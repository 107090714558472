import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export function unlinkSavedAccountCtor(http: AxiosInstance) {
  return async function unlinkSavedAccount(): Promise<void> {
    await http.delete('/bank/account');
  };
}

export default unlinkSavedAccountCtor(http);
