import { AxiosInstance } from 'axios';
import http from '../../../../http';

interface VerifyOtpContext {
  http: AxiosInstance;
}

interface VerifyOtpResponse {
  isStudentNew: boolean;
  studentId: string;
  token: string;
}

function verifyOtpCtor({ http }: VerifyOtpContext) {
  return async function verifyOtp(sessionId: string | null, otp: string, projectId: string) {
    const result = await http.post<VerifyOtpResponse>('/auth/verify-otp', {
      sessionId,
      otp,
      projectId,
    });

    const { data } = result;
    return data;
  };
}

export default verifyOtpCtor({ http });
