import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface PaymentPlanGroup {
  id: string;
  name: string;
  paymentPlans: PaymentPlan[];
}

export interface PaymentPlan {
  installments: number;
  minScore: number;
  maxScore: number;
  feeInPercentage: number;
  minOrderTotalInCents: number;
  maxOrderTotalInCents: number;
  downPaymentPercentage: number;
}

function getProjectPaymentPlanGroupCtor(http: AxiosInstance) {
  return async (projectId: string): Promise<PaymentPlanGroup> => {
    const { data } = await http.get(`/lnpl/v1/projects/${projectId}/payment-plan-groups`);
    return data;
  };
}

export const getPaymentPlanGroup = getProjectPaymentPlanGroupCtor(http);
