
















































import Vue from 'vue';
import archiveCreditCheck from './dependencies/archive_credit_check';
import getState from './dependencies/get_state';

export default Vue.extend({
  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      error: '',
      remainingArchiveAttempts: 0,
    };
  },

  async created() {
    const { projectId, sessionId } = this.$route.params;
    const state = await getState(projectId, sessionId);
    this.remainingArchiveAttempts = state.remainingAttempts;
  },

  methods: {
    async archive() {
      try {
        const { projectId, sessionId } = this.$route.params;
        await archiveCreditCheck(projectId, sessionId);
        this.$router.replace({ path: 'qualify', query: this.$route.query });
      // @ts-ignore
      } catch (error: Error) {
        if (error?.data?.message) {
          this.error = error.data.message;
        } else {
          this.error = "We've experienced an error.";
        }
      }
    },
  },
});
