













































































































import Vue from 'vue';

import PaymentOptionButton from './components/payment_option_button/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import DirectDebit from './components/direct_debit/index.vue';
import CreditCard from './components/payment_god/index.vue';
import CheckoutDetails from './components/checkout_details/index.vue';
import PaymentSchedule from './components/payment_schedule/index.vue';

import {
  PaymentMethods, paymentOptions, InstallmentData, CheckoutSession,
} from './dependencies/types';

import downloadFile from '@/helpers/download_file';
import getPaymentPlanData from './dependencies/get_payment_methods';
import downloadLoanAgreement from './dependencies/download_loan_agreement';
import completeCheckout from './dependencies/complete_checkout';

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    CheckoutDetails,
    PaymentOptionButton,
    DirectDebit,
    CreditCard,
    WbContentWrapper,
    PaymentSchedule,
  },

  props: {
    projectData: {
      type: Object,
    },
    ppmOnly: {
      type: Boolean,
    },
    origin: {
      type: String,
      required: true,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      error: '',
      sessionId: '',
      projectId: '',
      paymentMethods: {} as PaymentMethods,
      state: 'loading',
      paymentState: 'loaded',
      paymentOptionSelected: 0,
      hasAgreedToConditions: false,
      checkoutSession: {} as CheckoutSession,
      installmentsList: [] as InstallmentData[],
      recurringAmount: 0,
    };
  },

  computed: {
    hasOnlyOneOption(this: any) {
      return !(this.paymentMethods.canUseDirectDebit && this.paymentMethods.canUseCreditCard);
    },

    hasCreditCardSelected(this: any) {
      if (this.hasOnlyOneOption) {
        return this.paymentMethods.canUseCreditCard;
      }

      return this.paymentOptionSelected === paymentOptions.CREDIT_CARD;
    },

    hasDirectDebitSelected(this: any) {
      if (this.hasOnlyOneOption) {
        return this.paymentMethods.canUseDirectDebit;
      }

      return this.paymentOptionSelected === paymentOptions.DIRECT_DEBIT;
    },

    paymentOptions() {
      return paymentOptions;
    },

    dueTodayInCents(this: any) {
      return this.installmentsList[0]?.amountInCents;
    },
    totalPayments(this: any) {
      return this.checkoutSession.amountInCents + (this.checkoutSession.taxAmountInCents ?? 0);
    },
  },

  async mounted() {
    const { sessionId } = this.$route.params;
    const { projectId } = this.$route.params;

    try {
      this.state = 'loading';
      const { checkoutSession, paymentData } = await getPaymentPlanData(projectId, sessionId);
      this.checkoutSession = checkoutSession;
      this.paymentMethods = paymentData.paymentMethods;
      this.installmentsList = paymentData.paymentSchedule;

      if (this.installmentsList.length > 1) {
        this.recurringAmount = this.installmentsList[1].amountInCents;
      }
    } catch (error) {
      this.$emit('setErrorMessage', 'We ran into some technical difficulties');
    } finally {
      this.state = 'loaded';
    }
  },

  methods: {
    async completeCheckout(requestBody: any) {
      if (this.paymentState === 'loading') {
        return;
      }

      try {
        this.paymentState = 'loading';
        const { projectId, sessionId } = this.$route.params;
        const completionDetails = await completeCheckout({ projectId, sessionId, requestBody });

        if (completionDetails.redirectPage) {
          this.$router.push({
            path: 'completed-redirect',
            query: {
              redirectPage: completionDetails.redirectPage,
              firstName: completionDetails.studentFirstName,
              lastName: completionDetails.studentLastName,
              productName: completionDetails.productName,
              email: completionDetails.email,
              price: `${completionDetails.price}`,
            },
          });
        } else {
          this.$router.push({ path: 'completed', query: this.$route.query });
        }
      } catch (error) {
        const err = error as any;
        const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

        if (!err?.type || err.type === 'GenericError') {
          this.$emit('setErrorMessage', defaultMessage);
          return;
        }
        this.$emit('setErrorMessage', err.message);
      } finally {
        this.paymentState = 'loaded';
      }
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    handleError(error: any) {
      this.$emit('error', error);
    },

    setErrorMessage(error: string) {
      this.$emit('setErrorMessage', error);
    },

    async downloadAgreementPlan(origin: string) {
      try {
        const pdf = await downloadLoanAgreement(this.$route.params.sessionId);
        downloadFile({ file: pdf, fileName: origin === 'ppm' ? 'payment-plan-agreement.pdf' : 'loan-agreement.pdf' });
      } catch (error) {
        this.$emit(
          'setErrorMessage',
          `Error trying to download ${origin === 'ppm' ? 'payment plan' : 'loan'} agreement.`,
        );
      }
    },
  },
});
