








import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';

export default Vue.extend({
  components: {
    WbContentWrapper,
  },
  data() {
    return {
      state: 'loaded',
    };
  },
});
