








import Vue from 'vue';
import ErrorLayout from '@/layouts/error/index.vue';

export default Vue.extend({
  components: {
    ErrorLayout,
  },
});
