import { RouteConfig } from 'vue-router';

import Projects from './projects/index.vue';
import Project from './project/index.vue';
import Students from './students/index.vue';
import Student from './student/index.vue';
import OnboardingApplication from './onboarding_application/index.vue';
import OnboardingApplications from './onboarding_applications/index.vue';
import ppmRoutes from './ppm/routes';
import lnplRoutes from './lnpl/routes';
import Orders from './orders/index.vue';
import OrderDetails from './order/index.vue';
import Relay from './relay/index.vue';
import StudentApplication from './student/pages/application/index.vue';
import StudentCheckoutSession from './student/pages/checkout_session/index.vue';
import FinanceUtils from './utils/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('./index.vue'),
    children: [
      ...ppmRoutes,
      ...lnplRoutes,
      {
        path: 'projects',
        component: Projects,
        meta: {
          title: 'Projects',
        },
      },
      {
        path: 'projects/:id',
        component: Project,
        meta: {
          title: 'Project',
        },
      },
      {
        path: 'students',
        component: Students,
        meta: {
          title: 'Students',
        },
      },
      {
        path: 'students/:id',
        component: Student,
        meta: {
          title: 'Student',
        },
      },
      {
        path: 'students/:id/applications/:id',
        component: StudentApplication,
        meta: {
          title: 'Student Application',
        },
      },
      {
        path: 'students/:id/checkout-sessions/:id',
        component: StudentCheckoutSession,
        meta: {
          title: 'Student Checkout Session',
        },
      },
      {
        path: 'orders',
        component: Orders,
        meta: {
          title: 'Orders',
        },
      },
      {
        path: 'orders/:id',
        component: OrderDetails,
      },
      {
        path: 'orders/lnpl/:id',
        redirect: 'orders/:id', // backward compatibility for Ops team
      },
      {
        path: 'orders/ppm/:id',
        redirect: 'orders/:id', // backward compatibility for Ops team
      },
      {
        path: 'onboarding-applications',
        component: OnboardingApplications,
        meta: {
          title: 'Onboarding Applications',
        },
      },
      {
        path: 'onboarding-applications/:id',
        component: OnboardingApplication,
        meta: {
          title: 'Onboarding Application',
        },
      },
      {
        path: 'relay',
        component: Relay,
        meta: {
          title: 'Relay',
        },
      },
      {
        path: 'finance',
        component: FinanceUtils,
        meta: {
          title: 'Finance Utils',
        },
      },
    ],
  },
];

export default routes;
