












import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    customClasses: {
      default: '',
      type: String,
    },

    size: {
      default: 'medium',
      type: String,
    },
  },

  data() {
    return {
      maxHeight: undefined,
      maxHeightSizes: {
        small: '33px',
        medium: '55px',
        large: '66px',
      },
    };
  },

  created() {
    this.maxHeight = this.maxHeightSizes[this.size];
  },
});
