import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Feature {
  feature: string;
  enabled: boolean;
}

function updateFeatures({ http }: { http: AxiosInstance }) {
  return async function getOrders(projectId: string, features: Feature[]) {
    await http.put(`/lnpl/v1/projects/${projectId}/features`, { features });
  };
}

export default updateFeatures({ http });
