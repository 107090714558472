





















































































































































































































import Vue from 'vue';
import Dinero from 'dinero.js';
import {
  loadStripe, Stripe, StripeElements, StripeElementsOptions,
} from '@stripe/stripe-js';
import initCheckout, { Init } from './dependencies/init';
import completeCheckout,
{ CheckoutPaymentRequestBodyStripe, CompletionDetails } from './dependencies/complete_checkout';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import getStripeConfigs from './dependencies/get_stripe_configs';
import createCustomer from './dependencies/create_customer';

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    WbTextFieldLoader,
    WbParagraphLoader,
    Disclosure,
  },

  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      productId: '',
      projectId: '',
      product: {
        name: '',
        priceInCents: 0,
        currencyCode: '',
      },
      logoUrl: '',
      sessionId: '',
      termsOfServiceUrl: '',
      projectName: '',
      paymentPlans: [] as Init['paymentPlans'],
      state: 'loaded',
      paymentState: '',
      error: '',
      invalid: true,
      form: {
        card: {
          nameOnCard: '',
        },
        student: {
          firstName: '',
          lastName: '',
          email: '',
        },
        address: {
          country: {} as any,
          postalCode: '',
        },
        selectedPaymentPlanId: null,
        hasAgreedToConditions: false,
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        nameOnCard: '',
      },
      formInputsState: 'loading',
      stripe: null as unknown as Stripe,
      elements: {} as StripeElements,
      customerId: '',
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.form.hasAgreedToConditions
        && this.form.card.nameOnCard
        && this.form.student.firstName
        && this.form.student.lastName
        && this.form.student.email
        && this.form.selectedPaymentPlanId;
    },
  },

  async mounted() {
    this.productId = this.$route.params.productId as string;

    if (!this.productId) {
      this.$router.replace({
        path: `/student/checkout-full/${this.productId}/404`,
      });
      return;
    }

    try {
      this.formInputsState = 'loading';
      const init = await initCheckout(this.productId);
      this.sessionId = init.sessionId;
      this.product.name = init.product.name;
      this.product.priceInCents = init.product.priceInCents;
      this.product.currencyCode = init.product.currencyCode;
      this.projectName = init.product.projectName;
      this.projectId = init.product.projectId;
      this.termsOfServiceUrl = init.product.termsOfServiceUrl || '';

      this.paymentPlans = init.paymentPlans;
      this.logoUrl = init.logoUrl;

      await this.buildStripe();

      this.formInputsState = 'loaded';
    } catch (error) {
      this.handleInitError(error);
    }
  },

  methods: {
    handleInitError(error) {
      if (
        error?.status === 404
        || error?.data?.type === 'NoProductFound'
        || error?.data?.type === 'CheckoutNotAvailable'
      ) {
        this.$router.replace({ path: '404' });
        return;
      }

      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    },

    async buildStripe() {
      const sessionId = String(this.$route.params.sessionId);

      const stripeConfigs = await getStripeConfigs({ projectId: this.projectId, sessionId });

      this.stripe = await loadStripe(stripeConfigs.publishableKey, {
        stripeAccount: stripeConfigs.connectedAccount,
      }) as Stripe;

      const options: StripeElementsOptions = {
        mode: 'payment',
        amount: this.product.priceInCents,
        currency: this.product.currencyCode.toLowerCase(),
        paymentMethodCreation: 'manual',
        setupFutureUsage: 'off_session',
      };

      this.elements = this.stripe.elements(options);
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
    },

    async handleNextAction(pendingDetails) {
      if (pendingDetails.state === 'requires_action') {
        const { error, paymentIntent } = await this.stripe.handleNextAction({
          clientSecret: pendingDetails.clientSecret,
        });

        if (error) {
          throw new Error(error.message || '3DS error');
        }

        if (!paymentIntent) {
          throw new Error('3DS error');
        }

        return {
          status: 'success',
          data: {
            transactionId: paymentIntent?.id,
          },
        };
      }
      throw new Error('State not handled');
    },

    async submit() {
      this.error = '';

      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
        return;
      }

      const { error: submitError } = await this.elements.submit();
      if (submitError) {
        this.error = (submitError as any).message;
        return;
      }
      const { error, confirmationToken } = await this.stripe.createConfirmationToken({
        elements: this.elements,
      });

      if (error) {
        this.error = (submitError as any).message;
        return;
      }

      if (confirmationToken) {
        const {
          brand, funding, last4, exp_year: expYear, exp_month: expMonth,
        } = confirmationToken.payment_method_preview.card!;
        const { country, postal_code: postalCode } = confirmationToken.payment_method_preview.billing_details.address!;

        const customerId = await createCustomer({
          projectId: this.projectId,
          billingInfo: {
            email: this.form.student.email,
            country: country!,
            postalCode: postalCode!,
            firstName: this.form.student.firstName,
            lastName: this.form.student.lastName,
          },
        });

        this.customerId = customerId;

        const form: CheckoutPaymentRequestBodyStripe = {
          provider: 'stripe',
          student: {
            firstName: this.form.student.firstName,
            lastName: this.form.student.lastName,
            email: this.form.student.email,
          },
          customerId: this.customerId,
          confirmationTokenId: confirmationToken.id,
          paymentData: {
            card: {
              cardBrand: brand,
              cardType: funding,
              lastFourDigits: last4,
              cardExp: `${expMonth}/${expYear}`,
            },
            billingAddress: {
              country: country!,
              postalCode: postalCode!,
            },
          },
          selectedPaymentPlanId: this.form.selectedPaymentPlanId,
        };

        await this.submitCheckout(form);
      }
    },

    getPlanData() {
      const selectedPlan = this.paymentPlans.find((plan) => plan.id === this.form.selectedPaymentPlanId);

      if (!selectedPlan) {
        return {};
      }

      const obj = {
        amount: Dinero({ amount: selectedPlan.dueTodayInCents }).toUnit(),
        currency: this.product.currencyCode,
      };

      return obj;
    },

    onChangeName() {
      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },

    onChangeFirstName() {
      if (!this.form.student.firstName) {
        this.errors.firstName = 'Field Required';
      } else {
        this.errors.firstName = '';
      }
    },

    onChangeLastName() {
      if (!this.form.student.lastName) {
        this.errors.lastName = 'Field Required';
      } else {
        this.errors.lastName = '';
      }
    },

    onChangeEmail() {
      if (!this.form.student.email) {
        this.errors.email = 'Field Required';
      } else {
        this.errors.email = '';
      }
    },

    handleCheckoutCompletion(completionDetails: CompletionDetails) {
      this.$router.replace({ query: { checkoutSessionId: this.sessionId } });

      if (completionDetails.redirectPage) {
        this.$router.push({
          path: 'completed-redirect',
          query: {
            redirectPage: completionDetails.redirectPage,
            firstName: completionDetails.studentFirstName,
            lastName: completionDetails.studentLastName,
            productName: completionDetails.productName,
            email: completionDetails.email,
            price: `${completionDetails.price}`,
          },
        });
      } else {
        this.$router.push({
          path: 'completed',
          query: { ...this.$route.query },
        });
      }
    },

    async submitCheckout(requestBody: CheckoutPaymentRequestBodyStripe) {
      if (this.paymentState === 'loading') {
        return;
      }

      if (!this.isFormValid) {
        this.error = 'Please fill out all required fields.';
        return;
      }

      try {
        this.paymentState = 'loading';
        const checkoutResult = await completeCheckout({ requestBody, sessionId: this.sessionId });

        if (checkoutResult.status === 'pending') {
          const result = await this.handleNextAction(checkoutResult.data);
          const response = await completeCheckout({
            requestBody: { ...requestBody, transactionId: result.data.transactionId },
            sessionId: this.sessionId,
          });
          if (response.status === 'success') {
            this.handleCheckoutCompletion(response.data);
          }
        } else {
          this.handleCheckoutCompletion(checkoutResult.data);
        }
      } catch (error) {
        this.handleErrorCheckout(error);
      } finally {
        this.paymentState = 'loaded';
      }
    },

    handleErrorCheckout(error) {
      const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

      if (!error?.type || error?.type === 'GenericError') {
        this.error = defaultMessage;
        return;
      }
      this.error = error?.message;
    },

    disclosureChange(agreed: boolean) {
      this.form.hasAgreedToConditions = agreed;
    },

    setErrorMessage(error: string) {
      this.$emit('setErrorMessage', error);
    },
  },
});
