




























































import Vue from 'vue';
import getProjects from './dependencies/get_projects';
import WbSearchApi from '@/components/search_api/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import { store as projectStore } from '@/store/project';

interface Project {
  id: string;
  name: string;
  projectOwner: string;
  lnpl: boolean;
  analytics: boolean;
}

export default Vue.extend({

  components: {
    WbSearchApi,
    WbContentWrapper,
  },
  data() {
    return {
      state: '',
      projects: [] as Project[],
      table: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'ID', align: 'start', value: 'id', sortable: false,
          },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Owner', value: 'projectOwner' },
          { text: 'Analytics', value: 'analytics' },
          { text: 'LNPL', value: 'lnpl' },
        ],
      },
    };
  },

  computed: {
    projectStore() {
      return projectStore;
    },
  },

  watch: {
    'projectStore.selectedProjects': function () {
      // @ts-ignore
      if (!this._inactive) {
        this.getData();
      }
    },
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    projectSearchClick(order) {
      this.$router.push({ path: `/admin/app/projects/${order.id}` });
    },

    async getData() {
      try {
        this.state = 'loading';
        const data = await getProjects({
          projectIds: this.projectStore.selectedProjects.map((p) => p.id),
        });
        this.projects = data;
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },
  },
});
