











































































import Vue from 'vue';
import getStudents from './dependencies/get_students';
import WbSelectProjectApi from '@/components/select_project_api/index.vue';
import WbSearchApi from '@/components/search_api/index.vue';
import WbDatePicker from '@/components/date_picker_adv/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbLineChartPeriodComparison from '@/components/charts/line_chart_period_comparison/index.vue';
import WbMetrics from '@/components/metrics/index.vue';
import { store as dateRangeStore, viewOutOfSync } from '@/store/date_range';
import { store as projectStore } from '@/store/project';

interface Student {
  id: string;
  email: string;
  name: string;
  phone: string;
}

interface Metrics {
  total: unknown;
}

export default Vue.extend({

  components: {
    WbSelectProjectApi,
    WbContentWrapper,
    WbDatePicker,
    WbLineChartPeriodComparison,
    WbMetrics,
    WbSearchApi,
  },
  data() {
    return {
      state: '',
      students: [] as Student[],
      metrics: {} as Metrics,
      chartData: {},
      table: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'ID', align: 'start', value: 'id', sortable: false,
          },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Phone', value: 'phone', sortable: false },
          { text: 'Email', value: 'email', sortable: false },
        ],
      },
    };
  },

  computed: {
    dateRangeStore() {
      return dateRangeStore;
    },

    projectStore() {
      return projectStore;
    },
  },

  watch: {
    'dateRangeStore.dateRange': async function () {
      // @ts-ignore
      if (!this._inactive) {
        await this.getData();
      }
    },

    'projectStore.selectedProjects': async function () {
      // @ts-ignore
      if (!this._inactive) {
        await this.getData();
      }
    },
  },

  async mounted() {
    await this.getData();
  },

  async activated() {
    if (!this.state || viewOutOfSync(this.$route.fullPath)) {
      await this.getData();
    }
  },

  methods: {
    async getData() {
      this.state = 'loading';
      try {
        const data = await getStudents({
          ...this.dateRangeStore.dateRange,
          projectIds: this.projectStore.selectedProjects.map((p) => p.id),
        });
        this.metrics = data.metrics;
        this.students = data.students;
        this.chartData = data.chartData;
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    studentSearchClick(student) {
      this.$router.push({ path: `/admin/app/students/${student.id}` });
    },
  },
});
