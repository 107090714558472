import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { FilterOptions } from '@/components/table/dependencies/types';

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(id: string) {
    const { data } = await http.get(`/lnpl/v1/projects/${id}`);
    return data;
  };
}

export default getOrdersCtor({ http });
