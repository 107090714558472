









import Vue from 'vue';

export default Vue.extend({
  props: {
    progress: {
      type: Number,
    },
    dynamicColor: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getProgress() {
      const percentage = this.progress;
      if (percentage < 50) {
        return `width: ${percentage}%; ${this.dynamicColor ? 'background-color: #4caf50' : ''}`;
      }
      if (percentage < 75) {
        return `width: ${percentage}%; ${this.dynamicColor ? 'background-color: #ff9800' : ''}`;
      }
      return `width: ${percentage}%; ${this.dynamicColor ? 'background-color: #f44336' : ''}`;
    },
  },
});
