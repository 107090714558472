import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { SubscriptionInterval, SubscriptionStatus } from '../../../types';

export interface Subscription {
  createdAt: string;
  updatedAt: string;
  status: SubscriptionStatus;
  projectId: string;
  projectName: string;
  planId: string;
  planName: string;
  planPrice: number;
  planInterval: SubscriptionInterval;
  planOveragePercentage: number;
  planTransactionLimit: number;
  currentTransactionVolume: number;
  currentTransactionVolumePercentage: string;
  currency: string;
  startPeriod: string;
  endPeriod: string;
}

function getSubscriptionCtor({ http }: { http: AxiosInstance }) {
  return async function getSubscription(projectId: string) {
    const { data } = await http.get(`/lnpl/v1/subscriptions/${projectId}`);
    return data;
  };
}

export default getSubscriptionCtor({ http });
