export default function decorate(router: any) {
  router.onError((error) => {
    console.error(error);
    if (/ChunkLoadError:.*failed./i.test(error.message)) {
      window.location.reload();
    } else if (/Loading.*chunk.*failed./i.test(error.message)) {
      window.location.reload();
    }
  });
}
