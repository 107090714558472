import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

function setStudentInfo(http: AxiosInstance) {
  return async function qualifyStudent(projectId: string, sessionId: string, form: any) {
    const { data } = await http.post(`/checkout/${projectId}/${sessionId}/student-info`, form);
    return data;
  };
}

export default setStudentInfo(http);
