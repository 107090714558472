import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import * as Sentry from '@sentry/vue';
import Segment from '@dansmaculotte/vue-segment';
import Hotjar from 'vue-hotjar';
import Zendesk from '@dansmaculotte/vue-zendesk';

import App from './app.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import wbFilters from './plugins/wb_filters';

Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(wbFilters);

if (process.env.NODE_ENV === 'production') {
  Vue.use(Hotjar, { id: process.env.VUE_APP_HOTJAR_ID, isProduction: process.env.NODE_ENV === 'production' });
  Vue.use(Zendesk, {
    key: process.env.VUE_APP_ZENDESK_TOKEN,
    disabled: false,
    hideOnLoad: false,
  });
  Sentry.init({
    Vue,
    dsn: 'https://c21b01c6281349afa2f7c18371c3c1ed@o553831.ingest.sentry.io/5685474',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: false,
  });
  Vue.use(Segment, {
    writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY,
    disabled: false,
    router,
  });
}

declare module 'vue/types/vue' {
  interface Options {
    name?: string;
    email?: string;
  }

  interface Vue {
    $zendesk: {
      open: () => void;
      identify: (options: Options) => void;
      hide: () => void;
      show: () => void;
      $on: any;
    };
  }
}

declare global {
  interface Window {
    bluesnap: any;
    zE: any;
  }
}

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
