
















































































































































import Vue from 'vue';
import { formatDate } from '@/filters/date';
import {
  recallAccount,
  blockAccount,
  unblockAccount,
  DelinquentAccount,
} from '../../../api';
import { DelinquencyState, getDelinquentReadableState } from '@/helpers/delinquency';

import WbDialog from '@/components/dialog/index.vue';

export default Vue.extend({
  components: {
    WbDialog,
  },
  props: {
    delinquentAccount: {
      type: Object,
      default: () => ({} as DelinquentAccount),
    },
  },
  data() {
    return {
      delinquencyState: getDelinquentReadableState(this.delinquentAccount),
      delinquencyStates: DelinquencyState,
      recallDialog: false,
      blockFromBeingSentDialog: false,
      unblockFromBeingSentDialog: false,
      state: '',
    };
  },
  methods: {
    getArchivedDate(account: DelinquentAccount) {
      if (account.archivedAt) {
        return formatDate(String(account.archivedAt), 'MMM dd, yyyy');
      }
      if (account.blockedFromCollectionsAt) {
        return formatDate(String(account.blockedFromCollectionsAt), 'MMM dd, yyyy');
      }

      return '';
    },
    openRecallDialog() {
      this.recallDialog = true;
    },

    openBlockDialog() {
      this.blockFromBeingSentDialog = true;
    },

    openUnblockDialog() {
      this.unblockFromBeingSentDialog = true;
    },
    async recallAccount() {
      try {
        this.recallDialog = false;
        await recallAccount(this.delinquentAccount.loanOrderId);
        this.$emit('getData');
        // await this.getData();
      } catch (error) {
        this.$emit('setError', 'error recalling account');
      }
    },
    async blockAccountFromBeingSent() {
      try {
        this.blockFromBeingSentDialog = false;
        await blockAccount(this.delinquentAccount.loanOrderId);
        this.$emit('getData');
      } catch (error) {
        this.$emit('setError', 'error blocking account');
      }
    },

    async unblockAccountFromBeingSent() {
      try {
        this.unblockFromBeingSentDialog = false;
        await unblockAccount(this.delinquentAccount.loanOrderId);
        this.$emit('getData');
        // await this.getData();
      } catch (error) {
        this.$emit('setError', 'error unblocking account');
      }
    },
  },
});
