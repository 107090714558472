import { AxiosInstance } from 'axios';
import http from '@student/http';
import type { UpdatePaymentData } from '../types';

function updateCtor(http: AxiosInstance) {
  return async function update(loanOrderId: string, body: UpdatePaymentData) {
    const { data, status } = await http.post(`/update-payment/${loanOrderId}`, body, { validateStatus: () => true });
    if (status > 299) {
      throw data;
    }
  };
}

export const updatePaymentMethod = updateCtor(http);
