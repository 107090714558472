import http from '@admin/http';

interface Params {
  amountInCents: number;
  apr: number;
  term: number;
  startDate: Date;
  paymentFrequency: 'daily' | 'weekly' | 'biweekly' | 'monthly' | 'annually';
  downPaymentInCents: number;
  download: boolean;
}

export interface AmortizationScheduleEntry {
  date: string;
  paymentAmountInCents: number;
  principalInCents: number;
  interestInCents: number;
  balanceInCents: number;
}

export function calculateAmortizationSchedule(params: Params & { download: true }): Promise<Blob>;
export function calculateAmortizationSchedule
(params: Params & { download: false }): Promise<AmortizationScheduleEntry[]>;

export async function calculateAmortizationSchedule(params: Params):
Promise<Blob | AmortizationScheduleEntry[]> {
  const { download, ...restParams } = params;
  const { data } = await http.post(`/utils/amortization_schedule/${download ? 'download' : ''}`, restParams);
  if (download) {
    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }
  return data as AmortizationScheduleEntry[];
}
