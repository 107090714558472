









import Vue from 'vue';
import moment from 'moment';
import { currency, percentageChartAxis } from '@/filters';
import LineChart from '../chartjs/line';

export default Vue.extend({

  components: {
    LineChart,
  },
  props: {
    chartData: {
      default: () => ({}),
      type: Object,
    },

    labelOptions: {
      default: () => ({
        y: 'currency',
        x: 'date',
      }),
      type: Object,
    },
  },

  data() {
    return {
      dataCollection: {},
      chartOptions: {
        layout: {
          padding: {
            top: 50,
            left: 0,
          },
        },
        hover: { intersect: false, mode: 'index' },
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                padding: 16,
                callback: (value: string, i: number, values: string[]) => {
                  // @ts-ignore
                  if (this.labelOptions.x === 'date') return moment(value, 'YYYY-MM-DD').format('MMM Do YYYY');
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: {
                display: true,
                beginAtZero: false,
                padding: 16,
                callback: (value: string, i: number, values: string[]) => {
                  // @ts-ignore
                  if (this.labelOptions.y === 'currency') return currency(value, 0);
                  // @ts-ignore
                  if (this.labelOptions.y === 'percentage') return percentageChartAxis(value, 0);
                  return value;
                },
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.fillData();
  },

  methods: {
    fillData() {
      // @ts-ignore
      this.dataCollection = {
        // @ts-ignore: Unreachable code error
        labels: this.chartData.data && this.chartData.data.map((d) => d.x),
        datasets: [
          {
            label: 'Selected Range',
            borderColor: 'rgba(243, 102, 63, 1)',
            pointBorderColor: 'rgba(243, 102, 63, 1)',
            pointBackgroundColor: 'rgba(243, 102, 63, 1)',
            // @ts-ignore
            data: this.chartData.data && this.chartData.data.map((d) => d.y),
            fill: true,
            backgroundColor: 'rgba(243, 102, 63, .15)',
            borderWidth: 3,
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointHoverBackgroundColor: 'rgba(243, 102, 63, 1)',
          },
          {
            // @ts-ignore
            label: 'Previous Range',
            borderColor: 'rgba(243, 102, 63, 1)',
            // @ts-ignore
            data: this.chartData.data && this.chartData.data.map((d) => d.yoy_y),
            fill: false,
            pointBorderColor: 'rgba(243, 102, 63, 1)',
            pointFill: '#fff',
            borderWidth: 1,
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#fff',
            borderDash: [8, 5],
          },
        ],
      };
    },
  },
});
