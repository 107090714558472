import { RouteConfig } from 'vue-router';

import Auth from './index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '*',
    component: Auth,
  },
];

export default routes;
