import { RouteConfig } from 'vue-router';

import Auth from './index.vue';
import SignIn from './views/sign_in/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '',
    component: Auth,
    children: [
      {
        path: 'sign-in',
        component: SignIn,
      },
    ],
  },
];

export default routes;
