import Vue from 'vue';
import VueRouter from 'vue-router';

export default function decorate(router: VueRouter) {
  router.afterEach((to: any, from) => {
    if (to.path.includes('/student')) {
      Vue.nextTick(() => {
        document.title = 'Elective | Learn Now. Pay Later.';
      });
    } else {
      Vue.nextTick(() => {
        document.title = 'Elective';
      });
    }
  });
}
