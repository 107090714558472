




















import Vue from 'vue';

export default Vue.extend({
  props: {
    projectData: {
      type: Object,
    },
  },
});
