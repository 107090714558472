import Vue from 'vue';
import Chart from 'chart.js';
import { mixins, generateChart } from 'vue-chartjs';
import moment from 'moment';
import { store } from '@/store/date_range';
import { currency, percentage } from '@/filters';

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  // @ts-ignore
  draw(ease) {
    Chart.controllers.line.prototype.draw.call(this, ease);

    // @ts-ignore
    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      // @ts-ignore
      const activePoint = this.chart.tooltip._active[0];
      const { ctx } = this.chart;
      const { x } = activePoint.tooltipPosition();
      const topY = this.chart.scales['y-axis-0'].top;
      const bottomY = this.chart.scales['y-axis-0'].bottom + 200;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 40;
      ctx.strokeStyle = 'rgba(243, 102, 63, .05)';
      ctx.stroke();
      ctx.restore();
    }
  },
});

const CustomLine = generateChart('chart-line', 'LineWithLine');
const { reactiveProp } = mixins;

export default Vue.extend({
  extends: CustomLine,
  mixins: [reactiveProp],

  props: {
    chartData: {
      default: () => ({}),
      type: Object,
    },

    labelOptions: {
      default: () => ({
        y: 'currency',
        x: 'date',
      }),
      type: Object,
    },

    options: {
      type: Object,
      default: () => ({
        tooltips: {},
      }),
    },
  },

  computed: {
    store() {
      return store;
    },
  },

  mounted() {
    const tooltips = {
      callbacks: {
        // @ts-ignore
        title(tooltipItem, data, index) {
          let format;
          const date = data.labels[tooltipItem[0].index];

          if (store.dateRange.grouping === 'day') {
            format = 'MMM Do YYYY';
          } else if (store.dateRange.grouping === 'week') {
            format = 'MMM Do YYYY';
          } else {
            format = 'MMM YYYY';
          }

          return moment(date).format(format);
        },

        // @ts-ignore
        // label: (tooltipItems, data) => {
        //   let value = tooltipItems.value;
        //   // @ts-ignore
        //   if (this.labelOptions.y === 'currency') value = currency(value, 0);
        //   // @ts-ignore
        //   if (this.labelOptions.y === 'percentage') value = percentage(value, 0);
        //   return `${value}`;
        // },
        labelPointStyle: () => ' ',
      },
      backgroundColor: '#2A3459',
      titleFontSize: 14,
      titleFontWeight: 600,
      titleFontColor: '#fff',
      displayColors: false,
      usePointStyle: true,
      caretPadding: 22,
      caretSize: 0,
      position: 'nearest',
      bodySpacing: 6,
      titleMarginBottom: 6,
    };

    Object.assign(this.options.tooltips, tooltips);

    // @ts-ignore: Unreachable code error
    this.renderChart(this.chartData, Object.assign(this.options));
  },
});
