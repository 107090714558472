































































































































































































































































import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbDialog from '@/components/dialog/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import { getPaymentPlanGroup } from './dependencies/get_payment_plan_group';
import { createPaymentPlanGroup } from './dependencies/create_payment_plan_group';
import { getPaymentPlans } from './dependencies/get_payment_plans';
import { savePaymentPlans } from './dependencies/save_payment_plans';
import { deletePaymentPlanGroup } from './dependencies/delete_payment_plan_group';
import { deletePaymentPlan } from './dependencies/delete_payment_plan';
import { getProjectsUsingGroup, ProjectUsingGroup } from './dependencies/get_projects_using_group';
import { PaymentPlanGroup, PaymentPlan } from './dependencies/types';

export default Vue.extend({
  name: 'PaymentPlans',

  components: {
    WbContentWrapper,
    WbDialog,
    WbTextField,
    WbSelect,
  },

  data() {
    return {
      configurations: [] as PaymentPlanGroup[],
      table: {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Created', value: 'createdAt' },
          { text: '', value: 'action' },
          { text: '', value: 'delete' },
          { text: '', value: 'data-table-expand' },
        ],
      },
      state: '',
      createConfigurationDialog: false,
      editConfigurationDialog: false,
      configurationBeingEdited: {} as PaymentPlanGroup,
      editProjectPaymentPlansForm: [] as PaymentPlan[],
      configurationCreation: {
        name: '',
      },
      loadingProjectsByGroups: false,
      projectsByGroups: {} as Record<string, ProjectUsingGroup[]>,
      errorNotification: false,
      errorMessage: '',
    };
  },

  async mounted() {
    await this.loadConfigurations();
  },

  methods: {
    async loadConfigurations() {
      try {
        this.state = 'loading';
        this.configurations = await getPaymentPlanGroup();
        this.state = 'loaded';
      } catch (error) {
        console.error(error);
      }
    },

    async createConfiguration() {
      try {
        this.state = 'loading';
        await createPaymentPlanGroup(this.configurationCreation.name);
        await this.loadConfigurations();
      } catch (error) {
        console.error(error);
      } finally {
        this.createConfigurationDialog = false;
        this.configurationCreation.name = '';
        this.state = 'loaded';
      }
    },

    async editConfiguration(configuration: PaymentPlanGroup) {
      try {
        this.editProjectPaymentPlansForm = await getPaymentPlans(configuration.id);
        this.configurationBeingEdited = configuration;
        this.editConfigurationDialog = true;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteConfiguration(configuration: PaymentPlanGroup) {
      try {
        this.editProjectPaymentPlansForm = await deletePaymentPlanGroup(configuration.id);
        this.loadConfigurations();
      } catch (error) {
        if ((error as any).data.type === 'PaymentPlanGroupInUse') {
          this.errorNotification = true;
          this.errorMessage = 'This payment plan group is currently in use';
        } else console.error(error);
      }
    },

    async saveConfiguration() {
      try {
        await savePaymentPlans({
          groupId: this.configurationBeingEdited.id,
          paymentPlans: this.editProjectPaymentPlansForm,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.editConfigurationDialog = false;
      }
    },

    async onPaymentPlanGroupSelected(groupId: string) {
      try {
        this.editProjectPaymentPlansForm = (await getPaymentPlans(groupId)).map(
          (paymentPlan) => {
            paymentPlan.id = undefined;
            return paymentPlan;
          },
        );
      } catch (error) {
        console.error(error);
      }
    },

    async removePaymentPlan(i: number) {
      const paymentPlanToRemove = this.editProjectPaymentPlansForm[i];
      this.editProjectPaymentPlansForm.splice(i, 1);

      if (paymentPlanToRemove.id) {
        await deletePaymentPlan(this.configurationBeingEdited.id, paymentPlanToRemove.id);
      }
    },

    addPaymentPlanRow(i: number) {
      const row: PaymentPlan = {
        installments: 0,
        minScore: 0,
        maxScore: 0,
        minOrderTotalInCents: 0,
        maxOrderTotalInCents: 0,
        downPaymentPercentage: 0,
        feeInPercentage: 0,
      };

      this.editProjectPaymentPlansForm.splice(i, 0, row);
    },

    formatDateTime(date: string | Date) {
      const curr = new Date(date);
      const [year, month, day] = curr.toISOString().split('T')[0].split('-');
      return `${month}/${day}/${year}`;
    },

    async onExpanded({ item }: { item: PaymentPlanGroup}) {
      try {
        this.loadingProjectsByGroups = true;
        const projectsUsingGroup = await getProjectsUsingGroup(item.id);
        this.projectsByGroups[item.id] = projectsUsingGroup;
        this.loadingProjectsByGroups = false;
      } catch (error) {
        console.error(error);
      }
    },

    getProjectsForItem(id: string): ProjectUsingGroup[] {
      if (this.projectsByGroups[id]) {
        return this.projectsByGroups[id];
      } return [];
    },
  },
});
