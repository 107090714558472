import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { FilterOptions } from '@/components/table/dependencies/types';

function updateProjectCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(id: string, form: any) {
    const { data } = await http.post(`/lnpl/v1/projects/${id}/update-access`, form);
    return data;
  };
}

export default updateProjectCtor({ http });
