
















































import Vue from 'vue';
import { VueMaskDirective } from 'v-mask';
import { required } from 'vuelidate/lib/validators';
import WbTextField from '@/components/text_field/index.vue';
import submitSsn from './dependencies/submit_ssn';

Vue.directive('mask', VueMaskDirective);

export default Vue.extend({
  name: 'UnlockCreditProfile',

  components: {
    WbTextField,
  },

  data: () => ({
    loading: false,
    error: '',
    form: {
      ssn: '',
    },
  }),

  validations: {
    form: {
      ssn: {
        required,
        sinValidator: (ssn) => {
          if (!ssn) return false;

          const d = ssn.replace(/\D/g, '');
          return (d.length === 9);
        },
      },
    },
  },

  computed: {
    sinErrors() {
      const errors: string[] = [];
      if (!this.$v?.form?.ssn?.$dirty) return errors;
      if (!this.$v?.form?.ssn?.sinValidator) errors.push('Not a valid ssn');

      return errors;
    },
  },

  methods: {
    async submit() {
      this.error = '';
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      const { projectId, sessionId } = this.$route.params;
      try {
        this.loading = true;
        const { status } = await submitSsn(projectId, sessionId, this.form);

        if (status === 'ssn_needed') {
          this.error = "We couldn't verify you. Please try again";
          return;
        }

        let path: string;
        switch (status) {
        case 'frozen':
          path = 'frozen-credit-profile';
          break;
        case 'locked':
          path = 'locked-credit-profile';
          break;
        default:
          path = 'qualify';
          break;
        }

        this.$router.replace({ path, query: this.$route.query });
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
