
























































































































































import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbProgress from '@/components/progress/progress.vue';
import getInvoice, {
  Invoice, Communication, InvoiceItem, Transaction,
} from './dependencies/get_invoice';
import downloadInvoice from './dependencies/download_invoice';

export default Vue.extend({
  name: 'PpmSubscription',

  components: {
    WbContentWrapper,
    WbProgress,
  },
  data() {
    return {
      state: '',
      invoice: {} as Invoice,
      invoiceItems: [] as InvoiceItem[],
      invoiceCommunications: [] as Communication[],
      invoiceTransactions: [] as Transaction[],
      invoiceTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Status', align: 'start', value: 'status', sortable: true,
          },
          {
            text: 'Subtotal', align: 'start', value: 'subtotalAmount', sortable: true,
          },
          {
            text: 'Tax', align: 'start', value: 'totalTax', sortable: true,
          },
          { text: 'Total Due', value: 'totalAmount', sortable: true },
          { text: 'Currency', value: 'currency', sortable: true },
          { text: 'Due date', value: 'dueDate', sortable: true },
          { text: 'Next charge date', value: 'nextChargeDate', sortable: true },
        ],
      },
      invoiceItemsTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Type', align: 'start', value: 'type', sortable: true,
          },
          {
            text: 'Unit amount', align: 'start', value: 'unitAmount', sortable: true,
          },
          {
            text: 'Quantity', align: 'start', value: 'quantity', sortable: true,
          },
          { text: 'Subtotal', value: 'subtotalAmount', sortable: true },
          { text: 'Tax Percentage', value: 'taxPercentage', sortable: true },
          { text: 'Total Tax', value: 'totalTax', sortable: true },
          { text: 'Total', value: 'totalAmount', sortable: true },
          { text: 'Currency', value: 'currency', sortable: true },
        ],
      },
      invoiceTransactionTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Status', align: 'start', value: 'status', sortable: true,
          },
          {
            text: 'Charged at', align: 'start', value: 'chargedAt', sortable: true,
          },
          {
            text: 'Amount', align: 'start', value: 'amount', sortable: true,
          },
          { text: 'Currency', value: 'currency', sortable: true },
        ],
      },
      invoiceCommunicationTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Type', align: 'start', value: 'type', sortable: true,
          },
          {
            text: 'Sent at', align: 'start', value: 'sentAt', sortable: true,
          },
          {
            text: 'Delivered At', align: 'start', value: 'deliveredAt', sortable: true,
          },
          { text: 'Opened At', value: 'opened at', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const data = await getInvoice(this.$route.params.id);
        this.invoice = data.invoice;
        this.invoiceItems = data.invoiceItems;
        this.invoiceCommunications = data.communications;
        this.invoiceTransactions = data.transactions;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },
    getStatusColor(status: string) {
      switch (status) {
      case 'paid':
        return 'green';
      case 'overdue':
        return 'red';
      case 'processing':
        return 'orange';
      case 'open':
        return 'blue';
      default:
        return 'grey';
      }
    },

    async downloadInvoice(e: Event, invoiceId: string) {
      e.preventDefault();
      e.stopPropagation();
      try {
        await downloadInvoice(invoiceId);
      } catch (error) {
        console.log(error);
      }
    },

  },
});
