import http from '@admin/http';
import { AxiosInstance } from 'axios';

function getCsvUrlCtor(http: AxiosInstance) {
  return async () => {
    const { data } = await http.get('/relay/csv');
    return data;
  };
}

export const getCsvUrl = getCsvUrlCtor(http);
