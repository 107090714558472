import http from '@admin/http';
import { AxiosInstance } from 'axios';

function getStudentsCtor({ http }: { http: AxiosInstance }) {
  return async function getStudents(params) {
    const { data } = await http.get('/lnpl/v1/students', { params: { ...params } });
    return data;
  };
}

export default getStudentsCtor({ http });
