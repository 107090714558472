
















import Vue from 'vue';
import Logo from '../../../components/logo/index.vue';

export default Vue.extend({
  components: {
    Logo,
  },
});
