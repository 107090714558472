import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface UpdateMinMaxPayload {
    id: string;
    min: number | undefined;
    max: number | undefined;
}

function updateProjectMinMax({ http }: { http: AxiosInstance }) {
  return async ({ id, min, max }: UpdateMinMaxPayload) => http.post(`/lnpl/v1/projects/${id}/update-price-config`, {
    min,
    max,
  });
}

export default updateProjectMinMax({ http });
