



































































































































import Vue from 'vue';
import getOrders from './dependencies/get_orders';
import WbLineChartPeriodComparison from '@/components/charts/line_chart_period_comparison/index.vue';
import WbSearchApi from '@/components/search_api/index.vue';
import WbDatePicker from '@/components/date_picker_adv/index.vue';
import WbSelectProjectApi from '@/components/select_project_api/index.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbMetrics from '@/components/metrics/index.vue';
import { store as dateRangeStore, viewOutOfSync } from '@/store/date_range';
import { store as projectStore } from '@/store/project';

interface Order {
  id: string;
  currency: number;
  installments: number;
  amountInCents: number;
  downPaymentPercentage: number;
  projectId: string;
  studentId: string;
  loanAgreementFileName: string;
  createdAt: Date;
  studentName: string;
  projectName: string;
}

interface Metrics {
  total: number;
}

export default Vue.extend({
  name: 'CreatorLnplDashboardOrders',

  components: {
    WbDatePicker,
    WbMetrics,
    WbLineChartPeriodComparison,
    WbContentWrapper,
    WbSelectProjectApi,
    WbSearchApi,
  },

  data() {
    return {
      state: '',
      metrics: {}as Metrics,
      orders: [] as Order[],
      chartData: {},
      table: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'ID', align: 'start', value: 'id', sortable: false,
          },
          {
            text: 'Date', align: 'start', value: 'date', sortable: false,
          },
          { text: 'Status', align: 'start', value: 'status' },
          { text: 'Origin', align: 'start', value: 'origin' },
          { text: 'Project', value: 'projectName', sortable: false },
          { text: 'Student', value: 'studentName' },
          { text: 'Installments', value: 'installments', sortable: false },
          { text: 'Down Payment', value: 'downPaymentPercentage', sortable: false },
          { text: 'Price', value: 'amountInCents' },
          { text: 'Agreement', value: 'loanAgreementFileName', sortable: false },
        ],
      },
    };
  },

  computed: {
    dateRangeStore() {
      return dateRangeStore;
    },

    projectStore() {
      return projectStore;
    },
  },

  watch: {
    'dateRangeStore.dateRange': function () {
      // @ts-ignore
      if (!this._inactive) {
        this.getData();
      }
    },

    'projectStore.selectedProjects': function () {
      // @ts-ignore
      if (!this._inactive) {
        this.getData();
      }
    },
  },

  async mounted() {
    this.getData();
  },

  activated() {
    if (!this.state || viewOutOfSync(this.$route.fullPath)) {
      this.getData();
    }
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const { startDate, endDate, grouping } = this.dateRangeStore.dateRange;
        const data = await getOrders({
          startDate,
          endDate,
          grouping,
          projectIds: this.projectStore.selectedProjects.map((p) => p.id),
          orderBy: 'createdAt',
          order: 'DESC',
        });
        this.metrics = data.metrics;
        this.orders = data.orders;
        this.chartData = data.chartData;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    orderSearchClick(order) {
      this.$router.push({ path: `/admin/app/orders/${order.origin}/${order.id}` });
    },
  },
});
