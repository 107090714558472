import http from '@admin/http';
import { AxiosInstance } from 'axios';

function archiveLoanApplicationCtor({ http }: { http: AxiosInstance }) {
  return async (id: string): Promise<void> => {
    await http.delete(`/lnpl/v1/loan-order-applications/${id}`);
  };
}

export default archiveLoanApplicationCtor({ http });
