import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface CompleteCheckoutProps {
  requestBody: CheckoutPaymentRequestBodyStripe;
  sessionId: string;
}

export interface CheckoutPaymentRequestBodyStripe {
  provider: 'stripe';
  student: {
    firstName: string;
    lastName: string;
    email: string;
  };
  customerId: string;
  confirmationTokenId?: string;
  transactionId?: string;
  paymentData: {
    card?: {
      cardBrand?: string;
      cardType: string;
      lastFourDigits: string;
      cardExp: string;
    };
    billingAddress: {
      country: string;
      postalCode: string;
    };
  };
  selectedPaymentPlanId: string | null;
}

export interface CompletionDetails {
  price: number;
  studentFirstName: string;
  studentLastName: string;
  checkoutSessionId: string;
  email: string;
  productName: string;
  redirectPage: string | null;
}

export interface PendingDetails {
  state: 'requires_action';
  clientSecret: string;
}

export interface CheckoutResultSuccess {
  status: 'success';
  data: CompletionDetails;
}

export interface CheckoutResultPending {
  status: 'pending';
  data: PendingDetails;
}

export interface CheckoutApiResponse {
  status: 'success';
  price: number;
  studentFirstName: string;
  studentLastName: string;
  email: string;
  checkoutName: string;
  redirectPage: string | null;
  orderId: string;
  checkoutSessionId: string;
  productName: string;
}

export type CheckoutResult = CheckoutResultSuccess | CheckoutResultPending;

function completeCheckoutCtor(http: AxiosInstance) {
  return async function completeCheckout({
    sessionId,
    requestBody,
  }: CompleteCheckoutProps): Promise<CheckoutResult> {
    const { data: apiResponse, status } = await http.post<CheckoutApiResponse | CheckoutResultPending>(
      `/checkout-full/${sessionId}/complete`,
      requestBody,
    );

    if (status > 300) {
      throw apiResponse;
    }

    if (apiResponse.status === 'success') {
      return {
        status: apiResponse.status,
        data: {
          ...apiResponse,
        },
      };
    }
    return apiResponse;
  };
}

export default completeCheckoutCtor(http);
