import { RouteConfig } from 'vue-router';
import PaymentPlan from './views/payment_plan/index.vue';
import AutomaticPaymentSetup from './views/payment_setup/index.vue';
import PurchaseCompleted from './views/purchase_completed/index.vue';
import PurchaseCompletedRedirect from './views/purchase_completed_redirect/index.vue';
import OrderDeclined from './views/order_declined/index.vue';
import StudentInfo from './views/student_info/index.vue';
import QualifyStudent from './views/qualify_student/index.vue';
import SessionExpired from './views/session_expired/index.vue';
import SessionInvalid from './views/session_invalid/index.vue';
import Error from './views/error/index.vue';
import UnlockCreditProfile from './views/unlock_credit_profile/index.vue';
import ManualReview from './views/manual_review/index.vue';
import OutstandingLoanOrder from './views/outstanding_loan_order/index.vue';
import LockedCreditProfile from './views/locked_credit_profile/index.vue';
import FrozenCreditProfile from './views/frozen_credit_profile/index.vue';
import OutsideService from './views/outside_service/index.vue';
import ProductInactive from './views/product_inactive/index.vue';
import ProductNotFound from './views/product_not_found/index.vue';
import ProjectNotFound from './views/project_not_found/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: 'checkout/:projectId',
    redirect: 'landing/:projectId',
  },
  {
    path: 'checkout/:projectId/:sessionId',
    name: 'StudentCheckout',
    component: () => import('./index.vue'),
    children: [
      {
        name: 'StudentCheckoutInfo',
        path: 'info',
        component: StudentInfo,
        meta: {
          title: 'info',
        },
      },
      {
        name: 'StudentCheckoutQualify',
        path: 'qualify',
        component: QualifyStudent,
        meta: {
          title: 'qualify',
        },
      },
      {
        name: 'StudentCheckoutPaymentPlan',
        path: 'payment-plan',
        component: PaymentPlan,
        meta: {
          title: 'payment plans',
        },
      },
      {
        name: 'StudentCheckoutPaymentSetup',
        path: 'payment',
        component: AutomaticPaymentSetup,
        meta: {
          title: 'payment',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutCompleted',
        path: 'completed',
        component: PurchaseCompleted,
        meta: {
          title: 'completed',
        },
      },
      {
        name: 'StudentCheckoutCompletedRedirect',
        path: 'completed-redirect',
        component: PurchaseCompletedRedirect,
        meta: {
          title: 'completed',
        },
      },
      {
        name: 'StudentCheckoutDeclined',
        path: 'order-declined',
        component: OrderDeclined,
        meta: {
          title: 'declined',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutOutsideService',
        path: 'outside-service',
        component: OutsideService,
        meta: {
          title: 'Outside service',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutUnlockCreditProfile',
        path: 'unlock-credit-profile',
        component: UnlockCreditProfile,
        meta: {
          title: 'Unlock credit profile',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutManualReview',
        path: 'manual-review',
        component: ManualReview,
        meta: {
          title: 'Manual Review',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutOutstandingLoanOrder',
        path: 'outstanding-order',
        component: OutstandingLoanOrder,
        meta: {
          title: 'Outstanding Loan',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutLockedCreditProfile',
        path: 'locked-credit-profile',
        component: LockedCreditProfile,
        meta: {
          title: 'Locked credit profile',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutFrozenCreditProfile',
        path: 'frozen-credit-profile',
        component: FrozenCreditProfile,
        meta: {
          title: 'Frozen credit profile',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutProductInactive',
        path: 'product-inactive',
        component: ProductInactive,
        meta: {
          title: 'Product not for sale',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutSessionExpired',
        path: 'session-expired',
        component: SessionExpired,
        meta: {
          title: 'expired',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutSessionInvalid',
        path: 'session-invalid',
        component: SessionInvalid,
        meta: {
          title: 'invalid',
        },
        props: true,
      },
      {
        name: 'StudentCheckoutProjectNotFound',
        path: 'project-not-found',
        component: ProjectNotFound,
        meta: { title: 'Proect Not Found' },
      },
      {
        name: 'StudentCheckoutProductNotFound',
        path: 'product-not-found',
        component: ProductNotFound,
        meta: { title: 'Product Not Found' },
      },
      {
        name: 'StudentCheckoutError',
        path: 'error',
        component: Error,
        meta: {
          title: 'error',
        },
        props: true,
      },
    ],
  },
];

export default routes;
