import http from '@admin/http';
import { AxiosInstance } from 'axios';

function deletePaymentPlanGroupCtor(http: AxiosInstance) {
  return async (groupId: string, paymentPlanId: string) => {
    const { data } = await http.delete(`/lnpl/v1/payment-plan-groups/${groupId}/${paymentPlanId}`);
    return data;
  };
}

export const deletePaymentPlan = deletePaymentPlanGroupCtor(http);
