import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { PaymentPlan } from './types';

function getPaymentPlansCtor(http: AxiosInstance) {
  return async (groupId: string): Promise<PaymentPlan[]> => {
    const { data } = await http.get(`/lnpl/v1/payment-plan-groups/${groupId}`);
    return data;
  };
}

export const getPaymentPlans = getPaymentPlansCtor(http);
