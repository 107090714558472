import http from '@admin/http';
import { AxiosInstance } from 'axios';

function updateDueDateCtor({ http }: { http: AxiosInstance }) {
  return async (id: string, date: string) => {
    await http.put(`/lnpl/v1/installments/${id}/due-date`, { date: new Date(date) });
  };
}

export const updateDueDate = updateDueDateCtor({ http });
