<template>
  <component
    :is="componentToRender"
    v-if="componentToRender"
  />
</template>

<script>
import Payment from '../payment/index.vue';
import PaymentBs from '../payment_bs/index.vue';
import PaymentNmi from '../nmi/index.vue';
import PaymentStripe from '../payment_stripe/index.vue';

import getProductInfo from '../../dependencies/get_product_info';

export default {
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentToRender: null,
    };
  },
  async beforeMount() {
    const { productId } = this.$route.params;
    let { ppmPaymentProvider, projectId } = this.projectData;

    if (!ppmPaymentProvider) {
      const productInfo = await getProductInfo(productId);
      ppmPaymentProvider = productInfo.ppmPaymentProvider;
      projectId = productInfo.projectId;
    }

    switch (ppmPaymentProvider.toLowerCase()) {
    case 'vgs':
      this.componentToRender = Payment;
      break;
    case 'bluesnap_relay':
    case 'bluesnap_ppm':
      this.componentToRender = PaymentBs;
      break;
    case 'nmi':
      this.componentToRender = PaymentNmi;
      break;
    case 'stripe':
      this.componentToRender = PaymentStripe;
      break;
    default:
      this.componentToRender = null;
      this.$router.push(
        {
          path: `/student/checkout/${projectId}/${projectId}/error`,
          query: { productId },
        },
      );
      break;
    }
  },
};
</script>
