var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wb-content-wrapper',{attrs:{"state":_vm.state}},[_c('h1',[_vm._v("Invoice #"+_vm._s(_vm.invoice.invoiceId))]),_c('v-btn',{staticClass:"my-3",attrs:{"small":"","color":"primary","disabled":_vm.invoice.status !== 'open'},on:{"click":function (e) { return _vm.downloadInvoice(e, _vm.invoice.invoiceId); }}},[_vm._v(" Preview current invoice ")]),_c('p',[_vm._v(" Current transaction volume: "+_vm._s(_vm._f("currencyInCents")(_vm.invoice.currentTransactionVolume,2, _vm.invoice.currency))+" / "+_vm._s(_vm._f("currencyInCents")(_vm.invoice.transactionLimit,2, _vm.invoice.currency))+" ")]),_c('WbProgress',{attrs:{"progress":_vm.invoice.currentTransactionVolumePercentage,"dynamic-color":true}}),_c('p',[_vm._v("Current transaction percentage: "+_vm._s(_vm.invoice.currentTransactionVolumePercentage)+"%")]),_c('section',[_c('strong',[_vm._v("Re: "+_vm._s(_vm.invoice.projectName))]),_c('p',[_vm._v(" Between "+_vm._s(_vm._f("formatDate")(_vm.invoice.startDate,'MMM dd, yyyy'))+" and "+_vm._s(_vm._f("formatDate")(_vm.invoice.endDate,'MMM dd, yyyy'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.invoiceTable.headers,"items":[_vm.invoice],"items-per-page":_vm.invoiceTable.itemsPerPage},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",staticStyle:{"color":"white"},attrs:{"small":"","color":_vm.getStatusColor(item.status),"label":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.subtotalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.subtotalAmount,2, _vm.invoice.currency))+" ")]}},{key:"item.totalTax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.totalTax,2, _vm.invoice.currency))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.totalAmount,2, _vm.invoice.currency))+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dueDate,'MMM dd, yyyy'))+" ")]}},{key:"item.nextChargeDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.nextChargeDate || item.dueDate,'MMM dd, yyyy'))+" ")]}}])})],1),_c('section',[_c('h2',[_vm._v("Items")]),_c('v-data-table',{attrs:{"headers":_vm.invoiceItemsTable.headers,"items":_vm.invoiceItems,"items-per-page":_vm.invoiceItemsTable.itemsPerPage},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.unitAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.unitAmount,2, item.currency))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.subtotalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.subtotalAmount,2, item.currency))+" ")]}},{key:"item.taxPercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taxPercentage)+" ")]}},{key:"item.totalTax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.totalTax,2, _vm.invoice.currency))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.totalAmount,2, _vm.invoice.currency))+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" ")]}}])})],1),_c('section',[_c('h2',[_vm._v("Transactions")]),_c('v-data-table',{attrs:{"headers":_vm.invoiceTransactionTable.headers,"items":_vm.invoiceTransactions,"items-per-page":_vm.invoiceTransactionTable.itemsPerPage},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.chargedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.chargedAt,'MMM dd, yyyy'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.amount,2, _vm.invoice.currency))+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" ")]}}])})],1),_c('section',[_c('h2',[_vm._v("Communications")]),_c('v-data-table',{attrs:{"headers":_vm.invoiceCommunicationTable.headers,"items":_vm.invoiceCommunications,"items-per-page":_vm.invoiceCommunicationTable.itemsPerPage},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.sentAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.sentAt,'MMM dd, yyyy'))+" ")]}},{key:"item.deliveredAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.deliveredAt,'MMM dd, yyyy'))+" ")]}},{key:"item.openedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.openedAt,'MMM dd, yyyy'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.fileName)?_c('a',{attrs:{"href":item.fileName}},[_vm._v("download attachment")]):_c('div',[_vm._v(" no attachments ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }