import { format, parseISO } from 'date-fns';

export function formatDate(
  value: string,
  dateFormat = 'MM/dd/yyyy HH:mm',
): string {
  if (!value) {
    return '';
  }
  return format(parseISO(value), dateFormat);
}

export default {
  formatDate,
};
