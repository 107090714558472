var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wb-content-wrapper',{attrs:{"state":_vm.state}},[_c('h1',[_vm._v("Subscriptions")]),_c('section',[_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.subscriptions,"items-per-page":_vm.table.itemsPerPage},scopedSlots:_vm._u([{key:"item.projectName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/app/ppm/subscriptions/" + (item.projectId))}},[_vm._v(" "+_vm._s(item.projectName)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",staticStyle:{"color":"white"},attrs:{"small":"","color":_vm.getStatusColor(item.status),"label":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.endPeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.endPeriod))+" ")]}},{key:"item.planName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.planName)+" ")]}},{key:"item.planInterval",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.planInterval)+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }