

































import Vue from 'vue';
import WbCard from '@/components/card/index.vue';

export default Vue.extend({

  components: {
    WbCard,
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: String,
      default: '440px',
    },
  },

  methods: {
    handleInput(e: unknown) {
      this.$emit('input', e);
    },
  },
});
