
































import {
  format, parse, sub,
} from 'date-fns';
import Vue from 'vue';
import { VDatePicker } from 'vuetify/lib';

export default Vue.extend({

  components: {
    VDatePicker,
  },
  props: ['date'],
  data() {
    return {
      menu: false,
    };
  },

  watch: {
    date() {
      this.$emit('dateUpdated', this.date);
      this.menu = false;
    },
  },
});
