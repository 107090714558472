import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

function getRegionsCtor(http: AxiosInstance) {
  return async function qualifyStudent(countryId) {
    const { data } = await http.get(`/checkout/regions/${countryId}`);
    return data;
  };
}

export default getRegionsCtor(http);
