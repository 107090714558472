






































import Vue from 'vue';

export default Vue.extend({
  props: {
    state: {
      default: 'loading',
      type: String,
    },
    loadingMessage: {
      default: '',
      type: String,
    },
  },
});
