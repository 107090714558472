import { RouteConfig } from 'vue-router';

export const routes: Array<RouteConfig> = [
  {
    path: 'landing/:projectId',
    component: () => import('./index.vue'),
  },
];

export default routes;
