







































import Vue from 'vue';
import getSubscriptions, { Subscription } from './dependencies/get_subscriptions';
import WbContentWrapper from '@/components/content_wrapper/index.vue';

export default Vue.extend({
  name: 'PpmSubscriptions',

  components: {
    WbContentWrapper,
  },
  data() {
    return {
      state: '',
      subscriptions: [] as Subscription[],
      table: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Name', align: 'start', value: 'projectName', sortable: true,
          },
          {
            text: 'Status', align: 'start', value: 'status', sortable: true,
          },
          {
            text: 'End Period', align: 'start', value: 'endPeriod', sortable: true,
          },
          { text: 'Plan', value: 'planName', sortable: true },
          { text: 'Interval', value: 'planInterval', sortable: true },
        ],
      },
    };
  },

  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const data = await getSubscriptions();
        this.subscriptions = data;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    dateFormatter(d: string) {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'America/Vancouver',
      }).format(new Date(d));
    },

    getStatusColor(status: string) {
      switch (status) {
      case 'active':
        return 'green';
      case 'canceled':
        return 'red';
      case 'pending':
        return 'orange';
      default:
        return 'grey';
      }
    },
  },
});
