import jwtDecode from 'jwt-decode';
import sha256 from 'crypto-js/sha256';

type token = string | undefined;

interface SignInAttributes {
  newToken: token;
  newRefreshToken: token;
}

function authCtor({ jwtDecode, storage }) {
  return function auth(resource) {
    const tokenName = sha256(`wb_${resource}_token_${process.env.NODE_ENV}`);
    const refreshTokenName = sha256(`wb_${resource}_refresh_token_${process.env.NODE_ENV}`);

    function isTokenExpired(date: number) {
      return Date.now() >= date * 1000;
    }

    function signIn({ newToken, newRefreshToken }: SignInAttributes) {
      storage.setItem(tokenName, newToken);
      storage.setItem(refreshTokenName, newRefreshToken);
    }

    function signOut(path: string) {
      storage.removeItem(tokenName);
      storage.removeItem(refreshTokenName);
      window.location.replace(path);
    }

    function getToken() {
      const token = storage.getItem(tokenName);
      const refreshToken = storage.getItem(refreshTokenName);

      if (!token) return undefined;
      const { exp: tokenExp } = jwtDecode(token);
      const tokenExpired = isTokenExpired(tokenExp);
      if (!tokenExpired) return token;

      if (!refreshToken) return undefined;
      const { exp: refreshTokenExp } = jwtDecode(refreshToken);
      const refreshTokenExpired = isTokenExpired(refreshTokenExp);

      if (refreshTokenExpired) return undefined;

      return token;
    }

    function isAuthenticated() {
      const token = storage.getItem(tokenName);
      if (!token) return false;

      const { exp, sub } = jwtDecode(token);

      if (isTokenExpired(exp)) {
        storage.removeItem(tokenName);
        return false;
      }

      return true && resource === sub.type;
    }

    function hasLnplAccess() {
      const token = storage.getItem(tokenName);
      if (!token) return false;

      const { sub } = jwtDecode(token);
      const { allowedFeatures } = sub;

      return allowedFeatures.lnpl;
    }

    return {
      hasLnplAccess,
      isAuthenticated,
      getToken,
      signIn,
      signOut,
    };
  };
}

export default authCtor({
  jwtDecode,
  storage: window.localStorage,
});
