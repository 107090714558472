import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface StatusReturn {
  singlePageCheckoutEnabled: boolean | null;
  ppmOnly: boolean | null;
  productName: string | null;
  priceInCents: number | null;
  currencyCode: string | null;
  termsOfServiceUrl: string | null;
  hasPlans: boolean;
  ppmPaymentProvider: string;
  projectId: string;
}

function getProductInfoCtor(http: AxiosInstance) {
  return async (productId: string): Promise<StatusReturn> => {
    const { data } = await http.get(`/projects/${productId}/info`);
    return data;
  };
}

export default getProductInfoCtor(http);
