import { RouteConfig } from 'vue-router';

import AppRoutes from './app/routes';
import PublicStoreRoutes from './public_store/routes';
import AuthRoutes from './auth/routes';
import CheckoutRoutes from './checkout/routes';
import CheckoutPayInFullRoutes from './checkout_pay_in_full/routes';
import ErrorRoutes from './errors/routes';

export const routes: Array<RouteConfig> = [
  {
    path: '/student',
    component: () => import('./index.vue'),
    children: [
      ...AppRoutes,
      ...PublicStoreRoutes,
      ...AuthRoutes,
      ...CheckoutRoutes,
      ...CheckoutPayInFullRoutes,
      ...ErrorRoutes,
    ],
  },
];

export default routes;
