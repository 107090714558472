import http from '@admin/http';
import { AxiosInstance } from 'axios';
import {
  InvoiceChargeStatus,
  InvoiceCommunicationStatus,
  InvoiceItemType,
  InvoiceStatus,
} from '../../../types';

export interface Invoice {
  invoiceId: string;
  currency: string;
  totalAmount: number;
  subtotalAmount: number;
  totalTax: number;
  createdAt: string;
  dueDate: string;
  startDate: string;
  endDate: string;
  chargedAt: string;
  status: InvoiceStatus;
  projectName: string;
  transactionLimit: number;
  currentTransactionVolume: number;
  currentTransactionVolumePercentage: string;
}

export type Communication = {
  type: InvoiceCommunicationStatus;
  sentAt: string | null;
  deliveredAt: string | null;
  openedAt: string | null;
  fileName: string | null;
};

export interface Transaction {
  amount: number;
  chargedAt: string;
  status: InvoiceChargeStatus;
  currency: string;
}

export type InvoiceItem = {
  id: string;
  type: InvoiceItemType;
  taxPercentage: number;
  totalTax: number;
  quantity: number;
  unitAmount: number;
  subtotalAmount: number;
  totalAmount: number;
  currency: string;
};

interface CompleteInvoiceTrail {
  invoice: Invoice;
  communications: Communication[];
  invoiceItems: InvoiceItem[];
  transactions: Transaction[];
}

function getInvoiceCtor({ http }: { http: AxiosInstance }) {
  return async (invoiceId: string): Promise<CompleteInvoiceTrail> => {
    const { data } = await http.get(`/lnpl/v1/subscriptions/invoice/${invoiceId}`);
    return data;
  };
}

export default getInvoiceCtor({ http });
