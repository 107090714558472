import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

function setStudentInfo(http: AxiosInstance) {
  return async function qualifyStudent(projectId: string, sessionId: string) {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/student-info`);
    return data;
  };
}

export default setStudentInfo(http);
