export enum KybStatus {
  pending = 'pending',
  resubmissionRequested = 'resubmission_requested',
  approved = 'approved',
  declined = 'declined',
}

export enum KycStatus {
  pending = 'pending',
  resubmissionRequested = 'resubmission_requested',
  approved = 'approved',
  declined = 'declined',
}

export enum DemoStatus {
  pending = 'pending',
  completed = 'completed',
}

export enum ApplicationStatus {
  submitted = 'submitted',
  resubmissionRequested = 'resubmission_requested',
  approved = 'approved',
  declined = 'declined',
  abandoned = 'abandoned',
}
