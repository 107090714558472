import { AxiosInstance } from 'axios';
import http from '@student/http';

function downloadLoanAgreementCtor(http: AxiosInstance) {
  return async function downloadLoanAgreement(loanOrderId: string): Promise<Blob> {
    const { data } = await http.get(`/loan-agreement/${loanOrderId}`);
    const bytes = new Uint8Array(data.pdf.data);
    return new Blob([bytes], { type: 'application/pdf' });
  };
}

export const downloadLoanAgreement = downloadLoanAgreementCtor(http);
