import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface SavedAccount {
  accountNumber: string;
  accountId: string;
}

export function getSavedAccountCtor(http: AxiosInstance) {
  return async function getSavedAccount(): Promise<SavedAccount | null> {
    const { data } = await http.get<SavedAccount | null>('/bank/account');
    return data;
  };
}

export default getSavedAccountCtor(http);
