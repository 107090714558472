import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface UpdateProps {
  id: string | null;
  country?: string | null;
  region?: string | null;
  city?: string | null;
  street?: string | null;
  postal?: string | null;
  phoneCode?: string | null;
  phone?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}
function updateStudentCtor({ http }: { http: AxiosInstance }) {
  return async ({ id, ...rest }: UpdateProps): Promise<void> => {
    await http.put(`/lnpl/v1/students/${id}`, rest);
  };
}

export const updateStudent = updateStudentCtor({ http });
