export const paymentOptions = {
  DIRECT_DEBIT: 0,
  CREDIT_CARD: 1,
};

export interface PaymentMethods {
  canUseCreditCard: boolean;
  canUseDirectDebit: boolean;
}

export interface InstallmentData {
  dueDate: Date;
  amountInCents: number;
}

export interface CheckoutSession {
  completedAt: string | null;
  currencyCode: string;
  downPaymentPercentage: number;
  expiredAt: string | null;
  id: string;
  installments: number;
  paymentPlanId: string;
  amountInCents?: number;
  productId: string;
  projectId: string;
  studentId: string;
  taxAmountInCents: number | null;
}
