



























































































































import Vue from 'vue';
import Dinero from 'dinero.js';
import {
  loadStripe, Stripe, StripeElements, StripeElementsOptions,
} from '@stripe/stripe-js';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import getPrepaymentInfo, { PrepaymentInfo } from './dependencies/get_prepayment_info';
import getStripeConfigs from './dependencies/get_stripe_configs';
import createCustomer from './dependencies/create_customer';
import completeCheckout, {
  CheckoutPaymentRequestBodyStripe,
  PendingDetails,
  CompletionDetails,
} from '../../dependencies/complete_checkout_stripe';

interface CustomerInfo extends PrepaymentInfo {
  amount: number;
}

export default Vue.extend({
  name: 'CreditCard',

  components: {
    Disclosure,
    WbTextFieldLoader,
    WbParagraphLoader,
  },

  props: {
    businessName: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    dueToday: {
      type: Number,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      error: '',
      invalid: true,
      creditCardVendor: '',
      hasAgreedToConditions: false,
      errors: {
        nameOnCard: '',
      },
      form: {
        nameOnCard: '',
      },
      formInputsState: 'loading',
      completeCheckoutState: 'loaded',
      prepaymentInfo: {} as Partial<CustomerInfo>,
      stripe: null as unknown as Stripe,
      elements: {} as StripeElements,
      customerId: '',
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.hasAgreedToConditions && this.form.nameOnCard;
    },
  },

  async mounted() {
    const { projectId, sessionId } = this.$route.params;

    try {
      this.formInputsState = 'loading';

      this.prepaymentInfo = {
        ...(await getPrepaymentInfo({ projectId, sessionId })),
        amount: Dinero({ amount: this.dueToday }).getAmount(),
      };

      await this.buildStripe();

      this.formInputsState = 'loaded';
    } catch (error) {
      console.error('error building Stripe', error);
      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    }
  },

  methods: {
    downloadAgreementPlan(event: Event) {
      event.preventDefault();
      this.$emit('downloadAgreementPlan', this.$props.origin);
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    onChangeName() {
      if (!this.form.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },

    async buildStripe() {
      const projectId = String(this.$route.params.projectId);
      const sessionId = String(this.$route.params.sessionId);

      const stripeConfigs = await getStripeConfigs({ projectId, sessionId });

      this.stripe = await loadStripe(stripeConfigs.publishableKey, {
        stripeAccount: stripeConfigs.connectedAccount,
      }) as Stripe;

      const customerId = await createCustomer({
        projectId,
        billingInfo: {
          email: this.prepaymentInfo.email!,
          phone: this.prepaymentInfo.phone!,
          city: this.prepaymentInfo.billingCity!,
          country: this.prepaymentInfo.billingCountry!,
          state: this.prepaymentInfo.billingState!,
          postalCode: this.prepaymentInfo.billingZip!,
          firstName: this.prepaymentInfo.billingFirstName!,
          lastName: this.prepaymentInfo.billingLastName!,
        },
      });

      this.customerId = customerId;

      const options: StripeElementsOptions = {
        mode: 'payment',
        amount: this.prepaymentInfo.amount!,
        currency: (this.prepaymentInfo.currency!).toLowerCase(),
        paymentMethodCreation: 'manual',
        setupFutureUsage: 'off_session',
      };

      this.elements = this.stripe.elements(options);
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
    },

    async handleNextAction(pendingDetails: PendingDetails) {
      if (pendingDetails.state === 'requires_action') {
        const { error, paymentIntent } = await this.stripe.handleNextAction({
          clientSecret: pendingDetails.clientSecret,
        });

        if (error) {
          return {
            status: 'error',
            error,
          };
        }
        return {
          status: 'succcess',
          data: {
            transactionId: paymentIntent?.id,
          },
        };
      }
      throw new Error('State not handled');
    },

    async completePurchase() {
      this.error = '';

      const { error: submitError } = await this.elements.submit();
      if (submitError) {
        this.error = (submitError as any).message;
        return;
      }
      const { error: confirmationTokenError, confirmationToken } = await this.stripe.createConfirmationToken({
        elements: this.elements,
      });

      if (confirmationTokenError) {
        this.error = (confirmationTokenError as any).message;
        return;
      }

      if (confirmationToken) {
        const {
          brand, funding, last4, exp_year: expYear, exp_month: expMonth,
        } = confirmationToken.payment_method_preview.card!;

        const { country, postal_code: postalCode } = confirmationToken.payment_method_preview.billing_details.address!;

        const payload = {
          amountInCents: this.prepaymentInfo.amount!,
          currency: this.prepaymentInfo.currency!,
          confirmationTokenId: confirmationToken.id,
          customerId: this.customerId,
          provider: 'stripe',
          card: {
            brand,
            type: funding,
            last4,
            expYear,
            expMonth,
            name: this.form.nameOnCard,
            countryCode: country,
            postalCode,
          },
        };
        const sessionId = String(this.$route.params.sessionId);
        const form: CheckoutPaymentRequestBodyStripe = {
          sessionId,
          paymentMethod: 'card',
          provider: 'stripe',
          paymentData: {
            ...payload,
          },
        };

        this.completeCheckout(form);
      } else {
        this.$emit('setErrorMessage', this.error);
      }
    },

    async handleCheckoutCompletion(completionDetails: CompletionDetails) {
      if (completionDetails.redirectPage) {
        this.$router.push({
          path: 'completed-redirect',
          query: {
            redirectPage: completionDetails.redirectPage,
            firstName: completionDetails.studentFirstName,
            lastName: completionDetails.studentLastName,
            productName: completionDetails.productName,
            email: completionDetails.email,
            price: `${completionDetails.price}`,
          },
        });
      } else {
        this.$router.push({ path: 'completed', query: this.$route.query });
      }
    },

    async completeCheckout(requestBody: CheckoutPaymentRequestBodyStripe) {
      if (this.completeCheckoutState === 'loading') {
        return;
      }

      try {
        this.completeCheckoutState = 'loading';
        const { projectId, sessionId } = this.$route.params;
        const checkoutResult = await completeCheckout({ projectId, sessionId, requestBody });

        if (checkoutResult.status === 'pending') {
          const result = await this.handleNextAction(checkoutResult.data);
          const nextActionResponseBody = result.data
            ? { transactionId: result.data.transactionId }
            : { transactionErr: result.error };

          const response = await completeCheckout({
            requestBody: {
              ...requestBody,
              paymentData: {
                ...requestBody.paymentData,
                ...nextActionResponseBody,
              },
            },
            projectId,
            sessionId,
          });
          if (response.status === 'success') {
            this.handleCheckoutCompletion(response.data);
          }
        } else {
          this.handleCheckoutCompletion(checkoutResult.data);
        }
      } catch (error) {
        const err = error as any;
        const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

        if (!err?.type || err.type === 'GenericError') {
          this.$emit('setErrorMessage', defaultMessage);
          return;
        }
        this.$emit('setErrorMessage', err.message);
      } finally {
        this.completeCheckoutState = 'loaded';
      }
    },
  },
});
