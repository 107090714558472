import { RouteConfig } from 'vue-router';

import DelinquentLoanOrders from './views/delinquent_loan_orders/index.vue';
import PaymentPlans from './views/payment_plans/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('./index.vue'),
    children: [
      {
        path: 'lnpl/delinquent-loan-orders',
        component: DelinquentLoanOrders,
      },
      {
        path: 'lnpl/payment-plans',
        component: PaymentPlans,
        name: 'PaymentPlans',
        meta: {
          title: 'Payment Plans',
        },
      },
    ],
  },
];

export default routes;
