import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface ManualReviewForm {
  source: string;
  status: string;
  notes: string;
}

function manualReviewCreditCheckCtor({ http }: { http: AxiosInstance }) {
  return async (creditCheckId: string, params: ManualReviewForm): Promise<void> => {
    await http.put(`/lnpl/v1/credit-checks/${creditCheckId}/manual-review`, {
      ...params,
    });
  };
}

export default manualReviewCreditCheckCtor({ http });
