import { AxiosInstance } from 'axios';
import http from '@student/http';
import { Country } from '../types';

export interface LoanOrder {
  id: string;
  productName: string;
  orderCompletedAt: Date;
  amountInCents: number;
  currency: string;
  installments: number;
  chargeType: string;
  paymentMethod: string;
  outstandingInstallments: number;
  totalInstallments: number;
  paymentDetails: Record<string, unknown>;
  availablePaymentOptions: {
    card: boolean;
    bank: boolean;
  };
  projectName: string;
  studentCountry: string;
  studentPostalCode: string;
  studentRegion: string;
  studentCity: string;
  studentStreet: string;
  countryOptions: Country[];
}

function getOrderDetailsCtor(http: AxiosInstance) {
  return async function init(loanOrderId: string) {
    const { data } = await http.get<LoanOrder>(`/update-payment/${loanOrderId}`);
    return data;
  };
}

export const getOrderDetails = getOrderDetailsCtor(http);
