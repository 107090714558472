













































































































































import Vue, { PropType } from 'vue';
import { required } from 'vuelidate/lib/validators';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import getRegions from '@/views/student/checkout/views/qualify_student/dependencies/get_regions';
import type {
  Country, Region, StudentAddress, UpdatePaymentData,
} from '../../types';

interface Form {
  nameOnCard: string;
  cardToken: string;
  lastFourDigits: string;
  exp: string;
  category: string;
  type: string;
  addressCountry: Country;
  addressPostalCode: string;
  addressStreet: string;
  addressCity: string;
  addressRegion: string | null;
}

export default Vue.extend({
  name: 'CreditCard',

  components: {
    WbTextFieldLoader,
    WbParagraphLoader,
    WbCountry,
    WbTextField,
    WbSelect,
  },

  props: {
    paymentState: {
      type: String,
    },
    projectName: {
      type: String,
    },
    studentAddress: {
      type: Object as PropType<StudentAddress>,
    },
    countries: {
      type: Array as PropType<Country[]>,
    },
  },

  data() {
    return {
      error: '',
      invalid: true,
      regions: [] as Region[],
      form: {
        addressCountry: {} as Country,
        addressPostalCode: '',
        addressStreet: '',
        addressCity: '',
        addressRegion: null,
      } as Form,
      formField: {} as VGSForm,
      formState: null as State | null,
      isTokenizeLoading: false,
      errors: {
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      },
      formInputsState: 'loading',
    };
  },

  validations: {
    form: {
      addressCountry: { required },
      addressPostalCode: { required },
      addressStreet: { required },
      addressCity: { required },
    },
  },

  async mounted() {
    this.form.addressCountry = this.countries.find(
      (c) => c.id === this.studentAddress.country?.toUpperCase(),
    ) || this.countries[0];
    this.form.addressCity = this.studentAddress.city;
    this.form.addressStreet = this.studentAddress.street;
    this.form.addressPostalCode = this.studentAddress.postalCode;

    await this.setRegions();
    this.form.addressRegion = this.studentAddress.region ? this.studentAddress.region.toUpperCase() : null;

    try {
      this.formInputsState = 'loading';
      this.buildVgs();
    } catch (error) {
      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    } finally {
      this.formInputsState = 'loaded';
    }
  },

  methods: {
    isFormValid(this: any) {
      return this.formState && this.formState?.card_cvc?.isValid
        && this.formState?.card_exp?.isValid
        && this.formState?.card_holder?.isValid
        && this.formState?.card_number?.isValid
        && this.form.addressStreet
        && this.form.addressPostalCode
        && this.form.addressCity
        && this.form.addressCountry;
    },
    handleState(state: State) {
      if (state.card_number) {
        if (
          state.card_number.errors.length
        && state.card_number.isTouched
        && !state.card_number.isValid
        ) {
          this.errors.cardNumber = 'Card number is invalid';
        }
        if (state.card_number.isValid) {
          this.errors.cardNumber = '';
        }
      }

      if (state.card_cvc) {
        if (state.card_cvc.errors.length && state.card_cvc.isTouched && !state.card_cvc.isValid) {
          this.errors.cvv = 'Card cvv is invalid';
        }
        if (state.card_cvc.isValid) {
          this.errors.cvv = '';
        }
      }

      if (state.card_exp) {
        if (state.card_exp.errors.length && state.card_exp.isTouched && !state.card_exp.isValid) {
          this.errors.expiryDate = 'Card expiry is invalid';
        }

        if (state.card_exp.isValid) {
          this.errors.expiryDate = '';
        }
      }
    },
    buildVgs() {
      const vgsForm = window.VGSCollect.create(
        process.env.VUE_APP_VGS_TOKEN!,
        process.env.VUE_APP_VGS_ENV as 'sandbox' | 'live',
        (state) => {
          this.formState = state;
          this.handleState(state);
        },
      );

      vgsForm.field('#cc-holder', {
        type: 'text',
        name: 'card_holder',
        placeholder: 'Card holder',
        validations: ['required'],
        tokenization: false,
      });

      vgsForm.field('#cc-number', {
        type: 'card-number',
        name: 'card_number',
        errorColor: '#D8000C',
        placeholder: 'Card number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        autoComplete: 'cc-number',
        tokenization: {
          format: 'FPE_SIX_T_FOUR',
        },
      });

      vgsForm.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        errorColor: '#D8000C',
        placeholder: 'CVV',
        validations: ['required', 'validCardSecurityCode'],
        autoComplete: 'cc-csc',
        tokenization: {
          format: 'UUID',
        },
      });

      vgsForm.field('#cc-expiration-date', {
        type: 'card-expiration-date',
        name: 'card_exp',
        errorColor: '#D8000C',
        placeholder: 'MM / YY',
        validations: ['required', 'validCardExpirationDate'],
        autoComplete: 'cc-exp',
        tokenization: false,
      });

      this.formField = vgsForm;
    },

    async setRegions() {
      const regions = await getRegions(this.form.addressCountry.id);
      this.regions = regions.map((region) => ({ name: region.name, code: region.id }));
    },

    async onCountryChange() {
      await this.setRegions();

      if (this.regions.length) {
        this.form.addressRegion = this.regions[0].code?.toUpperCase();
      } else {
        this.form.addressRegion = null;
      }
    },
    async tokenize(): Promise<TokenizeResponse> {
      const response = await new Promise((resolve, reject) => {
        this.formField.tokenize((status, response) => {
          resolve({ status, response });
        },
        (error) => {
          reject(error instanceof Error ? error : new Error(JSON.stringify(error)));
        });
      });
      return response as TokenizeResponse;
    },

    validateAddressField(input: string) {
      if (!this.$v?.form?.[input]?.$dirty) {
        return [];
      }
      if (!this.$v?.form?.[input]?.required) {
        return ['Field Required'];
      }
      return [];
    },

    async updatePayment() {
      this.error = '';
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      if (!this.formState?.card_holder.isValid) {
        this.errors.cardHolderName = 'Field Required';
        return;
      }

      this.isTokenizeLoading = true;
      const { response, status } = await this.tokenize();
      this.isTokenizeLoading = false;

      if (status !== 200) {
        this.error = `Authentication Failed: We apologize, but we were unable to verify your payment
        authentication. Please ensure that the information provided is correct and try again. If the
        issue persists, please contact our customer support for further assistance.`;
        return;
      }

      const form: UpdatePaymentData = {
        paymentMethod: 'card',
        paymentInfo: {
          card: {
            cardNumberToken: response.card_number,
            cardCvcToken: response.card_cvc,
            cardExp: response.card_exp.replaceAll(' ', ''),
            cardHolderName: response.card_holder,
          },
          address: {
            country: this.form.addressCountry.id,
            postalCode: this.form.addressPostalCode,
            street: this.form.addressStreet,
            region: this.form.addressRegion,
            city: this.form.addressCity,
          },
        },
      };

      await this.sendPaymentDetails(form);
    },

    async sendPaymentDetails(data: UpdatePaymentData) {
      this.$emit('update-payment', data);
    },
  },
});
