import http from '../../../views/student/http';

export interface Prediction {
    name: string;
    id: string;
}

interface Payload {
    sessionId: string;
    predictions: Prediction[];
}

interface Args {
    input: string;
    sessionId?: string;
    geofence: string;
}

function suggestionCtor({ http }) {
  return async ({ input, sessionId, geofence }: Args) => {
    const { data } = await http.post('/checkout/address-suggestion', {
      sessionId,
      input,
      geofence,
    });
    return data as Payload;
  };
}

export default suggestionCtor({ http });
