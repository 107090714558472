































































































































































import Vue, { PropType } from 'vue';

import { required } from 'vuelidate/lib/validators';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import getRegions from '@/views/student/checkout/views/qualify_student/dependencies/get_regions';
import WbSelect from '@/components/select/index.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import AccountButton from './components/account_button/index.vue';
import WbPlaidLink from './components/plaid/plaid_link/index.vue';
import UnlinkAccountDialog from './components/unlink_account_dialog/index.vue';
import getSavedAccount, { SavedAccount } from './dependencies/get_saved_account';
import unlinkSavedAccount from './dependencies/unlink_saved_account';
import type {
  Country, Region, StudentAddress, UpdatePaymentData,
} from '../../types';

interface Form {
  name: string;
  routingNumber: string;
  accountNumber: string;
  addressCountry: Country;
  addressPostalCode: string;
  addressStreet: string;
  addressCity: string;
  addressRegion: string | null;
}

export default Vue.extend({
  name: 'DirectDebit',

  components: {
    AccountButton,
    WbContentWrapper,
    WbPlaidLink,
    UnlinkAccountDialog,
    WbTextField,
    WbSelect,
    WbCountry,
  },

  props: {
    paymentState: {
      type: String,
    },
    studentAddress: {
      type: Object as PropType<StudentAddress>,
    },
    countries: {
      type: Array as PropType<Country[]>,
    },
  },

  data() {
    return {
      accountDialog: false,
      savedAccount: null as SavedAccount | null,
      state: 'loaded',
      linkToken: '',
      plaidEnv: process.env.VUE_APP_PLAID_ENV,
      isConnectingManually: false,
      form: {
        name: '',
        routingNumber: '',
        accountNumber: '',
        addressCountry: {} as Country,
        addressPostalCode: '',
        addressStreet: '',
        addressCity: '',
        addressRegion: null,
      } as Form,
      regions: [] as Region[],
    };
  },

  validations: {
    form: {
      name: { required },
      routingNumber: { required },
      accountNumber: { required },
      addressPostalCode: { required },
      addressCountry: { required },
      addressStreet: { required },
      addressCity: { required },
    },
  },

  watch: {
    '$route.hash': function (newHash, oldHash) {
      if (oldHash === '#dialog') {
        this.accountDialog = false;
      }
    },
  },

  async mounted() {
    await this.loadSavedAccount();

    this.form.addressCountry = this.countries.find(
      (c) => c.id === this.studentAddress.country?.toUpperCase(),
    ) || this.countries[0];
    this.form.addressCity = this.studentAddress.city;
    this.form.addressStreet = this.studentAddress.street;
    this.form.addressPostalCode = this.studentAddress.postalCode;

    await this.setRegions();
    this.form.addressRegion = this.studentAddress.region ? this.studentAddress.region.toUpperCase() : null;
  },

  methods: {
    async loadSavedAccount() {
      try {
        this.state = 'loading';
        this.savedAccount = await getSavedAccount();
        this.$emit('setErrorMessage', '');
      } catch (error) {
        const err = error as any;
        if (err?.data?.message && err?.data?.type) {
          this.$emit('setErrorMessage', err.data.message);
        } else {
          this.$emit('error', err);
        }
      } finally {
        this.state = 'loaded';
      }
    },

    async unlinkSavedAccount() {
      try {
        this.state = 'loading';
        await unlinkSavedAccount();
        this.savedAccount = null;
        this.accountDialog = false;
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.state = 'loaded';
      }
    },

    async onAccountLinkedSuccess() {
      await this.loadSavedAccount();
    },

    handleError(error: any) {
      this.$emit('error', error);
    },

    async update(type: 'plaid' | 'manual') {
      const { accountNumber, routingNumber } = this.form;
      const plaidIntegration = true;

      const body: UpdatePaymentData = {
        paymentMethod: 'bank',
        paymentInfo: {
          bank: type === 'plaid'
            ? { plaidIntegration }
            : {
              accountNumber,
              routingNumber,
              plaidIntegration: false,
            },
          address: {
            country: this.form.addressCountry.id,
            postalCode: this.form.addressPostalCode,
            street: this.form.addressStreet,
            region: this.form.addressRegion,
            city: this.form.addressCity,
          },
        },
      };

      this.$emit('update-payment', body);
    },

    async setRegions() {
      const regions = await getRegions(this.form.addressCountry.id);
      this.regions = regions.map((region) => ({ name: region.name, code: region.id }));
    },

    async onCountryChange() {
      await this.setRegions();

      if (this.regions.length) {
        this.form.addressRegion = this.regions[0].code?.toUpperCase();
      } else {
        this.form.addressRegion = null;
      }
    },

    onEditAccount() {
      this.accountDialog = true;
      this.$router.push('#dialog');
    },

    closeDialog() {
      this.$router.back();
    },
  },
});
