<template>
  <li
    class="payment-row"
    :class="{ 'payment-row-due-today': paymentIndex === 0 }"
  >
    <div class="payment-row-left">
      <div class="payment-number-pill">
        <span class="payment-number-text">{{ paymentIndex + 1 }}/{{ installmentsListLength }}</span>
      </div>
      <span
        v-if="paymentIndex === 0"
        class="payment-due-date"
      >Due Today</span>
      <span
        v-else
        class="payment-due-date"
      >{{ installment.dueDate | formatDueDate }}</span>
    </div>

    <p class="payment-amount">
      {{ installment.amountInCents | currencyInCents(2) }}
    </p>
  </li>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'PaymentScheduleRow',
  filters: {
    formatDueDate(date) {
      return moment(date).format('MMM D, YYYY');
    },
  },
  props: {
    paymentIndex: {
      type: Number,
      required: true,
    },
    installmentsListLength: {
      type: Number,
      required: true,
    },
    installment: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.payment-row {
  list-style: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .payment-number-pill {
    width: 3.25rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    background-color: $white;
    color: $dark;
    border: 0.125rem solid $dark;
    margin-right: 0.5rem;
  }

  .payment-number-text {
    font-size: 0.875rem;
  }

  .payment-due-date {
    font-size: 0.875rem;
    color: #6a6a6a;
  }

  .payment-amount {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}

.payment-row-due-today {
  .payment-number-pill {
    background-color: $dark;
    color: $white;
  }

  .payment-amount {
    font-weight: bold;
  }
}

.payment-row-left {
  display: flex;
  align-items: center;
}
</style>
