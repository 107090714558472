











import Vue from 'vue';

export default Vue.extend({
  name: 'VGSField',

  props: {
    name: {
      type: String,
    },
  },
});
