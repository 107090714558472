





































































































import Vue from 'vue';
import { format } from 'date-fns';
import OrderInfo from './components/order_info.vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import PaymentDetailsCard from './components/payment_details.vue';
import Management from './components/management/index.vue';
import OrderStatementCard from './components/order_statement/index.vue';
import { DelinquentAccount, getOrder } from './api';
import {
  Info,
  Installments,
  OrderEvents,
  OrderStatement,
  PaymentDetails,
  OrderOrigin,
  ChargeType,
} from './types';

export default Vue.extend({
  components: {
    OrderInfo,
    PaymentDetailsCard,
    Management,
    OrderStatementCard,
    WbContentWrapper,
  },
  data() {
    return {
      errorSnackbar: false,
      errorText: '',
      state: '',
      orderInfoData: {
        status: '',
        priceInCents: 0,
        currency: '',
        installments: [] as Installments[],
        origin: '' as OrderOrigin,
        chargeEnabled: true,
        chargeType: 'manual' as ChargeType,
      },
      order: {
        info: { id: '' } as Info,
        installments: [] as Installments[],
        paymentDetails: {} as PaymentDetails,
        orderEvents: [] as OrderEvents[],
        delinquencyInfo: null as DelinquentAccount | null,
        statement: null as OrderStatement | null,
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const data = await getOrder(this.$route.params.id);
        this.order = data;
        this.order.statement = data.orderStatement;
        this.orderInfoData = {
          status: this.order.info.status,
          priceInCents: this.order.info.priceInCents,
          currency: this.order.info.currency,
          installments: this.order.installments,
          origin: this.order.info.origin,
          chargeEnabled: this.order.info.chargeEnabled,
          chargeType: this.order.info.chargeType,
        };
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },
    formatDate(date: Date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    setError(err: string) {
      this.errorText = err;
      this.errorSnackbar = true;
    },
  },
});
