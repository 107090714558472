import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface CreateLinkTokenResponse {
  linkToken: string;
  expiration: string;
}

export function createLinkAccountCtor(http: AxiosInstance) {
  return async function createLinkAccount(): Promise<CreateLinkTokenResponse> {
    const { data } = await http.get('/bank/token');
    return data;
  };
}

export default createLinkAccountCtor(http);
