import http from '@admin/http';
import { AxiosInstance } from 'axios';

function getFeatures({ http }: { http: AxiosInstance }) {
  return async function getOrders(id: string) {
    const { data } = await http.get(`/lnpl/v1/projects/${id}/features`);

    return data;
  };
}

export default getFeatures({ http });
