















































import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators';

import WbTextField from '@/components/text_field/index.vue';
import WbSplitCard from '@/layouts/auth/components/split_card/index.vue';
import signIn from './dependencies/sign_in';

export default Vue.extend({

  components: {
    WbSplitCard,
    WbTextField,
  },
  data() {
    return {
      state: '',
      error: '',
      form: {
        email: '',
        password: '',
      },

      passwordToggleType: 'password',
    };
  },

  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },

  computed: {
    emailErrors() {
      const errors: string[] = [];
      if (!this.$v?.form?.email?.$dirty) return errors;
      if (!this.$v?.form?.email?.email) errors.push('Must be valid email');
      if (!this.$v?.form?.email?.required) errors.push('Email is required');
      return errors;
    },

    passwordErrors() {
      const errors: string[] = [];
      if (!this.$v?.form?.password?.$dirty) return errors;
      if (!this.$v?.form?.password?.required) errors.push('Password is required');
      return errors;
    },
  },

  methods: {
    togglePassword() {
      if (this.passwordToggleType === 'password') this.passwordToggleType = 'text';
      else this.passwordToggleType = 'password';
    },

    async submit() {
      this.error = '';
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        this.state = 'loading';
        await signIn(this.form);
        this.state = 'loaded';

        const path = this.$route.query.next;
        if (path) {
          const { query } = this.$route;
          delete query.next;
          // @ts-ignore
          this.$router.push({ path, query });
        } else {
          this.$router.push({ path: '/admin/app' });
        }
      // @ts-ignore
      } catch (error: any) {
        this.state = 'error';

        if (error && [401, 400].includes(error?.status)) {
          this.error = 'Incorrect email or password.';
        } else {
          this.error = "We've experienced an error.";
        }
      }
    },
  },
});
