












































































































































































































































































































































































import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import {
  parse, differenceInDays, isSameDay, format,
} from 'date-fns';
import WbDialog from '@/components/dialog/index.vue';
import WbDatePickerSingle from '@/components/date_picker_single/index.vue';
import WbTextField from '@/components/text_field/index.vue';
import { Info, Installments, OrderStatement } from '../../types';
import {
  updateDueDate, manageInstallment, InstallmentData, downloadOrderSummaryPdf,
} from '../../api';

type Installment = Omit<Installments, 'transactions'>

export default Vue.extend({
  components: {
    WbDialog,
    WbDatePickerSingle,
    WbTextField,
  },
  props: {
    installments: {
      type: Array,
      default: () => ([] as Installments[]),
    },
    orderInfo: {
      type: Object,
      default: () => ({} as Info),
    },
    orderStatement: {
      type: Object,
      default: () => ({} as OrderStatement),
    },
  },
  data() {
    return {
      changeAchDialog: false,
      changeAchDialogStagedId: '',
      changeAchDialogStagedDate: '',
      changeAchDialogState: '',
      achError: '',
      installmentDateUpdate: '',
      drawer: false,
      installmentsData: this.installments as Installment[],
      table: {
        itemsPerPage: 25,
        headers: [
          { text: '', value: 'data-table-expand' },
          { text: 'Due Date', value: 'dueDate', sortable: false },
          { text: 'Amount', value: 'amount', sortable: false },
          { text: 'Status', value: 'status', sortable: false },
          { text: 'Retry Date', value: 'retryDate', sortable: false },
          { text: 'Paid Date', value: 'paidAt', sortable: false },
          { text: 'BlueSnap ID', value: 'bluesnapTransactionId', sortable: false },
          { text: 'Last Modified At', value: 'updatedAt', sortable: false },
        ],
      },
      innerTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date' },
        { text: 'Type', value: 'type' },
        { text: 'IsSuccess', value: 'isSuccess' },
        { text: 'Error Message', value: 'errorMessage' },
      ],
      formInstallments: this.installments as Installment[],
      newInstallment: {} as Partial<Installment>,
      isNewInstallmentFormVisible: false,
      installmentStatus: ['paid', 'terminated', 'failed', 'future'] as string[],
      newInstallmentError: '',
      loadingSubmitButton: false,
      exportPdfState: 'loaded',
    };
  },
  mounted() {
    this.formInstallments = this.deepClone<Installment[]>(this.installments as any);
  },
  methods: {
    updateDate(newDate: string) {
      this.installmentDateUpdate = newDate;
    },
    showChangeAchDialog(id: string, date: string) {
      this.changeAchDialog = true;
      this.changeAchDialogStagedDate = date;
      this.changeAchDialogStagedId = id;
      this.achError = '';
    },
    async updateInstallmentDueDate() {
      try {
        await updateDueDate(this.changeAchDialogStagedId, this.installmentDateUpdate);
        this.changeAchDialog = false;
        this.installmentDateUpdate = '';
        this.$emit('getData');
      } catch (e) {
        this.changeAchDialogState = 'error';
        try {
          this.achError = `👋 ${(e as any).data.message}`;
        } catch (e) {
          this.achError = 'something went wrong';
        }
      }
    },
    formatDate(str: string | Date) {
      return format(new Date(str), 'yyyy/MM/dd HH:mm:ss');
    },
    getQuantityPaid() {
      return (this.installmentsData as Installments[]).filter((i) => i.status === 'paid').length;
    },
    amountInCentsRules(amountInCents?: number) {
      return [
        () => this.isInputPositive(amountInCents),
        () => this.isInputInCents(amountInCents),
      ];
    },
    isPositive(n?: number) {
      return Boolean(n && n > 0);
    },
    isInCents(n?: number) {
      return Number.isInteger(n);
    },
    isInputPositive(n?: number) {
      return this.isPositive(n) || 'Must be greater than 0';
    },
    isInputInCents(n?: number) {
      return this.isInCents(n) || 'Value must be in CENTS';
    },
    isNewInstallmentButtonDisabled() {
      const { amountInCents, status, dueDate } = this.newInstallment;
      return !amountInCents
        || !status
        || !dueDate
        || !this.isPositive(amountInCents)
        || !this.isInCents(amountInCents);
    },
    isInstallmentScheduleSame() {
      return this.formInstallments.length === this.installmentsData.length
        && this.formInstallments.every((item, index) => (
          item.amountInCents === this.installmentsData[index].amountInCents
          && item.id === this.installmentsData[index].id
          && item.status === this.installmentsData[index].status
          && isSameDay(new Date(item.dueDate), new Date(this.installmentsData[index].dueDate))
        ));
    },
    renderList(id: string, status: string) {
      const inst = this.installmentsData.find((x) => x.id === id);
      switch (inst?.status || status) {
      case 'paid':
        return ['paid'];
      case 'future':
      case 'failed':
      case 'terminated':
      case 'unpaid':
        return ['paid', 'terminated', 'failed', 'future', 'unpaid'];
      case 'chargebacked':
        return ['chargebacked'];
      case 'refunded':
        return ['refunded'];
      default:
        return [];
      }
    },
    deepClone<T>(data: T): T {
      return JSON.parse(JSON.stringify(data)) as T;
    },
    isInputDisabled(id: string) {
      const inst = this.installmentsData.find((x) => x.id === id);
      return [
        'chargebacked',
        'refunded',
        'canceled',
        'paid',
      ].includes(inst?.status || '');
    },
    async manageInstallmentsSubmit() {
      if (this.isInstallmentScheduleSame()) {
        return;
      }

      if (!this.formInstallments.every((x) => this.isPositive(x.amountInCents))) {
        this.$emit('setError', 'Installment Amount cannot be lower or equal to zero');
        return;
      }
      const total = this.getNewOrderBalance() - this.orderInfo.priceInCents;
      if (total !== 0) {
        this.$emit('setError', `Total order balance is ${total > 0 ? 'HIGHER' : 'LOWER'} than original order amount`);
        return;
      }

      const toUpdate: InstallmentData[] = this.getDiffFromInstallments()
        .map((x) => ({
          id: x.id,
          dueDate: new Date(x.dueDate),
          currency: x.currency,
          amountInCents: x.amountInCents,
          status: x.status,
        }));

      const isBefore = (newDate: Date, currDate: Date) => differenceInDays(newDate, currDate) < 0;

      if (toUpdate.some((x) => x.status === 'future' && isBefore(new Date(x.dueDate), new Date()))) {
        this.$emit('setError', 'Future installments cannot be in the past');
        return;
      }

      this.loadingSubmitButton = !this.loadingSubmitButton;
      try {
        await manageInstallment(this.orderInfo.id, toUpdate);
        this.$emit('getData');
      } catch (err) {
        this.$emit('setError', (err as any).message);
      } finally {
        this.loadingSubmitButton = !this.loadingSubmitButton;
      }
    },
    isFutureInstallmentValid(dueDate: string, status: string) {
      const newDueDate = parse(dueDate, 'yyyy-MM-dd', new Date());
      return status === 'future' && differenceInDays(newDueDate, new Date()) >= 0;
    },
    getNewOrderBalance() {
      const newBalance = this.formInstallments.reduce(
        (prev, curr) => (
          ['paid', 'future', 'failed', 'unpaid'].includes(curr.status) ? curr.amountInCents + prev : prev
        ), 0,
      );
      return newBalance;
    },
    removeInstallment(id: string) {
      const index = this.formInstallments.findIndex((x) => x.id === id);
      this.formInstallments.splice(index, 1);
    },
    newInstallmentSubmit() {
      if (
        this.newInstallment.status === 'future'
        && !this.isFutureInstallmentValid(this.newInstallment.dueDate!, this.newInstallment.status!)
      ) {
        this.$emit('setError', 'Future Installment cannot have a date in the past');
        return;
      }

      this.formInstallments.push({
        id: uuid(),
        status: this.newInstallment.status!,
        currency: this.orderInfo.currency,
        amountInCents: this.newInstallment.amountInCents!,
        dueDate: this.newInstallment.dueDate!,
        updatedAt: null,
        bluesnapTransactionId: null,
        paidAt: null,
        retryDate: null,
      });
      this.newInstallment = {};
    },
    showInstallmentForm() {
      this.isNewInstallmentFormVisible = !this.isNewInstallmentFormVisible;
    },
    isNewInstallment(id: string) {
      return !this.installmentsData.find((x) => x.id === id);
    },
    isPayInFullOrder() {
      // loan summary export disabled for pif as there is no loan
      return this.orderInfo.installments === 1;
    },
    resetFormInstallments() {
      this.formInstallments = this.deepClone<any[]>(this.installmentsData);
    },
    getDiffFromInstallments() {
      const currentIds = this.installmentsData.map((x) => x.id);
      const newInstallments = this.formInstallments.filter((e) => !currentIds.includes(e.id));

      const updatedInstallments = this.formInstallments.filter((item) => {
        const inst = this.installmentsData.find((x) => x.id === item.id);
        return inst
          && (item.amountInCents !== inst.amountInCents
          || item.status !== inst.status
          || !isSameDay(new Date(item.dueDate), new Date(inst.dueDate)));
      });

      return [...updatedInstallments, ...newInstallments];
    },
    async exportPdf() {
      this.exportPdfState = 'loading';
      try {
        const pdf = await downloadOrderSummaryPdf(this.orderInfo.id);
        const blob = new Blob([pdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      } catch (err) {
        this.$emit('setError', (err as any).message);
      } finally {
        this.exportPdfState = 'loaded';
      }
    },
  },
});
