
























































































































































































































import Vue from 'vue';
import Dinero from 'dinero.js';
import WbTextField from '@/components/text_field/index.vue';
import initCheckout, { Init } from './dependencies/init';
import completeCheckout, { CheckoutPaymentRequestBody } from './dependencies/complete_checkout';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import CardBrand from './components/index.vue';

interface Country {
  id: string;
  name: string;
  callingCode: number;
}

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    WbTextField,
    WbCountry,
    WbTextFieldLoader,
    WbParagraphLoader,
    Disclosure,
    CardBrand,
  },

  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      productId: '',
      product: {
        name: '',
        priceInCents: 0,
        currencyCode: '',
      },
      formField: {} as VGSForm,
      formState: {} as State,
      isTokenizeLoading: false,
      logoUrl: '',
      sessionId: '',
      termsOfServiceUrl: '',
      projectName: '',
      paymentPlans: [] as Init['paymentPlans'],
      state: 'loaded',
      paymentState: 'loaded',
      creditCardVendor: '',
      error: '',
      invalid: true,
      countries: [] as Country[],
      form: {
        student: {
          firstName: '',
          lastName: '',
          email: '',
        },
        address: {
          country: {} as any,
          postalCode: '',
        },
        selectedPaymentPlanId: null,
        hasAgreedToConditions: false,
      },
      errors: {
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      },
      formInputsState: 'loading',
      contries: [
        { code: 'us', name: 'United States' },
        { code: 'ca', name: 'Canada' },
      ],
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.form.hasAgreedToConditions
        && this.formState.card_cvc.isValid
        && this.formState.card_exp.isValid
        && this.formState.card_holder.isValid
        && this.formState.card_number.isValid
        && this.form.student.firstName
        && this.form.student.lastName
        && this.form.student.email
        && this.form.address.country
        && this.form.address.postalCode
        && this.form.selectedPaymentPlanId;
    },
  },

  async mounted() {
    this.productId = this.$route.params.productId as string;

    if (!this.productId) {
      this.$router.replace({
        path: `/student/checkout-full/${this.productId}/404`,
      });
      return;
    }

    try {
      this.formInputsState = 'loading';
      const init = await initCheckout(this.productId);
      this.sessionId = init.sessionId;
      this.product.name = init.product.name;
      this.product.priceInCents = init.product.priceInCents;
      this.product.currencyCode = init.product.currencyCode;
      this.projectName = init.product.projectName;
      this.termsOfServiceUrl = init.product.termsOfServiceUrl || '';

      this.countries = init.countries;

      this.form.address.country = this.countries.find((country) => country.id === 'US') || {};

      this.paymentPlans = init.paymentPlans;
      this.logoUrl = init.logoUrl;

      this.buildVgs();
      this.formInputsState = 'loaded';
    } catch (error) {
      this.handleInitError(error);
    }
  },

  methods: {
    handleInitError(error) {
      if (
        error?.status === 404
        || error?.data?.type === 'NoProductFound'
        || error?.data?.type === 'CheckoutNotAvailable'
      ) {
        this.$router.replace({ path: '404' });
        return;
      }

      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    },
    setCard(variant: string) {
      this.creditCardVendor = variant;
    },
    async tokenize(): Promise<TokenizeResponse> {
      const response = await new Promise((resolve, reject) => {
        this.formField.tokenize((status, response) => {
          resolve({ status, response });
        },
        (error) => {
          reject(error instanceof Error ? error : new Error(JSON.stringify(error)));
        });
      });
      return response as TokenizeResponse;
    },
    handleState(state: State) {
      if (state.card_number) {
        if (state.card_number.cardType) {
          this.setCard(state.card_number.cardType);
        }
        if (
          state.card_number.errors.length
        && state.card_number.isTouched
        && !state.card_number.isValid
        ) {
          this.errors.cardNumber = 'Card number is invalid';
        }
        if (state.card_number.isValid) {
          this.errors.cardNumber = '';
        }
      }

      if (state.card_cvc) {
        if (state.card_cvc.errors.length && state.card_cvc.isTouched && !state.card_cvc.isValid) {
          this.errors.cvv = 'Card cvv is invalid';
        }
        if (state.card_cvc.isValid) {
          this.errors.cvv = '';
        }
      }

      if (state.card_exp) {
        if (state.card_exp.errors.length && state.card_exp.isTouched && !state.card_exp.isValid) {
          this.errors.expiryDate = 'Card expiry is invalid';
        }

        if (state.card_exp.isValid) {
          this.errors.expiryDate = '';
        }
      }
    },

    buildVgs() {
      const vgsForm = window.VGSCollect.create(
        process.env.VUE_APP_VGS_TOKEN!,
        process.env.VUE_APP_VGS_ENV as 'sandbox' | 'live',
        (state) => {
          this.formState = state;
          this.handleState(state);
        },
      );

      vgsForm.field('#cc-holder', {
        type: 'text',
        name: 'card_holder',
        placeholder: 'Card holder',
        validations: ['required'],
        tokenization: false,
      });

      vgsForm.field('#cc-number', {
        type: 'card-number',
        name: 'card_number',
        errorColor: '#D8000C',
        placeholder: 'Card number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        autoComplete: 'cc-number',
        tokenization: {
          format: 'FPE_SIX_T_FOUR',
        },
      });

      vgsForm.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        errorColor: '#D8000C',
        placeholder: 'CVC',
        validations: ['required', 'validCardSecurityCode'],
        autoComplete: 'cc-csc',
        tokenization: {
          format: 'UUID',
        },
      });

      vgsForm.field('#cc-expiration-date', {
        type: 'card-expiration-date',
        name: 'card_exp',
        errorColor: '#D8000C',
        placeholder: 'MM / YY',
        validations: ['required', 'validCardExpirationDate'],
        autoComplete: 'cc-exp',
        tokenization: false,
      });

      this.formField = vgsForm;
    },

    async submit() {
      this.error = '';

      this.isTokenizeLoading = true;
      const { response, status } = await this.tokenize();
      this.isTokenizeLoading = false;
      if (status !== 200) {
        this.error = `Authentication Failed: We apologize, but we were unable to verify your payment
        authentication. Please ensure that the information provided is correct and try again. If the
        issue persists, please contact our customer support for further assistance.`;
        return;
      }

      const form: CheckoutPaymentRequestBody = {
        provider: 'vgs',
        student: {
          firstName: this.form.student.firstName,
          lastName: this.form.student.lastName,
          email: this.form.student.email,
        },
        paymentData: {
          card: {
            cardNumberToken: response.card_number,
            cardCvcToken: response.card_cvc,
            cardExp: response.card_exp.replaceAll(' ', ''),
            cardHolderName: response.card_holder,
            countryCode: this.form.address.country.id,
            postalCode: this.form.address.postalCode,
          },
          billingAddress: {
            ...this.form.address,
            country: this.form.address.country.id,
          },
        },
        selectedPaymentPlanId: this.form.selectedPaymentPlanId,
      };
      await this.submitCheckout(form);

      this.getPlanData();
    },

    getPlanData() {
      const selectedPlan = this.paymentPlans.find((plan) => plan.id === this.form.selectedPaymentPlanId);

      if (!selectedPlan) {
        return {};
      }

      const obj = {
        amount: Dinero({ amount: selectedPlan.dueTodayInCents }).toUnit(),
        currency: this.product.currencyCode,
      };

      return obj;
    },

    async submitCheckout(requestBody: CheckoutPaymentRequestBody) {
      if (this.paymentState === 'loading') {
        return;
      }

      try {
        this.paymentState = 'loading';
        const completionDetails = await completeCheckout({ requestBody, sessionId: this.sessionId });

        this.$router.replace({ query: { checkoutSessionId: this.sessionId } });

        if (completionDetails.redirectPage) {
          this.$router.push({
            path: 'completed-redirect',
            query: {
              redirectPage: completionDetails.redirectPage,
              firstName: completionDetails.studentFirstName,
              lastName: completionDetails.studentLastName,
              productName: completionDetails.productName,
              email: completionDetails.email,
              price: `${completionDetails.price}`,
            },
          });
        } else {
          this.$router.push({
            path: 'completed',
            query: { ...this.$route.query },
          });
        }
      } catch (error) {
        this.handleErrorCheckout(error);
      } finally {
        this.paymentState = 'loaded';
      }
    },

    handleErrorCheckout(error) {
      const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

      if (!error?.type || error?.type === 'GenericError') {
        this.error = defaultMessage;
        return;
      }
      this.error = error?.message;
    },

    disclosureChange(agreed: boolean) {
      this.form.hasAgreedToConditions = agreed;
    },

    setErrorMessage(error: string) {
      this.$emit('setErrorMessage', error);
    },
  },
});
