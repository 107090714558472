


















































import Vue from 'vue';
import { debounce } from 'lodash';
import search from './dependencies/search';
import WbTextField from '@/components/text_field/index.vue';

export default Vue.extend({
  props: {
    label: {
      default: '',
      type: String,
    },

    placeholder: {
      default: '',
      type: String,
    },

    path: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      items: [],
      search: '',
      state: '',
      showOptions: false,
      searchResultsError: '',
    };
  },

  methods: {
    async getData(val: string) {
      if (val.length >= 3) {
        try {
          this.state = 'loading';
          const data = await search(this.path, val);
          this.items = data;
          this.state = 'loaded';
        } catch (error) {
          this.state = 'error';
          this.searchResultsError = (error as any)?.message || 'Something went wrong';
        }
      }
    },

    click(item) {
      this.$emit('clicked', item);
    },

    clickOutside() {
      this.showOptions = false;
    },
  },

  watch: {
    search: debounce(async function search(this: any, val: string) {
      if (val) await this.getData(val);
    }, 500),
  },

  components: {
    WbTextField,
  },
});
