

























































































import Vue from 'vue';
import { debounce } from 'lodash';
import { store, mutations } from '@/store/project';
import getProjects from './dependencies/get_projects';
import WbTextField from '@/components/text_field/index.vue';

export default Vue.extend({
  data() {
    return {
      selectedProjects: store.selectedProjects,
      projects: store.projects,
      search: '',
      state: '',
      showOptions: false,
    };
  },

  computed: {
    store() {
      return store;
    },
  },

  methods: {
    async searchForProject(val: string) {
      this.state = 'loading';
      const data = await getProjects(val);
      this.projects = data;
      mutations.setProjects(this.projects);
      this.state = 'loaded';
    },

    isSelectedProject(id) {
      return store.selectedProjects.findIndex((p) => p.id === id) > -1;
    },

    clear() {
      mutations.clearProjects();
      this.showOptions = false;
    },

    selectProject({ id, name }) {
      const project = { id, name };
      mutations.addProject(project);
    },

    removeProject({ id }) {
      mutations.removeProject(id);
    },

    clickOutside() {
      this.showOptions = false;
    },
  },

  watch: {
    search: debounce(async function search(this: any, val: string) {
      if (val) await this.searchForProject(val);
    }, 500),
  },

  components: {
    WbTextField,
  },
});
