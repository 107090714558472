import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { ApplicationStatus } from './types';

export function updateApplicationStatusCtor(http: AxiosInstance) {
  return async (id: string, status: ApplicationStatus) => {
    await http.put(`/lnpl/v1/onboarding-applications/${id}/status`, { status });
  };
}

export const updateApplicationStatus = updateApplicationStatusCtor(http);
