










import Vue from 'vue';
import { countryCodeToFlagUrl } from '../helpers';

export default Vue.extend({
  props: {
    countryCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagUrl(): string {
      return countryCodeToFlagUrl(this.countryCode);
    },
  },
});
