











































































































import Vue from 'vue';
import {
  getOrderDetails, updatePaymentMethod, LoanOrder,
} from './dependencies';
import CreditCard from './components/credit_card/index.vue';
import DirectDebit from './components/direct_debit/index.vue';
import PaymentOptionButton from './components/payment_option_button/index.vue';
import type { UpdatePaymentData } from './types';

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    CreditCard,
    DirectDebit,
    PaymentOptionButton,
  },

  data() {
    return {
      state: 'loading',
      paymentData: { canUseDirectDebit: false, canUseCreditCard: false },
      loanOrder: { } as LoanOrder,
      errorMessage: '',
      updateCompleted: false,
      paymentOptionSelected: 0,
      paymentState: 'loaded',
      paymentOptions: {
        DIRECT_DEBIT: 0,
        CREDIT_CARD: 1,
      },
      studentAddress: {},
    };
  },

  computed: {
    hasOnlyOneOption(this: any) {
      return !(this.paymentData.canUseCreditCard && this.paymentData.canUseDirectDebit);
    },

    hasCreditCardSelected(this: any) {
      return this.hasOnlyOneOption
        ? this.paymentData.canUseCreditCard
        : this.paymentOptionSelected === this.paymentOptions.CREDIT_CARD;
    },

    hasDirectDebitSelected(this: any) {
      return this.hasOnlyOneOption
        ? this.paymentData.canUseDirectDebit
        : this.paymentOptionSelected === this.paymentOptions.DIRECT_DEBIT;
    },
  },

  async mounted() {
    try {
      const { loanOrderId } = this.$route.params;
      const data = await getOrderDetails(loanOrderId);
      this.state = 'loaded';
      this.loanOrder = data;
      this.paymentData = {
        canUseCreditCard: this.loanOrder.availablePaymentOptions.card,
        canUseDirectDebit: this.loanOrder.availablePaymentOptions.bank,
      };
      this.studentAddress = {
        country: data.studentCountry,
        postalCode: data.studentPostalCode,
        region: data.studentRegion,
        city: data.studentCity,
        street: data.studentStreet,
      };
    } catch (error) {
      this.state = 'invalid';
    }
  },

  methods: {
    setErrorMessage(error) {
      this.errorMessage = error;
    },

    handleError(error: any) {
      const type = error?.data?.type;
      if (type === 'UnsupportedPaymentMethodUpdate') {
        this.errorMessage = error.data.message;
      }
    },

    async updatePayment(form: UpdatePaymentData) {
      if (this.paymentState === 'loading') {
        return;
      }

      try {
        this.errorMessage = '';
        this.paymentState = 'loading';

        const { loanOrderId } = this.$route.params;

        await updatePaymentMethod(loanOrderId, form);
        this.updateCompleted = true;
      } catch (error) {
        const err = error as any;
        const defaultMessage = `Sorry, we could not complete your request to update your payment.
        Please try another payment method or please try again later. Thank you!`;

        this.errorMessage = !err || !err.type ? defaultMessage : err.message;
      } finally {
        this.paymentState = 'loaded';
      }
    },
  },
});
