



























































































import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbProgress from '@/components/progress/progress.vue';
import getInvoices, { Invoice } from './dependencies/get_invoices';
import getSubscription, { Subscription } from './dependencies/get_subscription';

export default Vue.extend({
  name: 'PpmSubscription',

  components: {
    WbContentWrapper,
    WbProgress,
  },

  data() {
    return {
      state: '',
      invoices: [] as Invoice[],
      subscription: {} as Subscription,
      invoiceTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Invoice id', align: 'start', value: 'invoiceId', sortable: true,
          },
          {
            text: 'Status', align: 'start', value: 'status', sortable: true,
          },
          {
            text: 'Subtotal', align: 'start', value: 'subtotalAmount', sortable: true,
          },
          { text: 'Tax', value: 'totalTax', sortable: true },
          { text: 'Total amount', value: 'totalAmount', sortable: true },
          { text: 'Due date', value: 'dueDate', sortable: true },
          { text: 'Start date', value: 'startDate', sortable: true },
          { text: 'End date', value: 'endDate', sortable: true },
        ],
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const [invoices, subscription] = await Promise.all([
          getInvoices(this.$route.params.id),
          getSubscription(this.$route.params.id),
        ]);

        this.invoices = invoices;
        this.subscription = subscription;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    getStatusColor(status: string) {
      switch (status) {
      case 'active':
        return 'green';
      case 'canceled':
        return 'red';
      case 'pending':
        return 'orange';
      default:
        return 'grey';
      }
    },

  },
});
