import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface CreateVendorData {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  country: 'US' | 'CA';
  state: string;
  zip: string;
  taxId?: string;
  vendorUrl: string;
  vendorPrincipal: {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    country: string;
    zip: string;
    dob: string;
    personalIdentificationNumber?: string;
    email: string;
  };
}

function createVendorId(http: AxiosInstance) {
  return async (projectId: string, data: CreateVendorData) => {
    try {
      await http.post(`/lnpl/v1/projects/${projectId}/bluesnap-vendor`, data);
    } catch (err) {
      const error = err as any;
      if (error.data.type && error.data.message) {
        throw error.data;
      }
      throw error;
    }
  };
}

export default createVendorId(http);
