import http from '@admin/http';
import auth from '@/lib/auth/auth.admin';

interface SignInAttributes {
  email: string;
  password: string;
}

// @ts-ignore
function signInCtor({ http, auth }) {
  return async function signIn(form: SignInAttributes) {
    const { data } = await http.post('/auth/v1/login', form);
    const { token } = data;

    auth.signIn({ newToken: token, newRefreshToken: token });
  };
}

export default signInCtor({ http, auth });
