<template>
  <section class="card custom-section">
    <div class="calculator">
      <h3 class="mb-3">
        Accrued Interest Calculator
      </h3>
      <form @submit.prevent="calculateInterest">
        <div>
          <wb-text-field
            v-model="amountInCents"
            label="Amount in Cents"
            type="number"
          />
        </div>
        <div>
          <wb-text-field
            v-model="interestRate"
            label="Interest Rate (not percentage)"
            type="number"
            step="0.01"
          />
        </div>
        <div>
          <wb-text-field
            v-model="period"
            label="Period (how long)"
            type="number"
            step="1"
          />
        </div>
        <v-btn
          class="my-3"
          color="primary"
          type="submit"
        >
          Calculate
        </v-btn>
      </form>
      <div
        v-if="interestResult !== null"
      >
        <h4>Result:</h4>
        <p>{{ interestResult }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import WbTextField from '@/components/text_field/index.vue';
import { calculateAccruedInterestOverPeriod } from '../dependencies';

export default Vue.extend({
  name: 'AccruedInterestCalculator',
  components: {
    WbTextField,
  },
  data() {
    return {
      amountInCents: 0,
      interestRate: 0,
      period: 0,
      interestResult: null,
    };
  },
  methods: {
    async calculateInterest() {
      this.interestResult = null;
      try {
        const result = await calculateAccruedInterestOverPeriod({
          amountInCents: Number(this.amountInCents),
          interestRate: Number(this.interestRate),
          period: Number(this.period),
        });
        this.interestResult = result.interestInCents;
      } catch (error) {
        console.error('Error calculating interest:', error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.calculator {
  max-width: 25rem;
}

.custom-section {
  max-width: fit-content;
}
</style>
