import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { InvoiceStatus } from '../../../types';

export interface Invoice {
  invoiceId: string;
  currency: string;
  totalAmount: number;
  subtotalAmount: number;
  totalTax: number;
  createdAt: string;
  dueDate: string;
  startDate: string;
  endDate: string;
  chargedAt: string;
  status: InvoiceStatus;
}

function getInvoicesCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(projectId: string) {
    const { data } = await http.get(`/lnpl/v1/subscriptions/${projectId}/invoices`);
    return data;
  };
}

export default getInvoicesCtor({ http });
