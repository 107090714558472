import http from '@student/http';

export interface ChooseProductRequest {
  sessionId: string;
  projectId: string;
  paymentPlanId: string;
}

function chooseProductCtor({ http }) {
  return async function chooseProduct({ projectId, sessionId, paymentPlanId }: ChooseProductRequest) {
    const { data } = await http.post(`/checkout/${projectId}/${sessionId}/set-product-payment-plan`, {
      paymentPlanId,
    });

    return data;
  };
}

export default chooseProductCtor({ http });
