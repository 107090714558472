import { AxiosInstance } from 'axios';
import http from '../../../../http';

export interface CompleteCheckoutProps {
  sessionId: string;
  projectId: string;
  requestBody: CheckoutPaymentRequestBodyStripe;
}

export interface CheckoutPaymentRequestBodyStripe {
  sessionId: string;
  paymentMethod: 'card' | 'bank';
  provider: 'stripe';
  paymentData: {
    amountInCents: number;
    currency: string;
    confirmationTokenId: string;
    customerId: string;
    transactionId?: string;
    transactionErr?: any;
    card: {
      brand: string;
      type: string;
      last4: string;
      expYear: number;
      expMonth: number;
      name: string;
      countryCode: string | null;
      postalCode: string | null;
    };
  };
}

export interface CompletionDetails {
  price: number;
  studentFirstName: string;
  studentLastName: string;
  email: string;
  productName: string;
  redirectPage: string | null;
}

export interface PendingDetails {
  state: 'requires_action';
  clientSecret: string;
}

export interface CheckoutResultSuccess {
  status: 'success';
  data: CompletionDetails;
}

export interface CheckoutResultPending {
  status: 'pending';
  data: PendingDetails;
}

export type CheckoutResult = CheckoutResultSuccess | CheckoutResultPending;

function completeCheckoutCtor(http: AxiosInstance) {
  return async function completeCheckout({
    sessionId,
    projectId,
    requestBody,
  }: CompleteCheckoutProps): Promise<CheckoutResult> {
    try {
      const { data: apiResponse, status } = await http.post(
        `/checkout/${projectId}/${sessionId}/complete-checkout`,
        requestBody,
      );
      if (status > 300) {
        throw apiResponse;
      }

      if (apiResponse.status === 'success') {
        return {
          status: apiResponse.status,
          data: {
            ...apiResponse,
          },
        };
      }
      return apiResponse;
    } catch (err) {
      const error = err as any;
      if (error?.data.type) {
        throw error.data;
      }
      throw error;
    }
  };
}

export default completeCheckoutCtor(http);
