<template>
  <v-btn
    block
    @click="editAccount"
  >
    <div class="account-button-content">
      <v-icon
        small
        right
      >
        fas fa-check-circle
      </v-icon>
      <p>Checking {{ account.accountNumber }}</p>
      <v-icon
        small
        right
      >
        fas fa-arrow-right
      </v-icon>
    </div>
  </v-btn>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    account: {
      type: Object,
    },
  },

  methods: {
    editAccount() {
      this.$emit('editAccount', this.account);
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  max-width: 100%;
}

.account-button-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .v-icon {
    margin: 0 5px;
  }

  p {
    margin: 0 !important;
    font-size: 0.8rem;
  }
}
</style>
