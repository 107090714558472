
































































import Vue from 'vue';

interface PaymentDetails {
  card?: {
    type: 'credit' | 'debit' ;
    brand: string;
    lastFourDigits: string;
    expirationMonth: string;
    expirationYear: string;
  };
  bank?: {
    accountType: string;
    lastFiveDigitsAccountNumber: string;
    lastFiveDigitsRoutingNumber: string;
  };
}

export default Vue.extend({
  props: {
    paymentDetails: {
      type: Object,
      default: () => ({} as Partial<PaymentDetails>),
    },
  },
});
