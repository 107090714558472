import http from '@admin/http';
import { AxiosInstance } from 'axios';

function chargeMerchantCtor({ http }: { http: AxiosInstance }) {
  return async (projectId: string, amountInCents: number, currencyCode: string) => {
    await http.post(`/lnpl/v1/projects/${projectId}/charge-balance`, { amountInCents, currencyCode });
  };
}

export const chargeMerchant = chargeMerchantCtor({ http });
