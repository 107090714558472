import VueRouter from 'vue-router';
import auth from '@/lib/auth/auth.student';

export default function decorate(router: VueRouter) {
  router.beforeEach((to: any, from, next) => {
    document.title = `Elective | Learn Now. Pay Later${!!to.meta && to.meta.title ? ` - ${to.meta.title}` : ''}`;
    const isAuthenticated = auth.isAuthenticated();

    if (to.path.includes('/student/checkout/') || to.path.includes('/student/app/')) {
      if (isAuthenticated) next();
      else {
        const nextPage = to.path;
        const query: any = { next: nextPage, ...to.query };

        if (to.path.includes('/student/checkout/')) {
          query.projectId = to.params.projectId;
        }

        next({ path: '/student/login-phone', query });
      }
    } else {
      next();
    }
  });
}
