import { AxiosInstance } from 'axios';
import http from '@student/http';

export interface PrepaymentInfo {
  email: string | null;
  phone: string;
  billingCountry: string | null;
  billingState: string | null;
  billingZip: string | null;
  billingCity: string | null;
  billingAddress: string | null;
  currency: string | null;
  billingFirstName: string | null;
  billingLastName: string | null;
}

function getPrepaymentInfoCtor(http: AxiosInstance) {
  return async ({ projectId, sessionId }): Promise<PrepaymentInfo> => {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/prepayment-info`);
    return data;
  };
}

export default getPrepaymentInfoCtor(http);
