import { RouteConfig } from 'vue-router';
import StatusPage from './status_page/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '/status',
    component: StatusPage,
  },
];

export default routes;
