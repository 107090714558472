



























































































import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbDialog from '@/components/dialog/index.vue';
import { getCsvUrl } from './dependencies/get_csv_url';
import { submitApplications } from './dependencies/submit_applications';

export default Vue.extend({

  components: {
    WbContentWrapper,
    WbDialog,
  },
  data() {
    return {
      state: '',
      formState: '',
      url: '',
      csvFile: {} as File,
      uploadCsvResponse: [],
      uploadCsvError: '',
      openUploadCsvModal: false,
    };
  },

  computed: {
  },

  watch: {
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loaded';
        const response = await getCsvUrl();
        this.url = response.url;
      } catch (err) {
        this.state = 'error';
      }
    },

    showUploadCsvDialog() {
      this.openUploadCsvModal = true;
    },

    handleCsvChange(event: Event) {
      const uploadedFile = ((event.target as HTMLInputElement).files as FileList)[0];
      this.csvFile = uploadedFile;
    },

    async uploadCsv() {
      try {
        this.formState = 'loading';
        if (this.csvFile === {} as File) {
          this.uploadCsvError = 'No file found';
          return;
        }
        const response = await submitApplications(this.csvFile);
        await this.getData();
        this.formState = 'loaded';
        this.uploadCsvResponse = response;
      } catch (err) {
        this.formState = 'error';
        this.uploadCsvError = (err as any).message || 'Something went wrong';
      }
    },
  },
});
