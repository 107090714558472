








import Vue from 'vue';
import mixpanel from 'mixpanel-browser';
import http from '@/lib/http';

const publicHttp = http({ name: 'public', path: '/' });

export default Vue.extend({
  async created() {
    try {
      await publicHttp.get('/ping');
      if (this.$route.name === 'App') {
        this.$router.replace({ path: '/creator/app' });
      }

      const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;
      if (mixpanelToken) {
        try {
          mixpanel.init(mixpanelToken, {
            debug: true,
            track_pageview: false,
            persistence: 'localStorage',
          });
        } catch (e) {
          // .. do nothing
        }
      }
    } catch (error) {
      const next = this.$route.query.next || window.location.pathname;
      this.$router.push({ path: '/status', query: { next } });
    }
  },
});
