import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export function exchangeTokensCtor(http: AxiosInstance) {
  return async function exchangeTokens(publicToken: string): Promise<any> {
    const { data } = await http.post('/bank/token', {
      publicToken,
    });
    return data;
  };
}

export default exchangeTokensCtor(http);
