import VueRouter from 'vue-router';
import auth from '@/lib/auth/auth.creator';

export default function decorate(router: VueRouter) {
  router.beforeEach((to, from, next) => {
    document.title = `Elective ${!!to.meta && to.meta.title ? to.meta.title : ''}`;

    const isAuthenticated = auth.isAuthenticated();
    const hasLnplAccess = auth.hasLnplAccess();

    if (to.path.includes('/creator/app')) {
      if (isAuthenticated) {
        if (!hasLnplAccess) {
          next({ path: '/creator/request-invite-lnpl?source=analytics-deprecated' });
        } else {
          next();
        }
      } else {
        const params = to.query;
        next({ path: '/creator/sign-in', query: { next: to.path, ...params } });
      }
    } else if (isAuthenticated && to.matched.map((m) => m.path).includes('/auth')) {
      next({ path: '/creator/app/lnpl' });
    } else {
      next();
    }
  });
}
