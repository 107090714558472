import { AxiosInstance } from 'axios';
import http from '../../../../../../http';

interface BillingInfo {
  email: string;
  phone: string;
  country: string;
  state: string;
  postalCode: string;
  city: string;
  firstName: string;
  lastName: string;
}

interface Params {
  projectId: string;
  billingInfo: BillingInfo;
}

function createCustomerCtor(http: AxiosInstance) {
  return async (params: Params) => {
    const { data } = await http.post(`/checkout/stripe/${params.projectId}/customer`, {
      billingInfo: params.billingInfo,
    });
    return data;
  };
}

export default createCustomerCtor(http);
