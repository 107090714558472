import { AxiosInstance } from 'axios';
import http from '@student/http';

function getCardSessionToken(http: AxiosInstance) {
  return async ({ projectId, sessionId }): Promise<string> => {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/payment-methods/card/session-token`);
    return data;
  };
}

export default getCardSessionToken(http);
