

















































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    metrics: {
      type: Object,
      default: () => ({}),
    },

    integrationState: {
      type: Object,
      default: () => ({}),
    },

    metricPath: {
      type: String,
      default: '',
    },

    inverseMetrics: {
      type: Boolean,
      default: false,
    },

    state: {
      type: String,
      default: 'loading',
    },
  },

  methods: {
    getChangeIcon(value: number) {
      if (this.inverseMetrics) {
        if (+value > 0) return 'fa-arrow-circle-down';
        return 'fa-arrow-circle-up';
      }
      if (+value > 0) return 'fa-arrow-circle-up';
      return 'fa-arrow-circle-down';
    },

    getColor(value: number) {
      if (this.inverseMetrics) {
        if (+value > 0) return 'negative';
        return 'positive';
      }
      if (+value > 0) return 'positive';
      return 'negative';
    },

    // eslint-disable
    getBenchMarkIcon(value: string) {
      let icon = '';

      if (value === 'Hot') {
        icon = '🔥';
      } else if (value === 'Strong') {
        icon = '💪';
      } else if (value === 'Average') {
        icon = '👍';
      } else if (value === 'Cold') {
        icon = '❄️';
      } else if (value === 'Icey') {
        icon = '🥶';
      }

      return icon;
    },
  },
});
