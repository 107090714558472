export enum DelinquencyState {
  RECALLED = 'Recalled',
  SENT_TO_AGENCY = 'Sent to Collections Agency',
  ARCHIVED = 'Archived',
  READY = 'Ready to be Sent',
}

export interface DelinquentDateStates {
  recalledAt?: Date;
  archivedAt?: Date;
  blockedFromCollectionsAt?: Date;
  sentToAgencyAt?: Date;
}

export function getDelinquentReadableState({
  recalledAt,
  archivedAt,
  blockedFromCollectionsAt,
  sentToAgencyAt,
}: DelinquentDateStates) {
  if (recalledAt) {
    return DelinquencyState.RECALLED;
  }
  if (sentToAgencyAt) {
    return DelinquencyState.SENT_TO_AGENCY;
  }
  if (blockedFromCollectionsAt || archivedAt) {
    return DelinquencyState.ARCHIVED;
  }
  return DelinquencyState.READY;
}
