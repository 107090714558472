












import Vue from 'vue';
import createLinkToken from './dependencies/create_link_token';
import exchangeTokens from './dependencies/exchange_tokens';

export default Vue.extend({
  name: 'PlaidLink',
  props: {
    env: {
      type: String,
      default: 'sandbox',
    },
    webhook: String,
    onAccountLinkedSuccess: Function,
    onAccountLinkedFail: Function,
  },

  data() {
    return {
      loading: false,
      plaidUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
      clientName: 'Wizebank',
    };
  },

  created() {
    this.loadScript(this.plaidUrl).catch(this.onScriptError);
  },

  beforeDestroy() {
    if ((window as any).linkHandler) {
      (window as any).linkHandler.exit();
    }
  },

  methods: {
    loadScript(src: string) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve(null);
          return;
        }

        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.src = src;
        el.addEventListener('load', resolve);
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        document.head.appendChild(el);
      });
    },

    onScriptError(error: any) {
      console.error('There was an issue loading the link-initialize.js script', error);
    },

    onScriptLoaded(token: string) {
      (window as any).linkHandler = (window as any).Plaid.create({
        clientName: this.clientName,
        env: this.env,
        onSuccess: this.onPublicTokenCreated,
        onEvent: this.onEvent,
        webhook: this.webhook,
        token,
      });
    },

    async handleOnClick() {
      try {
        this.loading = true;
        const token = (await createLinkToken()).linkToken;

        this.onScriptLoaded(token);

        if ((window as any).linkHandler) {
          (window as any).linkHandler.open();
        }
      } catch (error) {
        this.$emit('error', 'Error opening plaid modal');
      } finally {
        this.loading = false;
      }
    },

    async onEvent(event: string) {
      if (event === 'EXIT') {
        this.loading = false;
      }
    },

    async onPublicTokenCreated(token: string) {
      try {
        await exchangeTokens(token);
        this.onAccountLinkedSuccess();
      } catch (error) {
        this.$emit('error', 'Error trying to connect account with plaid');
      } finally {
        this.loading = false;
      }
    },
  },
});
