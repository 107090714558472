import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Params {
  startDate: Date | string;
  endDate: Date | string;
  grouping: string;
  projectIds: string[];
  order?: 'ASC' | 'DESC';
  orderBy?: 'id' |
            'currency' |
            'installments' |
            'amountInCents' |
            'downPaymentPercentage' |
            'loanAgreementFileName' |
            'createdAt' |
            'studentId' |
            'projectId' |
            'origin' |
            'status' |
            'studentName' |
            'projectName';
}

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(params: Params) {
    const { data } = await http.get('/lnpl/v1/orders', { params: { ...params } });
    return data;
  };
}

export default getOrdersCtor({ http });
