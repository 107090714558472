




























































































































































































































import Vue from 'vue';
import Dinero from 'dinero.js';
import WbTextField from '@/components/text_field/index.vue';
import initCheckout, { Init } from './dependencies/init';
import completeCheckout, { CheckoutPaymentRequestBody } from './dependencies/complete_checkout';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import CardBrand from './components/index.vue';
import { TokenResponse } from './dependencies/types';

interface Country {
  id: string;
  name: string;
  callingCode: number;
}

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    WbTextField,
    WbCountry,
    WbTextFieldLoader,
    WbParagraphLoader,
    Disclosure,
    CardBrand,
  },

  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      productId: '',
      product: {
        name: '',
        priceInCents: 0,
        currencyCode: '',
      },
      logoUrl: '',
      sessionId: '',
      termsOfServiceUrl: '',
      projectName: '',
      paymentPlans: [] as Init['paymentPlans'],
      state: 'loading',
      paymentState: '',
      creditCardVendor: '',
      error: '',
      invalid: true,
      countries: [] as Country[],
      form: {
        card: {
          nameOnCard: '',
          token: '',
        },
        student: {
          firstName: '',
          lastName: '',
          email: '',
        },
        address: {
          country: {} as any,
          postalCode: '',
        },
        selectedPaymentPlanId: null,
        hasAgreedToConditions: false,
      },
      errors: {
        nameOnCard: '',
        ccnumber: '',
        ccexp: '',
        cvv: '',
      },
      formInputsState: 'loading',
      contries: [
        { code: 'us', name: 'United States' },
        { code: 'ca', name: 'Canada' },
      ],
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.form.hasAgreedToConditions
        && this.form.card.nameOnCard
        && this.form.student.firstName
        && this.form.student.lastName
        && this.form.student.email
        && this.form.address.country
        && this.form.address.postalCode
        && this.form.selectedPaymentPlanId;
    },
  },

  async mounted() {
    this.productId = this.$route.params.productId as string;

    if (!this.productId) {
      this.$router.replace({
        path: `/student/checkout-full/${this.productId}/404`,
      });
      return;
    }

    try {
      this.formInputsState = 'loading';
      const init = await initCheckout(this.productId);
      this.sessionId = init.sessionId;
      this.product.name = init.product.name;
      this.product.priceInCents = init.product.priceInCents;
      this.product.currencyCode = init.product.currencyCode;
      this.projectName = init.product.projectName;
      this.termsOfServiceUrl = init.product.termsOfServiceUrl || '';

      this.countries = init.countries;

      this.form.address.country = this.countries.find((country) => country.id === 'US') || {};

      this.paymentPlans = init.paymentPlans;
      this.logoUrl = init.logoUrl;
      const { tokenizationKey, collectCheckoutKey } = this.getScriptCredentials(init.cardToken);
      await this.setScript({
        src: 'https://secure.networkmerchants.com/token/Collect.js',
        'data-tokenization-key': tokenizationKey,
      });

      await this.setScript({
        src: 'https://secure.networkmerchants.com/js/v1/Gateway.js',
      });

      // @ts-ignore
      const gateway = window.Gateway.create(collectCheckoutKey);
      const threeDS = gateway.get3DSecure();
      const ignore3DS = !this.is3DSecureEnabled(await gateway.configPromise);

      // TODO: @lucas fix this TS-IGNORE
      // @ts-ignore
      await window.CollectJS.configure({
        variant: 'inline',
        styleSniffer: true,
        validationCallback: (field, status, message) => {
          if (!status) {
            this.errors[field] = message;
          } else {
            this.errors[field] = '';
          }
        },
        timeoutDuration: 10000,
        timeoutCallback: () => {
          console.log('timeout');
          this.$emit('setErrorMessage', `
            We had trouble loading the credit card form. Try and refresh your browser.
            If this persists, please reach out to us.
          `);
        },
        fieldsAvailableCallback: (e) => {
          console.log('fields available');
        },
        placeholderCss: {
        },
        fields: {
          ccnumber: {
            placeholder: '4111 2222 3333 4444',
            selector: '#ccnumber',
            // enableCardBrandPreviews: true,
          },
          ccexp: {
            placeholder: 'MM / YY',
            selector: '#ccexp',
          },
          cvv: {
            placeholder: '123',
            selector: '#cvv',
          },
        },
        callback: async (token: TokenResponse) => {
          if (this.paymentState === 'loading') {
            return;
          }
          this.paymentState = 'loading';
          const planUsed = this.getPlanData();
          let options = {
            paymentToken: token.token,
            currency: planUsed.currency,
            amount: planUsed.amount,
            email: this.form.student.email,
            country: this.form.address.country.id,
            firstName: this.form.student.firstName,
            lastName: this.form.student.lastName,
            postalCode: this.form.address.postalCode,
          };
          const threeDSPromise = new Promise((resolve, reject) => {
            if (ignore3DS) {
              resolve('');
            }
            const threeDSecureInterface = threeDS.createUI(options);
            threeDSecureInterface.start('#threeDSMountPoint');

            threeDSecureInterface.on('challenge', (e) => {
              console.log('Challenged');
            });

            threeDSecureInterface.on('complete', (e) => {
              options = { ...options, ...e };
              resolve('');
            });

            threeDSecureInterface.on('failure', (e) => {
              reject(e);
            });

            gateway.on('error', (e) => {
              console.error(e);
              reject(e);
            });
          });
          try {
            await threeDSPromise;
            await this.submitCheckout({ ...token, ...options });
          } catch (error) {
            console.log(error);
            this.handleErrorCheckout(error);
          } finally {
            this.paymentState = 'loaded';
          }
        },
      });
      this.formInputsState = 'loaded';
      this.paymentState = 'loading';
      const inputFound = await this.findCCNumberInput();
      if (inputFound) {
        this.paymentState = 'loaded';
      }
    } catch (error) {
      this.handleInitError(error);
    }
  },

  methods: {
    findCCNumberInput() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          const iframe = document.getElementById('CollectJSInlineccnumber');
          if (iframe && iframe.ownerDocument) {
            const ccNumberInput = iframe.ownerDocument.getElementById('ccnumber');
            if (ccNumberInput) {
              clearInterval(intervalId);
              resolve(true);
            }
          }
        }, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
          resolve(false);
        }, 5000);
      });
    },
    getScriptCredentials(credentials: string) {
      const parsed = JSON.parse(credentials);
      return {
        tokenizationKey: parsed.tokenizationKey,
        collectCheckoutKey: parsed.collectCheckoutKey,
      };
    },
    is3DSecureEnabled(gateway: any) {
      return gateway.find((config) => config.name === '3DSecure')?.status === 'active';
    },
    async setScript(attr: Record<string, string>): Promise<void> {
      const script = document.createElement('script');
      Object.entries(attr).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });

      const scriptLoadPromise = new Promise((resolve) => {
        script.onload = resolve;
      });

      document.head.appendChild(script);

      await scriptLoadPromise;
    },
    getPlanData(): { amount?: number; currency?: string } {
      const selectedPlan = this.paymentPlans.find((plan) => plan.id === this.form.selectedPaymentPlanId);

      if (!selectedPlan) {
        return {};
      }

      const obj = {
        amount: Dinero({ amount: selectedPlan.dueTodayInCents }).toUnit(),
        currency: this.product.currencyCode,
      };

      return obj;
    },
    handleInitError(error) {
      if (
        error?.status === 404
        || error?.data?.type === 'NoProductFound'
        || error?.data?.type === 'CheckoutNotAvailable'
      ) {
        this.$router.replace({ path: '404' });
        return;
      }

      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    },

    onChangeName() {
      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },

    async submitCheckout(tokenResponse: TokenResponse) {
      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
        return;
      }

      const requestBody: CheckoutPaymentRequestBody = {
        student: {
          firstName: this.form.student.firstName,
          lastName: this.form.student.lastName,
          email: this.form.student.email,
        },
        provider: 'nmi',
        paymentData: {
          card: {
            cardToken: tokenResponse.token,
            cardType: tokenResponse.card.type,
            lastFourDigits: tokenResponse.card.number.slice(-4),
            cardExp: `${tokenResponse.card.exp.slice(0, 2)}/${tokenResponse.card.exp.slice(2)}`,
          },
          billingAddress: {
            ...this.form.address,
            country: this.form.address.country.id,
          },
        },
        selectedPaymentPlanId: this.form.selectedPaymentPlanId,
      };

      if (!this.isFormValid) {
        this.error = 'Please fill out all required fields.';
        return;
      }

      try {
        this.paymentState = 'loading';
        const completionDetails = await completeCheckout({ requestBody, sessionId: this.sessionId });

        this.$router.replace({ query: { checkoutSessionId: this.sessionId } });

        if (completionDetails.redirectPage) {
          this.$router.push({
            path: 'completed-redirect',
            query: {
              redirectPage: completionDetails.redirectPage,
              firstName: completionDetails.studentFirstName,
              lastName: completionDetails.studentLastName,
              productName: completionDetails.productName,
              email: completionDetails.email,
              price: `${completionDetails.price}`,
            },
          });
        } else {
          this.$router.push({
            path: 'completed',
            query: { ...this.$route.query },
          });
        }
      } catch (error) {
        this.handleErrorCheckout(error);
      } finally {
        this.paymentState = 'loaded';
      }
    },

    handleErrorCheckout(error) {
      const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

      if (!error?.type || error?.type === 'GenericError') {
        this.error = defaultMessage;
        return;
      }
      this.error = error?.message;
    },

    disclosureChange(agreed: boolean) {
      this.form.hasAgreedToConditions = agreed;
    },

    setErrorMessage(error: string) {
      this.$emit('setErrorMessage', error);
    },
  },
});
