import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#424A68',
        secondary: '#E26E4B',
        accent: '#C2E8EE',
        info: '#000',
        success: '#11C182',
        warning: '#f3663f',
      },
    },
  },
});
