import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { KycStatus } from './types';

export function updateKycStatusCtor(http: AxiosInstance) {
  return async (id: string, status: KycStatus) => {
    await http.put(`/lnpl/v1/onboarding-applications/${id}/kyc-status`, { status });
  };
}

export const updateKycStatus = updateKycStatusCtor(http);
