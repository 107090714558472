import { AxiosInstance } from 'axios';
import http from '@admin/http';

export interface Subscription {
  id: string;
  name: string;
  amountInCents: number;
  currency: string;
  interval: 'month' | 'annual';
  createdAt: Date;
  updatedAt: Date;
  overagePercentage: number;
  transactionLimit: number;
  isActive: boolean;
}

function ppmSubscriptionsCtor({ http }: { http: AxiosInstance }) {
  async function getSubscriptions(): Promise<Subscription[]> {
    const { data } = await http.get<Subscription[]>('/lnpl/v1/subscriptions/plans');
    return data;
  }

  async function createSubscriptions(id: string, planId: string): Promise<void> {
    const response = await http.post(`/lnpl/v1/projects/${id}/ppm/subscription-plan`, {
      planId,
    }, { validateStatus: () => true });

    if (response.status !== 200) {
      throw response.data;
    }
  }

  async function getProjectSubscriptionPlan(id: string): Promise<Subscription | undefined> {
    const response = await http.get<Subscription | undefined>(`/lnpl/v1/projects/${id}/ppm/subscription-plan`);
    return response.data;
  }

  return {
    getSubscriptions,
    createSubscriptions,
    getProjectSubscriptionPlan,
  };
}

export const ppmSubscriptions = ppmSubscriptionsCtor({ http });
