import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

interface Params {
  projectId: string;
  sessionId: string;
}

function getStripeConfigsCtor(http: AxiosInstance) {
  return async (params: Params) => {
    const { data } = await http.get(`/checkout/stripe/${params.projectId}/configs`);
    return data;
  };
}

export default getStripeConfigsCtor(http);
