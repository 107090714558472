

























import Vue from 'vue';
import Visa from './visa.vue';
import Mastercard from './mastercard.vue';
import Discover from './discover.vue';
import Amex from './amex.vue';

export default Vue.extend({
  components: {
    Visa,
    Mastercard,
    Amex,
    Discover,
  },

  props: {
    variant: {
      type: String,
      default: '',
    },
  },
});
