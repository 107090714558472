import http from '@admin/http';
import { AxiosInstance } from 'axios';

function archiveKycFormsCtor({ http }: { http: AxiosInstance }) {
  return async (id: string): Promise<void> => {
    await http.delete(`/lnpl/v1/kyc-forms/${id}`);
  };
}

export default archiveKycFormsCtor({ http });
