

































import Vue from 'vue';

export default Vue.extend({
  methods: {
    openVerificationLink() {
      window.open('https://www.elective.com/verification', '_blank')?.focus();
    },
  },
});
