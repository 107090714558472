











import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '100%',
    },
  },
});
