




















import Vue from 'vue';

export default Vue.extend({
  computed: {
    hasProjectId(this: any) {
      return this.$route.params.projectId;
    },
  },

  methods: {
    goToLandingPage() {
      this.$router.push({ path: `/student/landing/${this.$route.params.projectId}`, query: this.$route.query });
    },
  },
});
