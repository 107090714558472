<template>
  <div class="flex">
    <AccruedInterestCalculator
      class="mb-3"
    />
    <AccruedInterestSchedule
      class="mb-3"
    />
    <AmortizationSchedule
      class="mb-3"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import AccruedInterestCalculator from './components/accrued_interest_calculator.vue';
import AccruedInterestSchedule from './components/accrued_interest_schedule.vue';
import AmortizationSchedule from './components/amortization_schedule.vue';

export default Vue.extend({
  components: {
    AccruedInterestCalculator,
    AccruedInterestSchedule,
    AmortizationSchedule,
  },
});
</script>
