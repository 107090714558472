import http from '../../../http';

function sendOtpCtor({ http }) {
  return async function sendOtp(
    phoneNumber: string,
    phoneCountryCode: number,
    countryCode: string,
  ): Promise<string> {
    const { data: { sessionId } } = await http.post('/auth/login-phone', {
      phoneNumber,
      phoneCountryCode,
      countryCode,
    });

    return sessionId;
  };
}

export default sendOtpCtor({ http });
