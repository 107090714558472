export interface DownloadFileParams {
  file: Blob;
  fileName: string;
}

export default function downloadFile({
  file,
  fileName,
}: DownloadFileParams) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = fileName;
  link.click();
}
