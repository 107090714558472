<template>
  <component
    :is="componentToRender"
    v-if="componentToRender"
    v-bind="$props"
    v-on="$listeners"
  />
</template>

<script>
import Payment from '../credit_card/index.vue';
import PaymentBs from '../credit_card_bs/index.vue';
import PaymentNmi from '../nmi/index.vue';
import PaymentStripe from '../credit_card_stripe/index.vue';

export default {
  props: {
    businessName: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    dueToday: {
      type: Number,
    },
    termsOfServiceUrl: {
      type: String,
    },
    ppmPaymentProvider: {
      type: String,
    },
  },
  data() {
    return {
      componentToRender: null,
    };
  },
  mounted() {
    const { projectId } = this.$route.params;
    const { productId } = this.$route.query;

    if (this.origin === 'lnpl') {
      this.componentToRender = PaymentBs;
      return;
    }

    switch (this.ppmPaymentProvider.toLowerCase()) {
    case 'vgs':
      this.componentToRender = Payment;
      break;
    case 'bluesnap_relay':
    case 'bluesnap_ppm':
      this.componentToRender = PaymentBs;
      break;
    case 'nmi':
      this.componentToRender = PaymentNmi;
      break;
    case 'stripe':
      this.componentToRender = PaymentStripe;
      break;
    default:
      this.componentToRender = null;
      this.$router.push(
        {
          path: `/student/checkout/${projectId}/${projectId}/error`,
          query: { productId },
        },
      );
      break;
    }
  },
};
</script>
