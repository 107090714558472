import http from '../../../views/student/http';

export interface CompleteResponse {
    streetNumber?: string;
    streetName?: string;
    country?: string;
    region?: string;
    postal?: string;
    city?: string;
    countryCode?: string;
    regionCode?: string;
    complement?: string;
  }

function completeCtor({ http }) {
  return async (predictionId: string, sessionId: string) => {
    const { data } = await http.post('/checkout/address-completion', {
      predictionId,
      sessionId,
    });
    return data as CompleteResponse;
  };
}

export default completeCtor({ http });
