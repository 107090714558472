import { RouteConfig } from 'vue-router';

import ErrorPage from './views/error_page/index.vue';
import NotFound from './views/not_found/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: 'error',
    name: 'Error',
    component: () => import('./index.vue'),
    children: [
      {
        path: '',
        name: 'ErrorPage',
        component: ErrorPage,
      },
      {
        path: '404',
        name: 'NotFound',
        component: NotFound,
      },
    ],
  },
];

export default routes;
