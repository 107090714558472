














































































































































import Vue from 'vue';
import moment from 'moment';
import { store, mutations } from '@/store/date_range';

export default Vue.extend({
  props: {
    min: {
      default: moment().subtract(1, 'year').format(),
      type: String,
    },
    max: {
      default: moment().format(),
      type: String,
    },
    showGrouping: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      dateRangeMenu: false,
      datePickerGroupings: [
        {
          text: 'Days',
          value: 'day',
          disabled: false,
        },
        {
          text: 'Weeks',
          value: 'week',
          disabled: false,
        },
        {
          text: 'Months',
          value: 'month',
          disabled: false,
        },
      ],
      currentDateRange: [store.dateRange.startDate, store.dateRange.endDate],
      currentDateRangeGrouping: store.dateRange.grouping,
    };
  },

  computed: {
    currentDateRangeText() {
      // @ts-ignore
      return this.currentDateRange.map((d) => moment(d).format('MMM Do YYYY')).join(' to ');
    },
  },

  watch: {
    currentDateRange(dateRange) {
      this.setDateRange(dateRange);
    },
  },

  methods: {
    canselSetDateRange() {
      this.currentDateRange = [
        store.dateRange.startDate,
        store.dateRange.endDate,
      ];
      this.dateRangeMenu = false;
    },

    setDateRangePresets(type: string, number = 0) {
      if (type === 'days' || type === 'months') {
        this.currentDateRange = [
          moment().subtract(number, type).format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      } else if (type === 'mtd') {
        this.currentDateRange = [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD'),
        ];
      } else if (type === 'pm') {
        this.currentDateRange = [
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        ];
      } else if (type === 'ytd') {
        this.currentDateRange = [
          moment().startOf('year').format('YYYY-MM-DD'),
          moment().endOf('year').format('YYYY-MM-DD'),
        ];
      } else if (type === 'py') {
        this.currentDateRange = [
          moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        ];
      } else if (type === 'all') {
        this.currentDateRange = [
          moment(this.min).format('YYYY-MM-DD'),
          moment(this.max).format('YYYY-MM-DD'),
        ];
      }
    },

    setDateRange(dateRange: string[]) {
      if (dateRange.length < 2) return;

      const startDate = moment(dateRange[0]);
      const endDate = moment(dateRange[1]);
      if (endDate.isBefore(startDate)) {
        const actualStartDate = endDate.format('YYYY-MM-DD');
        const actualEndDate = startDate.format('YYYY-MM-DD');
        this.currentDateRange = [actualStartDate, actualEndDate];
        dateRange = [actualStartDate, actualEndDate];
      }

      // @ts-ignore
      this.$refs.dateRangeMenu.save(dateRange);
      // @ts-ignore
      mutations.setDateRange({
        // @ts-ignore
        startDate: dateRange[0],
        // @ts-ignore
        endDate: dateRange[1],
      });
    },

    setDateRangeGrouping(grouping: string) {
      mutations.setDateRangeGrouping(grouping);
    },
  },
});
