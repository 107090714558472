import { RouteConfig } from 'vue-router';

import updatePaymentRoutes from './views/update_payment/routes';

export const routes: Array<RouteConfig> = [
  {
    path: 'app',
    name: 'StudentApp',
    component: () => import('./index.vue'),
    children: [
      ...updatePaymentRoutes,
    ],
  },
];

export default routes;
