import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface PlanGroup {
  id: string;
  name: string;
}

function getPaymentPlanGroupsCtor(http: AxiosInstance) {
  return async (): Promise<PlanGroup[]> => {
    const { data } = await http.get('/lnpl/v1/payment-plan-groups');
    return data;
  };
}

export const getPaymentPlanGroups = getPaymentPlanGroupsCtor(http);
