import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface InstallmentData {
  id: string;
  dueDate: Date;
  currency: string;
  amountInCents: number;
  status: string;
}

function manageInstallmentCtor({ http }: { http: AxiosInstance }) {
  return async (orderId: string, installments: InstallmentData[]) => {
    const response = await http.put(
      `/lnpl/v1/orders/${orderId}/installments`,
      installments,
      { validateStatus: () => true },
    );

    if (response.status > 299) {
      throw response.data;
    }
  };
}

export const manageInstallment = manageInstallmentCtor({ http });
