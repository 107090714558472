import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { FilterOptions } from '@/components/table/dependencies/types';

function archiveCreditCheckCtor({ http }: { http: AxiosInstance }) {
  return async (id: string): Promise<void> => {
    await http.delete(`/lnpl/v1/credit-checks/${id}`);
  };
}

export default archiveCreditCheckCtor({ http });
