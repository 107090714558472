import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

export interface Country {
    id: string;
    name: string;
    callingCode: number;
    requiresPostalCode: boolean;
}

export type State = { countries: Country[] }

function initCheckoutCtor(http: AxiosInstance) {
  return async () => {
    const { data } = await http.get<Promise<State>>('/init');
    return data;
  };
}

export default initCheckoutCtor(http);
