import { AxiosInstance } from 'axios';
import http from '../../../../http';

export interface CompletedCheckoutSession {
  productName: string;
  creatorName: string;
  currencyCode: string;
  pricePerMonth: number;
  orderTotal: number;
  studentEmail: string;
  firstPaymentInCents: number;
}

function getCompletedOrder(http: AxiosInstance) {
  return async function completeCheckout(projectId: string, sessionId: string): Promise<CompletedCheckoutSession> {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/completed`);
    return data;
  };
}

export default getCompletedOrder(http);
