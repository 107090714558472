





























import Vue from 'vue';

export default Vue.extend({
  name: 'PaymentOptionButton',
  props: {
    valuePerMonth: {
      type: Number,
    },
    instalments: {
      type: Number,
    },
    state: {
      type: String,
      default: 'loading',
    },
    selected: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
});
