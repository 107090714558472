import { RouteConfig } from 'vue-router';
import authRoutes from './auth/routes';
import appRoutes from './app/routes';

export const routes: Array<RouteConfig> = [
  {
    path: '/admin',
    component: () => import('./index.vue'),
    children: [
      ...authRoutes,
      ...appRoutes,
    ],
  },
];

export default routes;
