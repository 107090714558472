import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { PaymentPlansCreation } from './types';

function savePaymentPlansCtor(http: AxiosInstance) {
  return async (creationData: PaymentPlansCreation) => {
    const { data } = await http.post(`/lnpl/v1/payment-plan-groups/${creationData.groupId}`, {
      paymentPlans: creationData.paymentPlans,
    });
    return data;
  };
}

export const savePaymentPlans = savePaymentPlansCtor(http);
