var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wb-content-wrapper',{attrs:{"state":_vm.state}},[_c('h1',{staticClass:"table-title"},[_vm._v(" Order History ")]),_c('wb-select-project-api'),_c('wb-date-picker'),_c('div',{staticClass:"mt-6"},[_c('wb-search-api',{attrs:{"label":"Search Orders","placeholder":"Enter order info","path":"/lnpl/v1/orders/search"},on:{"clicked":_vm.orderSearchClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":"secondary","label":""}},[_vm._v(" "+_vm._s(item.studentName)+" ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":"secondary","label":""}},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":"secondary","label":""}},[_vm._v(" "+_vm._s(item.projectName)+" ")]),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":"secondary","label":""}},[_vm._v(" "+_vm._s(item.purchasedAt)+" ")])]}}])})],1),_c('section',[_c('wb-metrics',{key:"conversionRate",attrs:{"metrics":_vm.metrics,"state":_vm.state,"integration-state":{ status: 'data_sync_completed'}}},[_c('h2',[_vm._v(" "+_vm._s(_vm._f("currencyInCents")(_vm.metrics.total))+" ")])])],1),_c('section',[_c('wb-line-chart-period-comparison',{attrs:{"chart-data":_vm.chartData}})],1),_c('section',[_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.orders,"items-per-page":_vm.table.itemsPerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("orders/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.projectName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/app/projects/" + (item.projectId))}},[_vm._v(" "+_vm._s(item.projectName)+" ")])]}},{key:"item.studentName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/app/students/" + (item.studentId))}},[_vm._v(" "+_vm._s(item.studentName)+" ")])]}},{key:"item.amountInCents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(_vm._f("currencyInCents")(item.amountInCents))+" ")]}},{key:"item.downPaymentPercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.downPaymentPercentage)+"% ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.loanAgreementFileName",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://wizebank.s3.amazonaws.com/" + (item.loanAgreementFileName)),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" Link ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }