import http from '@admin/http';
import { AxiosInstance } from 'axios';

function search({ http }: { http: AxiosInstance }) {
  return async function search(path, text) {
    const { data } = await http.get(path, { params: { text } });
    return data;
  };
}

export default search({ http });
