























import Vue from 'vue';
import http from '@/lib/http';
import ErrorLayout from '@/layouts/error/index.vue';

const publicHttp = http({ name: 'public', path: '/' });

export default Vue.extend({

  components: {
    ErrorLayout,
  },
  data() {
    return {
      status: '',
    };
  },

  async created() {
    try {
      await publicHttp.get('/ping');
      this.status = 'online';

      if (this.$route.query.next && typeof this.$route.query.next === 'string') {
        this.$router.replace({ path: this.$route.query.next });
      }
    } catch (error) {
      this.status = 'offline';
    }
  },
});
