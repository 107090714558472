import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

function submitSinCtor(http: AxiosInstance) {
  return async (projectId: string, sessionId: string, form: { freezeOverrideCode: string }) => {
    const { data } = await http.post(`/checkout/${projectId}/${sessionId}/override-frozen-credit-profile`, form);
    return data;
  };
}

export default submitSinCtor(http);
