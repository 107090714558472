import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface Product {
  id: string;
  name: string;
  description: string;
  currencyCode: string;
  state: string;
  archivedAt: string | null;
  createdAt: string;
  priceInCents: number;
  coursePlatform: string | null;
  courseIdentifier: string | null;
  courseIdentifierType: string | null;
  monthsUntilExpire: number | null;
  redirectPage: string | null;
  ppm: boolean;
}

function getProductsCtor({ http }: { http: AxiosInstance }) {
  return async (projectId: string): Promise<Product[]> => {
    const { data } = await http.get(`/lnpl/v1/projects/${projectId}/products`);
    return data;
  };
}

export default getProductsCtor({ http });
