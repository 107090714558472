import axios, { AxiosInstance, AxiosStatic } from 'axios';
import { decider } from './auth/decider';

interface HttpContext {
  axios: AxiosStatic;
  decider: any; // TODO: type this later
  baseUrl: string;
}

// TODO: type this to return a Custom Http interface to be used, instead of axios
export function httpCtor({ axios, decider, baseUrl }: HttpContext) {
  return ({ name, path = '' }: { name: string; path }): AxiosInstance => {
    const http = axios.create({
      baseURL: baseUrl + path,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
      },
      timeout: 35000,
    });

    if (name !== 'public') {
      const authLib = decider(name);
      http.interceptors.request.use((config) => {
        const token = authLib.getToken();
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      });
    }

    http.interceptors.response.use((response) => response,
      (error) => Promise.reject(error.response));

    return http;
  };
}

const baseUrl = process.env.VUE_APP_BASE_URL_API!;
export const httpLib = axios;
export default httpCtor({ axios, decider, baseUrl });
