




























































import Vue from 'vue';
import { debounce } from 'lodash';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import { getOnboardingApplications, OnboardingApplication } from './dependencies/get_applications';

export default Vue.extend({
  data() {
    return {
      tab: null,
      state: 'loaded',
      states: [{
        text: 'Submitted',
        value: 'submitted',
      },
      {
        text: 'Approved',
        value: 'approved',
      },
      {
        text: 'Declined',
        value: 'declined',
      },
      {
        text: 'Resubmission Requested',
        value: 'resubmission_requested',
      },
      {
        text: 'Pending',
        value: 'pending',
      },
      {
        text: 'Abandoned',
        value: 'abandoned',
      }],
      selectedApplicationStates: [],
      searchTerm: '',
      onboardingApplications: [] as OnboardingApplication[],
      table: {
        itemsPerPage: 25,
        headers: [
          { text: 'Id', align: 'start', value: 'id' },
          { text: 'Project Id', value: 'projectId' },
          { text: 'Project Name', value: 'projectName' },
          { text: 'Status', value: 'status' },
          { text: 'Submitted At', value: 'submittedAt' },
          { text: 'Approved At', value: 'approvedAt' },
          { text: 'Declined At', value: 'declinedAt' },
          { text: 'Resubmission Requested At', value: 'resubmissionRequestedAt' },
        ],
      },
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        this.onboardingApplications = await getOnboardingApplications(this.selectedApplicationStates, this.searchTerm);
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

  },

  watch: {
    searchTerm: debounce(async function getData(this: any) {
      this.onboardingApplications = await getOnboardingApplications(this.selectedApplicationStates, this.searchTerm);
    }, 500),
  },

  components: {
    WbContentWrapper,
  },
});
