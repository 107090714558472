import http from '@admin/http';
import { AxiosInstance } from 'axios';

function downloadOrderSummaryPdfCtor({ http }: { http: AxiosInstance }) {
  return async (id: string): Promise<Buffer> => {
    const { data } = await http.get<Buffer>(`/lnpl/v1/orders/${id}/summary`, {
      responseType: 'arraybuffer',
    });
    return data;
  };
}

export const downloadOrderSummaryPdf = downloadOrderSummaryPdfCtor({ http });
