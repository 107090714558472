import http from '@admin/http';
import { AxiosInstance } from 'axios';

function submitApplicationsCtor(http: AxiosInstance) {
  return async (csvFile: File) => {
    const formData = new FormData();
    formData.append('csv', csvFile);
    const { data } = await http.post('/relay/submit-applications', formData);
    return data;
  };
}

export const submitApplications = submitApplicationsCtor(http);
