import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { KybStatus } from './types';

export function updateKybStatusCtor(http: AxiosInstance) {
  return async (id: string, status: KybStatus) => {
    await http.put(`/lnpl/v1/onboarding-applications/${id}/kyb-status`, { status });
  };
}

export const updateKybStatus = updateKybStatusCtor(http);
