import { RouteConfig } from 'vue-router';

import LoginPhone from './views/login_phone/index.vue';
import VerifyOtp from './views/verify_otp/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('./index.vue'),
    children: [
      {
        path: 'login-phone',
        name: 'StudentAuthLoginPhone',
        component: LoginPhone,
      },
      {
        path: 'verify-otp',
        name: 'StudentAuthVerifyOtp',
        component: VerifyOtp,
      },
    ],
  },
];

export default routes;
