import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Params {
  projectId: string;
  seats: number;
}

function setProjectSeatsCtor({ http }: { http: AxiosInstance }) {
  return async ({ projectId, seats }: Params): Promise<void> => {
    await http.put(`/lnpl/v1/projects/${projectId}/seats`, {
      seats,
    });
  };
}

export const setProjectSeats = setProjectSeatsCtor({ http });
