import { RouteConfig } from 'vue-router';

import SelectLoanOrder from './views/select_loan_order/index.vue';
import UpdateLoanOrder from './views/update_loan_order/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: 'update-payment',
    component: () => import('./index.vue'),
    children: [
      {
        path: '',
        component: SelectLoanOrder,
      },
      {
        path: ':loanOrderId',
        component: UpdateLoanOrder,
      },
    ],
  },
];

export default routes;
