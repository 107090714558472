import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface AccountBalance {
  currency: string;
  amountInCents: number;
}

export interface AccountBalanceResponse {
  accountBalance: AccountBalance[];
  canAccountBeCharged: boolean;
}

function getAccountBalanceCtor(http: AxiosInstance) {
  return async (projectId: string): Promise<AccountBalanceResponse> => {
    const { data } = await http.get(`/lnpl/v1/projects/${projectId}/account-balance`);
    return data;
  };
}

export const getAccountBalance = getAccountBalanceCtor(http);
