import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface Info {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCode: string;
  email: string;
  addressStreet: string;
  addressCity: string;
  addressRegion: string;
  addressCountry: string;
  postalOrZip: string;
}

export interface Kyc {
  id: string;
  status: string;
  source: string;
  riskScore: number;
  createdAt: Date;
  archivedAt: Date;
}

export interface CreditCheck {
  id: string;
  source: string;
  ficoScore: number;
  hitStrength: string;
  responseHard: string | null;
  responseSoft: string | null;
  requestedAt: Date;
  archivedAt: Date;
  status: string;
  incomeFormatted: string;
  debtToIncomeRatio: number;
}

export interface LoanOrder {
  id: string;
  currency: string;
  purchasePriceInCents: number;
  orderCompletedAt: Date;
  productName: string;
  projectId: string;
  projectName: string;
}

export interface InstallmentsMetricsChartData {
  label: string;
  data: {
    label: string;
    value: Date;
  }[];
}

export interface Application {
  id: string;
  checkoutSessionId: string;
  productId: string;
  productName: string;
  result: string;
  messages: string;
  createdAt: Date;
  expiredAt: Date;
  archivedAt: Date;
}

export type CheckoutSession = {
  staticId: string;
  createdAt: Date;
  studentId: string;
}

interface OrderResponse {
  info: Info;
  creditCheck: CreditCheck;
  kycForm: Kyc;
  loanOrders: LoanOrder[];
  loanOrderApplications: Application[];
  checkoutSessions: CheckoutSession[];
}

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(id: string) {
    const { data } = await http.get<OrderResponse>(`/lnpl/v1/students/${id}`);
    return data;
  };
}

export default getOrdersCtor({ http });
