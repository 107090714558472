<template>
  <section class="grid-details checkout-details">
    <template v-if="numberOfMonths > 1">
      <div>
        <wb-paragraph-loader
          :loading="state === 'loading'"
          width="220px"
        >
          <p>
            <b>{{ recurringAmountInCents | currencyInCents(2) }} every month for {{ numberOfMonths - 1 }} Months
            </b>
          </p>
        </wb-paragraph-loader>
      </div>

      <div>
        <wb-paragraph-loader
          :loading="state === 'loading'"
          width="220px"
        >
          <p>Due Today: <b>{{ currencyCode }} {{ dueTodayInCents | currencyInCents(2) }}</b></p>
        </wb-paragraph-loader>
      </div>

      <div v-if="origin !== 'ppm'">
        <wb-paragraph-loader
          :loading="state === 'loading'"
          width="320px"
        >
          <p>Interest (0.00% APR): <b>{{ currencyCode }} {{ 0 | currencyInCents(2) }}</b></p>
        </wb-paragraph-loader>
      </div>
    </template>

    <div>
      <wb-paragraph-loader
        :loading="state === 'loading'"
        width="180px"
      >
        <p>Total of Payments: <b>{{ currencyCode }} {{ totalPayments | currencyInCents(2) }}</b></p>
      </wb-paragraph-loader>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import Dinero from 'dinero.js';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';

export default Vue.extend({
  name: 'CheckoutDetails',

  components: {
    WbParagraphLoader,
  },

  props: {
    numberOfMonths: {
      type: Number,
    },

    currencyCode: {
      type: String,
    },

    totalPayments: {
      type: Number,
    },

    origin: {
      type: String,
    },

    dueTodayInCents: {
      type: Number,
    },

    recurringAmountInCents: {
      type: Number,
    },

    state: {
      type: String,
      default: 'loading',
    },
  },
});
</script>

<style lang="scss" scoped>
  .workflow-content .grid-details.checkout-details {
    margin-bottom: 1.5rem;
  }
</style>
