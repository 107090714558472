import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { FilterOptions } from '@/components/table/dependencies/types';

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async (id: string, form: {}): Promise<void> => {
    await http.put(`/lnpl/v1/credit-checks/${id}`, form);
  };
}

export default getOrdersCtor({ http });
