import http from '@admin/http';
import { AxiosInstance } from 'axios';

function ctor({ http }: { http: AxiosInstance }) {
  return async (orderId: string, isEnabled: boolean) => {
    const response = await http.post(
      `/lnpl/v1/orders/${orderId}/manage-charge-state`,
      { isEnabled },
      { validateStatus: () => true },
    );

    if (response.status !== 200) {
      throw response.data;
    }
  };
}

export const updateOrderCharge = ctor({ http });
