


















































































































































import Vue from 'vue';

import Dinero from 'dinero.js';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import { CheckoutPaymentRequestBodyVGS } from '../../dependencies/complete_checkout';
import getPrepaymentInfo, { PrepaymentInfo } from './dependencies/get_prepayment_info';

import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import CardBrand from './components/index.vue';
import { PaymentData } from '@/views/student/types';

import VGSField from './components/vgs.vue';

interface CustomerInfo extends PrepaymentInfo {
  amount: number;
}

export default Vue.extend({
  name: 'CreditCard',

  components: {
    Disclosure,
    WbTextFieldLoader,
    WbParagraphLoader,
    CardBrand,
    VGSField,
  },

  props: {
    businessName: {
      type: String,
    },
    projectData: {
      type: Object,
    },
    paymentState: {
      type: String,
    },
    origin: {
      type: String,
    },
    numberOfMonths: {
      type: Number,
    },
    ppmOnly: {
      type: Boolean,
    },
    dueToday: {
      type: Number,
    },
    termsOfServiceUrl: {
      type: String,
    },
  },

  data() {
    return {
      error: '',
      invalid: true,
      creditCardVendor: '',
      hasAgreedToConditions: false,
      formField: {} as VGSForm,
      formState: {} as State,
      isTokenizeLoading: false,
      errors: {
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      },
      formInputsState: 'loading',
      prepaymentInfo: {} as Partial<CustomerInfo>,
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.hasAgreedToConditions
        && this.formState.card_cvc.isValid
        && this.formState.card_exp.isValid
        && this.formState.card_holder.isValid
        && this.formState.card_number.isValid;
    },
  },

  async mounted() {
    const { projectId, sessionId } = this.$route.params;

    try {
      this.formInputsState = 'loading';

      this.prepaymentInfo = {
        ...(await getPrepaymentInfo({ projectId, sessionId })),
        amount: Dinero({ amount: this.dueToday }).toUnit(),
      };

      this.buildVgs();

      this.formInputsState = 'loaded';
    } catch (error) {
      console.error('error building VGS', error);
      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    }
  },

  methods: {
    downloadAgreementPlan(event: Event) {
      event.preventDefault();
      this.$emit('downloadAgreementPlan', this.$props.origin);
    },

    disclosureChange(agreed: boolean) {
      this.hasAgreedToConditions = agreed;
    },

    async completePurchase() {
      this.error = '';
      this.isTokenizeLoading = true;
      const { response, status } = await this.tokenize();
      this.isTokenizeLoading = false;
      if (status !== 200) {
        this.error = `Authentication Failed: We apologize, but we were unable to verify your payment
        authentication. Please ensure that the information provided is correct and try again. If the
        issue persists, please contact our customer support for further assistance.`;
        return;
      }

      await this.sendPayment({
        cardNumberToken: response.card_number,
        cardCvcToken: response.card_cvc,
        cardExp: response.card_exp.replaceAll(' ', ''),
        cardHolderName: response.card_holder,
        postalCode: this.prepaymentInfo.billingZip ?? null,
        countryCode: this.prepaymentInfo.billingCountry ?? null,
      });
    },

    async tokenize(): Promise<TokenizeResponse> {
      const response = await new Promise((resolve, reject) => {
        this.formField.tokenize((status, response) => {
          resolve({ status, response });
        },
        (error) => {
          reject(error instanceof Error ? error : new Error(JSON.stringify(error)));
        });
      });
      return response as TokenizeResponse;
    },

    async sendPayment(cardData: PaymentData) {
      const sessionId = String(this.$route.params.sessionId);
      const form: CheckoutPaymentRequestBodyVGS = {
        provider: 'vgs',
        sessionId,
        paymentMethod: 'card',
        paymentData: {
          card: {
            ...cardData,
            countryCode: this.prepaymentInfo.billingCountry ?? null,
            postalCode: this.prepaymentInfo.billingZip ?? null,
          },
        },
      };

      this.$emit('complete-checkout', form);
    },

    setCard(variant: string) {
      this.creditCardVendor = variant;
    },

    handleState(state: State) {
      if (state.card_number) {
        if (state.card_number.cardType) {
          this.setCard(state.card_number.cardType);
        }
        if (
          state.card_number.errors.length
        && state.card_number.isTouched
        && !state.card_number.isValid
        ) {
          this.errors.cardNumber = 'Card number is invalid';
        }
        if (state.card_number.isValid) {
          this.errors.cardNumber = '';
        }
      }

      if (state.card_cvc) {
        if (state.card_cvc.errors.length && state.card_cvc.isTouched && !state.card_cvc.isValid) {
          this.errors.cvv = 'Card cvv is invalid';
        }
        if (state.card_cvc.isValid) {
          this.errors.cvv = '';
        }
      }

      if (state.card_exp) {
        if (state.card_exp.errors.length && state.card_exp.isTouched && !state.card_exp.isValid) {
          this.errors.expiryDate = 'Card expiry is invalid';
        }

        if (state.card_exp.isValid) {
          this.errors.expiryDate = '';
        }
      }
    },

    buildVgs() {
      const vgsForm = window.VGSCollect.create(
        process.env.VUE_APP_VGS_TOKEN!,
        process.env.VUE_APP_VGS_ENV as 'sandbox' | 'live',
        (state) => {
          this.formState = state;
          this.handleState(state);
        },
      );

      vgsForm.field('#cc-holder', {
        type: 'text',
        name: 'card_holder',
        placeholder: 'Card holder',
        validations: ['required'],
        tokenization: false,
      });

      vgsForm.field('#cc-number', {
        type: 'card-number',
        name: 'card_number',
        errorColor: '#D8000C',
        placeholder: 'Card number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        autoComplete: 'cc-number',
        tokenization: {
          format: 'FPE_SIX_T_FOUR',
        },
      });

      vgsForm.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        errorColor: '#D8000C',
        placeholder: 'CVC',
        validations: ['required', 'validCardSecurityCode'],
        autoComplete: 'cc-csc',
        tokenization: {
          format: 'UUID',
        },
      });

      vgsForm.field('#cc-expiration-date', {
        type: 'card-expiration-date',
        name: 'card_exp',
        errorColor: '#D8000C',
        placeholder: 'MM / YY',
        validations: ['required', 'validCardExpirationDate'],
        autoComplete: 'cc-exp',
        tokenization: false,
      });

      this.formField = vgsForm;
    },
  },
});
