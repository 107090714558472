
























import Vue, { PropType } from 'vue';
import { ProjectInfo } from '../../index.vue';

export default Vue.extend({
  props: {
    projectData: {
      type: Object as PropType<ProjectInfo>,
    },
  },

  data() {
    return {
      countdown: 5,
    };
  },

  async mounted() {
    const intervalId = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown === 0) {
        clearInterval(intervalId);
        this.redirect();
      }
    }, 1000);
  },

  methods: {
    redirect() {
      const {
        redirectPage,
        firstName,
        lastName,
        email,
        productName,
        price,
      } = this.$route.query as {
        redirectPage: string;
        firstName: string;
        lastName: string;
        email: string;
        productName: string;
        price: string;
      };

      const redirectParams = new URLSearchParams({
        firstName,
        lastName,
        email,
        productName,
        price,
      }).toString();

      window.location.replace(`${redirectPage}?${redirectParams}`);
    },
  },
});
