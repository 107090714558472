



























































































import { format } from 'date-fns';
import Vue from 'vue';
import { Info } from '../types';

export default Vue.extend({
  props: {
    orderInfo: {
      type: Object,
      default: () => ({} as Partial<Info>),
    },
  },

  methods: {
    formatDate(dt: string) {
      return format(new Date(dt), 'MMM do yyyy');
    },
  },
});
