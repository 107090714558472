import Vue from 'vue';

interface Project {
  id: string;
  name: string;
}

export const store = Vue.observable({
  selectedProjects: [] as Project[],
  projects: [],
});

export const mutations = {
  addProject(project) {
    const index = store.selectedProjects.findIndex((p: Project) => p.id === project.id);
    if (index === -1) { store.selectedProjects.push(project); }
  },

  removeProject(id: string) {
    const index = store.selectedProjects.findIndex((p: Project) => p.id === id);
    store.selectedProjects.splice(index, 1);
  },

  clearProjects() {
    store.selectedProjects = [];
  },

  setProjects(projects) {
    store.projects = projects;
  },
};

export default {
  mutations,
  store,
};
