import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface GetDelinquentLoanOrdersParams {
  loanOrderId: string;
  delinquencyStates: string[];
}

export interface DelinquentLoanOrder {
  loanOrderId: string;
  productName: string;
  studentEmail: string;
  agency: string;
  recalledAt?: Date;
  archivedAt?: Date;
  blockedFromCollectionsAt?: Date;
  sentToAgencyAt?: Date;
}

export function getDelinquentLoanOrdersCtor(http: AxiosInstance) {
  return async ({
    loanOrderId,
    delinquencyStates,
  }: GetDelinquentLoanOrdersParams): Promise<DelinquentLoanOrder[]> => {
    const { data } = await http.get('/lnpl/v1/delinquent-loan-orders', { params: { loanOrderId, delinquencyStates } });
    return data;
  };
}

export const getDelinquentLoanOrders = getDelinquentLoanOrdersCtor(http);
