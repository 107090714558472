import http from '@admin/http';

interface Params {
  amountInCents: number;
  annualPercentageRate: number;
  startDate: Date;
  endDate: Date;
  frequency: 'daily' | 'monthly' | 'yearly';
  download: boolean;
}

interface AccruedInterestStatement {
  amountInCents: number;
  accruedInterestInCents: number;
  totalAccruedInterestInCents: number;
  date: Date;
}

interface Response {
  statements: AccruedInterestStatement[];
  frequencyInterestRate: number;
  totalPeriod: number;
  totalAccruedInterestInCents: number;
}

export function calculateAccruedInterestScheduleOverPeriod(params: Params & { download: true }): Promise<Blob>;
export function calculateAccruedInterestScheduleOverPeriod(params: Params & { download: false }): Promise<Response>;

export async function calculateAccruedInterestScheduleOverPeriod(params: Params): Promise<Blob | Response> {
  const { download, ...restParams } = params;
  const { data } = await http.post(`/utils/accrued_interest_statement/${download ? 'download' : ''}`, restParams);
  if (download) {
    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }
  return data as Response;
}
