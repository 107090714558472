import http from '@admin/http';
import { AxiosInstance } from 'axios';

function getProjects({ http }: { http: AxiosInstance }) {
  return async function getProjects(text) {
    const { data } = await http.get('/lnpl/v1/projects/search', { params: { text } });
    return data;
  };
}

export default getProjects({ http });
