










































import Vue from 'vue';
import Logo from '../../../../../../../components/logo/index.vue';

export default Vue.extend({
  name: 'UnlinkAccountDialog',

  components: {
    Logo,
  },

  props: {
    accountDialog: {
      type: Boolean,
    },

    savedAccount: {
      type: Object,
    },
  },

  methods: {
    unlinkAccount() {
      this.$emit('unlinkAccount');
    },

    closeDialog() {
      this.$emit('closeDialog');
    },

    onKeyDown({ key }: { key: string }) {
      if (key === 'Escape') {
        this.closeDialog();
      }
    },
  },
});
