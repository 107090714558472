import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { PaymentPlanGroup } from './types';

function getPaymentPlanGroupCtor(http: AxiosInstance) {
  return async (): Promise<PaymentPlanGroup[]> => {
    const { data } = await http.get('/lnpl/v1/payment-plan-groups');
    return data;
  };
}

export const getPaymentPlanGroup = getPaymentPlanGroupCtor(http);
