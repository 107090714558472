import http from '@admin/http';
import { AxiosInstance } from 'axios';

function setProjectPaymentPlanGroupCtor(http: AxiosInstance) {
  return async (projectId: string, paymentPlanGroupId: string): Promise<void> => {
    await http.post(`/lnpl/v1/projects/${projectId}/payment-plan-groups`, {
      paymentPlanGroupId,
    });
  };
}

export const setProjectPaymentPlanGroup = setProjectPaymentPlanGroupCtor(http);
