import { AxiosInstance } from 'axios';
import http from '@/views/student/http';

function createAccountCtor(http: AxiosInstance) {
  return async function qualifyStudent(projectId, sessionId) {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/qualify`);
    return data;
  };
}

export default createAccountCtor(http);
