import http from '@admin/http';
import { AxiosInstance } from 'axios';

function createPaymentPlanGroupCtor(http: AxiosInstance) {
  return async (configurationName: string) => {
    const { data } = await http.post('/lnpl/v1/payment-plan-groups', {
      name: configurationName,
    });
    return data;
  };
}

export const createPaymentPlanGroup = createPaymentPlanGroupCtor(http);
