import { AxiosInstance } from 'axios';
import http from '@/lib/http';

interface Address {
  city: string;
  zipCode: string;
  region: string | null;
  country: string;
  street: string;
  complement?: string;
}

interface Params {
  sessionId: string;
  address: Address;
}

export interface PaymentSchedule {
  amountInCents: number;
  dueDate: string;
  installmentNumber: number;
}

export interface PaymentPlan {
  id: string;
  installments: number;
  frequency: string;
  schedule: PaymentSchedule[];
  origin: 'lnpl' | 'ppm';
}
export interface Response {
  plans: PaymentPlan[];
  hasPaymentOptions: boolean;
  taxAmountInCents: number;
  totalInCents: number;
  error: boolean;
}

function calculateCheckoutTaxCtor(http: AxiosInstance) {
  return async ({ sessionId, address }: Params) => {
    const { data } = await http.post<Response>(`salestax/${sessionId}`, address);
    return data;
  };
}

export const calculateCheckoutTax = calculateCheckoutTaxCtor(http({ name: 'public', path: '/checkout' }));
