































































































































































































































import Vue from 'vue';
import Dinero from 'dinero.js';
import WbTextField from '@/components/text_field/index.vue';
import initCheckout, { Init } from './dependencies/init';
import completeCheckout, { CheckoutPaymentRequestBodyBS } from './dependencies/complete_checkout';
import WbTextFieldLoader from '@/components/skeleton_loaders/text_field.vue';
import WbParagraphLoader from '@/components/skeleton_loaders/paragraph.vue';
import Disclosure from '@/views/student/components/disclosure/index.vue';
import WbCountry from '@/components/country_dropdown/index.vue';
import CardBrand from './components/index.vue';

interface Country {
  id: string;
  name: string;
  callingCode: number;
}

export default Vue.extend({
  name: 'AutomaticPaymentSetup',

  components: {
    WbTextField,
    WbCountry,
    WbTextFieldLoader,
    WbParagraphLoader,
    Disclosure,
    CardBrand,
  },

  props: {
    projectData: {
      type: Object,
    },
  },

  data() {
    return {
      productId: '',
      product: {
        name: '',
        priceInCents: 0,
        currencyCode: '',
      },
      logoUrl: '',
      sessionId: '',
      termsOfServiceUrl: '',
      projectName: '',
      paymentPlans: [] as Init['paymentPlans'],
      state: 'loaded',
      paymentState: '',
      creditCardVendor: '',
      error: '',
      invalid: true,
      countries: [] as Country[],
      env: process.env.VUE_APP_BLUESNAP_ENV === 'production'
        ? 'https://ws.bluesnap.com'
        : 'https://sandbox.bluesnap.com',
      form: {
        card: {
          nameOnCard: '',
          token: '',
        },
        student: {
          firstName: '',
          lastName: '',
          email: '',
        },
        address: {
          country: {} as any,
          postalCode: '',
        },
        selectedPaymentPlanId: null,
        hasAgreedToConditions: false,
      },
      errors: {
        nameOnCard: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      },
      formInputsState: 'loading',
      contries: [
        { code: 'us', name: 'United States' },
        { code: 'ca', name: 'Canada' },
      ],
    };
  },

  computed: {
    isFormValid(this: any) {
      return this.form.hasAgreedToConditions
        && this.form.card.nameOnCard
        && this.form.card.token
        && this.form.student.firstName
        && this.form.student.lastName
        && this.form.student.email
        && this.form.address.country
        && this.form.address.postalCode
        && this.form.selectedPaymentPlanId;
    },
  },

  async mounted() {
    this.productId = this.$route.params.productId as string;

    if (!this.productId) {
      this.$router.replace({
        path: `/student/checkout-full/${this.productId}/404`,
      });
      return;
    }

    try {
      this.formInputsState = 'loading';
      const init = await initCheckout(this.productId);
      this.sessionId = init.sessionId;
      this.form.card.token = init.cardToken;
      this.product.name = init.product.name;
      this.product.priceInCents = init.product.priceInCents;
      this.product.currencyCode = init.product.currencyCode;
      this.projectName = init.product.projectName;
      this.termsOfServiceUrl = init.product.termsOfServiceUrl || '';

      this.countries = init.countries;

      this.form.address.country = this.countries.find((country) => country.id === 'US') || {};

      this.paymentPlans = init.paymentPlans;
      this.logoUrl = init.logoUrl;

      const obj = this.buildBluesnap(this.form.card.token);
      window.bluesnap.hostedPaymentFieldsCreate(obj);

      // On bluesnap build success, state is set to loaded.
      // if more than 10s goes by, we assume it failed.
      setTimeout(() => {
        if (this.formInputsState === 'loading') {
          this.$emit('setErrorMessage', `
            We had trouble loading the credit card form. Try and refresh your browser.
            If this persists, please reach out to us.
          `);
        }
      }, 10000);
    } catch (error) {
      this.handleInitError(error);
    }
  },

  methods: {
    handleInitError(error) {
      if (
        error?.status === 404
        || error?.data?.type === 'NoProductFound'
        || error?.data?.type === 'CheckoutNotAvailable'
      ) {
        this.$router.replace({ path: '404' });
        return;
      }

      this.$emit('setErrorMessage', `
        We had trouble loading the credit card form. Try and refresh your browser.
        If this persists, please reach out to us.
      `);
    },

    async submit() {
      this.error = '';

      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
        return;
      }

      window.bluesnap.hostedPaymentFieldsSubmitData(
        async (callback) => {
          if (callback.error != null) {
            this.error = `Authentication Failed: We apologize, but we were unable to verify your payment
            authentication. Please ensure that the information provided is correct and try again. If the
            issue persists, please contact our customer support for further assistance.`;
            return;
          }

          const { cardData } = callback as {
            cardData: {
              token: string;
              ccType: string;
              cardSubType: string;
              last4Digits: string;
              exp: string;
            };
          };

          const form: CheckoutPaymentRequestBodyBS = {
            student: {
              firstName: this.form.student.firstName,
              lastName: this.form.student.lastName,
              email: this.form.student.email,
            },
            provider: 'bluesnap',
            paymentData: {
              card: {
                cardToken: this.form.card.token,
                lastFourDigits: cardData.last4Digits,
                cardExp: cardData.exp,
              },
              billingAddress: {
                ...this.form.address,
                country: this.form.address.country.id,
              },
            },
            selectedPaymentPlanId: this.form.selectedPaymentPlanId,
          };

          await this.submitCheckout(form);
        }, this.getPlanData(),
      );
    },

    getPlanData() {
      const selectedPlan = this.paymentPlans.find((plan) => plan.id === this.form.selectedPaymentPlanId);

      if (!selectedPlan) {
        return {};
      }

      const obj = {
        amount: Dinero({ amount: selectedPlan.dueTodayInCents }).toUnit(),
        currency: this.product.currencyCode,
      };

      return obj;
    },

    onChangeName() {
      if (!this.form.card.nameOnCard) {
        this.errors.nameOnCard = 'Field Required';
      } else {
        this.errors.nameOnCard = '';
      }
    },

    async submitCheckout(requestBody: CheckoutPaymentRequestBodyBS) {
      if (this.paymentState === 'loading') {
        return;
      }

      if (!this.isFormValid) {
        this.error = 'Please fill out all required fields.';
        return;
      }

      try {
        this.paymentState = 'loading';
        const completionDetails = await completeCheckout({ requestBody, sessionId: this.sessionId });

        this.$router.replace({ query: { checkoutSessionId: this.sessionId } });

        if (completionDetails.redirectPage) {
          this.$router.push({
            path: 'completed-redirect',
            query: {
              redirectPage: completionDetails.redirectPage,
              firstName: completionDetails.studentFirstName,
              lastName: completionDetails.studentLastName,
              productName: completionDetails.productName,
              email: completionDetails.email,
              price: `${completionDetails.price}`,
            },
          });
        } else {
          this.$router.push({
            path: 'completed',
            query: { ...this.$route.query },
          });
        }
      } catch (error) {
        this.handleErrorCheckout(error);
      } finally {
        this.paymentState = 'loaded';
      }
    },

    handleErrorCheckout(error) {
      const defaultMessage = `Sorry, we could not complete your checkout at this time.
        Please try another payment method or please try again later. Thank you!`;

      if (!error?.type || error?.type === 'GenericError') {
        this.error = defaultMessage;
        return;
      }
      this.error = error?.message;
    },

    disclosureChange(agreed: boolean) {
      this.form.hasAgreedToConditions = agreed;
    },

    setErrorMessage(error: string) {
      this.$emit('setErrorMessage', error);
    },

    buildBluesnap(sessiontoken: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const componentRef = this;
      return {
        // insert your Hosted Payment Fields token
        token: sessiontoken,
        '3DS': true,
        onFieldEventHandler: {
          setupComplete: () => {
            this.formInputsState = 'loaded';
          },

          onFocus(tagId) {
            // Handle focus
            // changeImpactedElement(tagId, 'hosted-field-valid hosted-field-invalid', 'hosted-field-focus');
          },

          onBlur(tagId) {
            // Handle blur
            // changeImpactedElement(tagId, 'hosted-field-focus');
          },

          onError: (tagId: string, errorCode: string, errorDescription: string) => {
            // Temporary handler to hide 3D Secure error from not being enabled
            if (errorDescription.includes('3D Secure')) {
              return;
            }
            switch (tagId) {
            case 'ccn':
              this.errors.cardNumber = errorDescription;
              break;
            case 'exp':
              this.errors.expiryDate = errorDescription;
              break;
            case 'cvv':
              this.errors.cvv = errorDescription;
              break;
            default:
              break;
            }
          },

          onType(tagId, cardType, cardData) {
            componentRef.creditCardVendor = cardType.toLowerCase();
          },

          onValid: (tagId) => {
            switch (tagId) {
            case 'ccn':
              this.errors.cardNumber = '';
              break;
            case 'exp':
              this.errors.expiryDate = '';
              break;
            case 'cvv':
              this.errors.cvv = '';
              break;
            default:
              break;
            }
          },
        },
        // styling is optional
        style: {
          ':focus': {
            color: '#40496b',
          },
          input: {
            color: '#40496b',
            'font-size': '0.875rem',
          },
        },
        ccnPlaceHolder: '4111 2222 3333 4444',
        cvvPlaceHolder: '123',
        expPlaceHolder: 'MM / YY',
      };
    },
  },
});
