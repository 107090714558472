import http from '@admin/http';
import { AxiosInstance } from 'axios';

function updateOrderCtor({ http }: { http: AxiosInstance }) {
  return async (id: string, type: string) => {
    await http.post(`/lnpl/v1/orders/${id}/update`, { type });
  };
}

export const updateOrder = updateOrderCtor({ http });
