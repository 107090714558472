var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wb-content-wrapper',{attrs:{"state":_vm.state}},[_c('h1',{staticClass:"table-title"},[_vm._v(" Onboarding Applications ")]),_c('div',{staticClass:"mt-6"},[_c('v-text-field',{staticClass:"mb-2 search-bar",attrs:{"append-icon":"fas fa-search","label":"Search by Project Id or Project Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-select',{staticClass:"mb-2 search-bar",attrs:{"items":_vm.states,"menu-props":{ maxHeight: '400' },"label":"Filter by Application State","multiple":"","outlined":"","dense":""},on:{"blur":_vm.getData},model:{value:(_vm.selectedApplicationStates),callback:function ($$v) {_vm.selectedApplicationStates=$$v},expression:"selectedApplicationStates"}})],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items-per-page":_vm.table.itemsPerPage,"items":_vm.onboardingApplications},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("onboarding-applications/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.submittedAt",fn:function(ref){
var item = ref.item;
return [(item.submittedAt)?_c('b',[_vm._v(_vm._s(_vm._f("formatDate")(item.submittedAt,'MMM dd, yyyy')))]):_vm._e()]}},{key:"item.approvedAt",fn:function(ref){
var item = ref.item;
return [(item.approvedAt)?_c('b',[_vm._v(_vm._s(_vm._f("formatDate")(item.approvedAt,'MMM dd, yyyy')))]):_vm._e()]}},{key:"item.declinedAt",fn:function(ref){
var item = ref.item;
return [(item.declinedAt)?_c('b',[_vm._v(_vm._s(_vm._f("formatDate")(item.declinedAt,'MMM dd, yyyy')))]):_vm._e()]}},{key:"item.resubmissionRequestedAt",fn:function(ref){
var item = ref.item;
return [(item.resubmissionRequestedAt)?_c('b',[_vm._v(_vm._s(_vm._f("formatDate")(item.resubmissionRequestedAt,'MMM dd, yyyy')))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }