import http from '../../../../http';

function getSessionCtor({ http }) {
  return async function getSession(sessionId: string):
    Promise<{
      phoneNumber: number;
      phoneCountryCode: number;
      countryCode: string;
    }> {
    const { data } = await http.get('/auth/session', {
      params: {
        sessionId,
      },
    });
    return data;
  };
}

export default getSessionCtor({ http });
