import http from '@admin/http';
import { AxiosInstance } from 'axios';

function updateDemoStatusCtor({ http }: { http: AxiosInstance }) {
  return async (id: string, status: string): Promise<void> => {
    await http.put(`/lnpl/v1/projects/${id}/status`, {
      status,
    });
  };
}

export default updateDemoStatusCtor({ http });
