import http from '@admin/http';
import { AxiosInstance } from 'axios';

export function setDemoCompleteCtor(http: AxiosInstance) {
  return async (id: string) => {
    await http.put(`/lnpl/v1/onboarding-applications/${id}/demo/complete`);
  };
}

export const setDemoComplete = setDemoCompleteCtor(http);
