

















































































import Vue from 'vue';
import Dinero from 'dinero.js';
import WbContentWrapper from '@/components/content_wrapper/index.vue';

import getCompletedCheckout, { CompletedCheckoutSession } from './dependencies/get_completed_order';

export default Vue.extend({
  name: 'PurchaseCompleted',

  components: {
    WbContentWrapper,
  },

  props: {
    projectData: {
      type: Object,
    },
    origin: {
      type: String,
    },
    ppmOnly: {
      type: Boolean,
    },
  },

  data() {
    return {
      state: '',
      purchaseData: {} as CompletedCheckoutSession,
      projectId: '',
    };
  },

  computed: {
    installmentsValue(this: any) {
      if (this.purchaseData.firstPaymentInCents) {
        return this.purchaseData.installments - 1;
      }

      return this.purchaseData.installments;
    },
  },

  async mounted() {
    try {
      this.state = 'loading';
      const { checkoutSessionId } = this.$route.query as any;

      this.purchaseData = await getCompletedCheckout(checkoutSessionId);
      this.projectId = this.purchaseData.projectId;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.state = 'loaded';
    }
  },

  methods: {
    handleError(error) {
      if (error?.status === 404) {
        this.$router.replace({ path: '404' });
      }
      this.$emit('error', error);
    },
  },
});
