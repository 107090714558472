import http from '@admin/http';
import { AxiosInstance } from 'axios';

function deletePaymentPlanGroupCtor(http: AxiosInstance) {
  return async (configurationId: string) => {
    const { data } = await http.delete(`/lnpl/v1/payment-plan-groups/${configurationId}`);
    return data;
  };
}

export const deletePaymentPlanGroup = deletePaymentPlanGroupCtor(http);
