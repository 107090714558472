






































import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      default: '',
      type: String,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    min: {
      default: null,
      type: String,
    },

    max: {
      default: null,
      type: String,
    },

    placeholder: {
      default: '',
      type: String,
    },

    hint: {
      default: '',
      type: String,
    },

    prefix: {
      default: '',
      type: String,
    },

    suffix: {
      default: '',
      type: String,
    },

    maxlength: {
      default: '',
      type: String,
    },

    inputmode: {
      default: 'text',
      type: String,
    },

    type: {
      default: 'text',
      type: String,
    },

    persistentHint: {
      default: false,
      type: Boolean,
    },

    required: {
      default: false,
      type: Boolean,
    },

    dense: {
      default: true,
      type: Boolean,
    },

    actionIcon: {
      default: '',
      type: String,
    },

    actionIconOuter: {
      default: '',
      type: String,
    },

    errorMessages: {
      default: () => [],
      type: [Array, String],
    },

    value: {
      default: '',
      type: String,
    },

    clearable: {
      default: false,
      type: Boolean,
    },

    color: {
      default: 'primary',
      type: String,
    },

    rules: {
      default: () => [],
      type: Array,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    readonly: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      content: this.value,
    };
  },

  methods: {
    clickAction() {
      this.$emit('action-clicked', true);
    },

    handleInput(e: string) {
      this.$emit('input', e);
    },

    focus() {
      this.$emit('focus');
    },

    blur() {
      this.$emit('blur');
    },
  },
});
