

















































import Vue from 'vue';
import init from './dependencies/init';
import WbContentWrapper from '@/components/content_wrapper/index.vue';

  interface LoanOrder {
    id: string;
    productName: string;
    purchasedAt: Date;
    amountInCents: number;
    currency: string;
    installments: number;
    chargeType: string;
    paymentMethod: string;
    outstandingInstallments: string;
  }

export default Vue.extend({

  components: {
    WbContentWrapper,
  },
  data() {
    return {
      state: 'loading',
      loanOrders: [] as LoanOrder[],
    };
  },

  async mounted() {
    try {
      this.state = 'loading';
      const data = await init();
      this.state = 'loaded';
      this.loanOrders = data;
      if (this.loanOrders.length === 1) {
        this.$router.replace(`/student/app/update-payment/${this.loanOrders[0].id}`);
      }
    } catch (error) {
      this.state = 'error';
    }
  },
});
