import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface OnboardingApplication {
  id: string;
  projectId: string;
  projectName: string;
  status: string;
  submittedAt: Date;
  approvedAt: Date | null;
  declinedAt: Date | null;
}

export function getOnboardingApplicationsCtor(http: AxiosInstance) {
  return async (states: string[], searchText: string): Promise<OnboardingApplication[]> => {
    const { data } = await http.get<OnboardingApplication[]>('/lnpl/v1/onboarding-applications', {
      params: {
        states,
        search: searchText,
      },
    });
    return data;
  };
}

export const getOnboardingApplications = getOnboardingApplicationsCtor(http);
