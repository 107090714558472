import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { FilterOptions } from '@/components/table/dependencies/types';

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(params) {
    const { data } = await http.get('/lnpl/v1/projects', { params: { ...params } });
    return data;
  };
}

export default getOrdersCtor({ http });
