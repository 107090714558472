import { RouteConfig } from 'vue-router';

import NotAllowed from './views/403/index.vue';
import NotFound from './views/404/index.vue';
import ServerError from './views/500/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '/error',
    name: 'Error500',
    component: ServerError,
  },
  {
    path: '/not-allowed',
    name: 'Error403',
    component: NotAllowed,
  },
  {
    path: '*',
    name: 'Error404',
    component: NotFound,
  },
];

export default routes;
