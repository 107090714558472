import { AxiosInstance } from 'axios';
import http from '../../../../http';
import { PaymentData } from '@/views/student/types';

export interface CompleteCheckoutProps {
  requestBody: CheckoutPaymentRequestBody;
  sessionId: string;
}

export interface CheckoutPaymentRequestBody {
  provider: 'vgs';
  student: {
    firstName: string;
    lastName: string;
    email: string;
  };
  paymentData: {
    card?: PaymentData;
    billingAddress: {
      country: string;
      postalCode: string;
    };
  };
  selectedPaymentPlanId: string | null;
}

export interface CompletionDetails {
  price: number;
  studentFirstName: string;
  studentLastName: string;
  checkoutSessionId: string;
  email: string;
  productName: string;
  redirectPage: string | null;
}

function completeCheckoutCtor(http: AxiosInstance) {
  return async function completeCheckout({
    sessionId,
    requestBody,
  }: CompleteCheckoutProps): Promise<CompletionDetails> {
    const { data, status } = await http.post(
      `/checkout-full/${sessionId}/complete`,
      requestBody,
      { validateStatus: () => true },
    );

    if (status > 300) {
      throw data;
    }
    return data;
  };
}

export default completeCheckoutCtor(http);
