import http from '@admin/http';
import { AxiosInstance } from 'axios';

function getOrderCtor({ http }: { http: AxiosInstance }) {
  return async function getOrder(id: string) {
    const { data } = await http.get(`/lnpl/v1/orders/${id}`);
    return data;
  };
}

export const getOrder = getOrderCtor({ http });
