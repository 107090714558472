import { AxiosInstance } from 'axios';
import http from '@student/http';

interface Country {
  id: string;
  name: string;
  callingCode: number;
}

export interface Init {
  product: {
    name: string;
    projectName: string;
    priceInCents: number;
    currencyCode: string;
    projectId: string;
    singlePageCheckoutEnabled: boolean;
    termsOfServiceUrl: string | null;
  };
  paymentPlans: {
    id: string;
    installments: number;
    downPaymentPercentage: number;
    dueTodayInCents: number;
  }[];
  cardToken: string;
  sessionId: string;
  completed: boolean;
  logoUrl: string;
  countries: Country[];
}

function getCardSessionToken(http: AxiosInstance) {
  return async (productId: string): Promise<Init> => {
    const { data } = await http.get(`/checkout-full/${productId}/init`);
    return data;
  };
}

export default getCardSessionToken(http);
