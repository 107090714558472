const months = [
  { text: 'January', value: '01' },
  { text: 'February', value: '02' },
  { text: 'March', value: '03' },
  { text: 'April', value: '04' },
  { text: 'May', value: '05' },
  { text: 'June', value: '06' },
  { text: 'July', value: '07' },
  { text: 'August', value: '08' },
  { text: 'September', value: '09' },
  { text: 'October', value: '10' },
  { text: 'November', value: '11' },
  { text: 'December', value: '12' },
];

export const getDays = (month: number) => {
  const year = new Date().getFullYear();

  const lastDayOnDateObject = 0;

  const numDays = new Date(year, month, lastDayOnDateObject).getDate();

  const days: string[] = [];
  for (let i = 1; i <= numDays; i += 1) {
    days.push(i.toString().padStart(2, '0'));
  }

  return days;
};

const years = Array.from({ length: 120 }, (_, v) => (String(new Date().getFullYear() - v)));

export default {
  months,
  years,
};
