import http from '@admin/http';
import { AxiosInstance } from 'axios';

 type DownPaymentType = 'percentage' | 'cents';
type CheckoutStatus = 'active' | 'inactive';
type Currency = 'USD' | 'CAD';

 type Product = {
  id: string;
  name: string;
  amountInCents: number;
  currencyCode: Currency;
}

export type PaymentPlan = {
  id: string;
  installments: number;
  frequency: string;
};

export type Checkout = {
  staticIdentifier: string;
  projectId: string;
  paymentSettingsId: string;
  downPaymentType: DownPaymentType;
  downPaymentValue: number | null;
  currencyCode: Currency;
  totalInCents: number;
  products: Product[];
  status: CheckoutStatus;
  needsAttention: boolean;
  termsUrl: string;
  redirectUrl: string;
  lnplEnabled: boolean;
  ppmEnabled: boolean;
  paymentPlans: PaymentPlan[] | null;
  createdAt: string;
}

function getCheckoutCtor(http: AxiosInstance) {
  return async (projectId: string): Promise<Checkout[]> => {
    const { data } = await http.get(`/lnpl/v1/projects/${projectId}/checkouts`);
    return data;
  };
}

export const getCheckouts = getCheckoutCtor(http);
