import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface ProjectUsingGroup {
  name: string;
}

function getProjectsUsingGroupCtor(http: AxiosInstance) {
  return async (groupId: string): Promise<ProjectUsingGroup[]> => {
    const { data } = await http.get(`/lnpl/v1/payment-plan-groups/${groupId}/projects`);
    return data.projects;
  };
}

export const getProjectsUsingGroup = getProjectsUsingGroupCtor(http);
