













// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  inject: ['activeTab'],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isActive(): string {
      return this.activeTab() === this.name;
    },
  },
});
