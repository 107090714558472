<template>
  <section class="card custom-section">
    <h3 class="mb-3">
      Amortization Schedule
    </h3>
    <form @submit.prevent="fetchAmortizationSchedule(false)">
      <div>
        <wb-text-field
          v-model="amountInCents"
          label="Amount in Cents"
          type="number"
          required
        />
      </div>
      <div>
        <wb-text-field
          v-model="annualPercentageRate"
          label="Annual Percentage Rate (%)"
          type="number"
          step="0.01"
          required
        />
      </div>
      <div class="date-picker">
        <wb-date-picker-single
          class="my-3"
          :style="{width:'100%'}"
          :date="startDate"
          @dateUpdated="updateDate"
        />
      </div>
      <div>
        <wb-select
          v-model="frequency"
          label="Frequency"
          required
          :items="['daily', 'weekly', 'biweekly', 'monthly', 'annually']"
        />
      </div>
      <div>
        <wb-text-field
          v-model="term"
          label="Term (in months)"
          type="number"
          required
        />
      </div>
      <div>
        <wb-text-field
          v-model="downPaymentInCents"
          label="Down Payment in cents"
          type="number"
          required
        />
      </div>
      <v-btn
        class="my-3"
        type="submit"
        color="primary"
      >
        Amortize
      </v-btn>
    </form>
    <v-btn
      class="mb-3"
      color="primary"
      @click="fetchAmortizationSchedule(true)"
    >
      Download Schedule
    </v-btn>
    <div v-if="scheduleResult">
      <div>
        <h4>Schedule Result:</h4>
        <ul>
          <li
            v-for="row in scheduleResult"
            :key="row.date"
            class="mb-3"
          >
            Date: {{ formatDate(row.date) }}<br>
            Balance: {{ row.balanceInCents }}<br>
            Payment: {{ row.paymentAmountInCents }}<br>
            Principal: {{ row.principalInCents }}<br>
            Interest: {{ row.interestInCents }}<br>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import {
  format,
} from 'date-fns';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import WbDatePickerSingle from '@/components/date_picker_single/index.vue';
import downloadFile from '@/helpers/download_file';
import { store as dateRangeStore } from '@/store/date_range';
import { calculateAmortizationSchedule } from '../dependencies';

export default Vue.extend({
  name: 'AccruedInterestSchedule',
  components: {
    WbTextField,
    WbSelect,
    WbDatePickerSingle,
  },
  data() {
    return {
      amountInCents: 0,
      annualPercentageRate: 0,
      startDate: '',
      endDate: '',
      frequency: 'monthly',
      scheduleResult: null,
      term: 0,
      downPaymentInCents: 0,
    };
  },
  computed: {
    dateRangeStore() {
      return dateRangeStore;
    },
  },
  methods: {
    async fetchAmortizationSchedule(download) {
      try {
        const result = await calculateAmortizationSchedule({
          amountInCents: Number(this.amountInCents),
          apr: Number(this.annualPercentageRate),
          startDate: new Date(this.startDate),
          term: Number(this.term),
          paymentFrequency: this.frequency,
          downPaymentInCents: Number(this.downPaymentInCents),
          download,
        });
        if (download) {
          downloadFile({ file: result, fileName: 'schedule.csv' });
        } else {
          this.scheduleResult = result;
        }
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
    },
    updateDate(newDate) {
      this.startDate = newDate;
    },
    formatDate(date) {
      return format(new Date(date), 'MMM dd, yyyy');
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-section {
  max-width: fit-content;
}

.date-picker {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 1rem;
}

</style>
