






























import Vue from 'vue';

export default Vue.extend({
  name: 'NotFound',

  computed: {
    hasProjectId(this: any) {
      return this.$route.query.projectId;
    },
  },

  methods: {
    goToLandingPage() {
      this.$router.push({ path: `/student/checkout/${this.$route.query.projectId}/landing` });
    },
  },
});
