












import Vue from 'vue';

export default Vue.extend({
  name: 'Logo',
  props: {
    businessName: {
      type: String,
    },
  },
});
