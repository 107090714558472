











































import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      default: 'Select',
      type: String,
      required: true,
    },

    required: {
      default: false,
      type: Boolean,
    },

    items: {
      default: [],
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    multiple: {
      default: false,
      type: Boolean,
    },

    itemText: {
      default: 'text',
      type: String,
    },

    itemValue: {
      default: 'value',
      type: String,
    },

    backgroundColor: {
      default: '#F2F5FB',
      type: String,
    },

    value: {
      default: '',
    },

    dense: {
      default: true,
      type: Boolean,
    },

    outlined: {
      default: true,
      type: Boolean,
    },

    rules: {
      default: () => [],
      type: Array,
    },

    clearable: {
      default: false,
      type: Boolean,
    },

    placeholder: {
      default: '',
      type: String,
    },

    hint: {
      default: '',
      type: String,
    },

    persistentHint: {
      default: false,
      type: Boolean,
    },

    filled: {
      default: false,
      type: Boolean,
    },

    color: {
      default: 'primary',
      type: String,
    },

    errorMessages: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      content: this.value,
    };
  },

  methods: {
    clickAction() {
      this.$emit('action-clicked', true);
    },

    handleInput(e: string) {
      this.$emit('input', e);
    },
    handleChange(e) {
      this.$emit('change', e);
    },
  },
});
