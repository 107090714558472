import http from '@admin/http';
import { AxiosInstance } from 'axios';

function uploadLogo(http: AxiosInstance) {
  return async (projectId: string, logo: File) => {
    try {
      const formData = new FormData();
      formData.append('logo', logo);
      const { data } = await http.post(`/lnpl/v1/projects/${projectId}/logo`, formData);
      return data;
    } catch (err) {
      const error = err as any;
      if (error.data.type && error.data.message) {
        throw error.data;
      }
      throw error;
    }
  };
}

export default uploadLogo(http);
