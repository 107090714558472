import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import App from '@/app.vue';

import adminRoutes from '@/views/admin/routes';
import creatorRoutes from '@/views/creator/routes';
import studentRoutes from '@/views/student/routes';
import publicRoutes from '@/views/public/routes';
import errorRoutes from '@/views/error/routes';

import silenceNavErrors from './decoraters/silence_nav_errors';

import decoratorCreator from './decoraters/creator';
import decoratorStudent from './decoraters/student';
import decoratorAdmin from './decoraters/admin';
import decoratorPageTitle from './decoraters/page_title';
import decoratorErrors from './decoraters/errors';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'App',
    component: App,
  },
  ...adminRoutes,
  ...creatorRoutes,
  ...studentRoutes,
  ...adminRoutes,
  ...publicRoutes,
  ...errorRoutes,
];

silenceNavErrors(VueRouter);

const originalReplace: any = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

decoratorAdmin(router);
decoratorCreator(router);
decoratorStudent(router);
decoratorPageTitle(router);
decoratorErrors(router);

export default router;
