import { RouteConfig } from 'vue-router';
import Payment from './views/payment_god/index.vue';
import PurchaseCompleted from './views/purchase_completed/index.vue';
import PurchaseCompletedRedirect from './views/purchase_completed_redirect/index.vue';
import NotFound from './views/not_found/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: 'checkout-full/:productId',
    name: 'StudentCheckoutFull',
    component: () => import('./index.vue'),
    children: [
      {
        path: 'payment',
        component: Payment,
        meta: {
          title: 'Payment',
        },
      },
      {
        path: 'completed',
        component: PurchaseCompleted,
        meta: {
          title: 'Completed',
        },
      },
      {
        path: 'completed-redirect',
        component: PurchaseCompletedRedirect,
        meta: {
          title: 'Completed',
        },
      },
      {
        path: '404',
        component: NotFound,
        meta: {
          title: 'Not Found',
        },
      },
    ],
  },
];

export default routes;
