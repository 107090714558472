







































import Vue from 'vue';
import WbTextField from '@/components/text_field/index.vue';
import suggest, { Prediction } from './dependencies/suggest';
import complete, { CompleteResponse } from './dependencies/complete';

export default Vue.extend({
  components: {
    WbTextField,
  },

  props: {
    geofence: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuOpen: false,
      search: '',
      predictions: [] as Prediction[],
      referenceWidth: 0,
      sessionId: '',
    };
  },

  watch: {
    async search(newVal: string) {
      if (newVal) {
        const response = await suggest({
          input: newVal,
          geofence: this.geofence,
          sessionId: this.sessionId,
        });
        this.sessionId = response.sessionId;
        this.predictions = response.predictions;
      } else {
        this.predictions = [];
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.calculateReferenceWidth();
      window.addEventListener('resize', this.calculateReferenceWidth);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateReferenceWidth);
  },

  methods: {
    calculateReferenceWidth() {
      const { referenceElement } = this.$refs as any;
      if (referenceElement) {
        this.referenceWidth = referenceElement.offsetWidth;
      }
    },

    async handleClick(placeId: string) {
      this.menuOpen = false;
      this.search = '';
      this.predictions = [];

      const addressData = await complete(placeId, this.sessionId);

      this.sessionId = '';

      this.$emit('change', {
        country: addressData.country,
        countryCode: addressData.countryCode,
        region: addressData.region,
        regionCode: addressData.regionCode,
        city: addressData.city,
        street: `${addressData.streetNumber} ${addressData.streetName}`.trim(),
        complement: addressData.complement,
        postal: addressData.postal,
      });
    },
  },
});
