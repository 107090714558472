import http from '@admin/http';
import { AxiosInstance } from 'axios';
import {
  KycStatus, KybStatus, ApplicationStatus, DemoStatus,
} from './types';

export interface ProjectProfile {
  name: string;
  legalName: string;
  businessNumber: string;
  websiteUrl: string;
  productTypes: string[];
  addressStreet: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressPostalCode: string;
  kybStatus: KybStatus;
}

export interface ProjectOwnerProfile {
  firstName: string;
  lastName: string;
  ssnLast4: string;
  dob: string;
  phoneCountryCode: string;
  phoneNumber: string;
  title: string;
  email: string;
  veriffStatus: string;
  veriffResponse: any;
  kycStatus: KycStatus;
}

export interface ProjectPaymentDetails {
  accountNumber: string;
  accountHolderName: string;
  bankId: string | null;
  branchId: string;
  currencyCode: string;
  countryCode: string;
}

export interface ProjectDemo {
  status: DemoStatus;
  demoCompletedAt: string;
}

export interface OnboardingApplicationDetails {
  status: ApplicationStatus;
  projectProfile: ProjectProfile;
  projectOwnerProfile: ProjectOwnerProfile;
  projectPaymentDetails: ProjectPaymentDetails | null;
  projectDemo: ProjectDemo;
}

export function getOnboardingApplicationCtor(http: AxiosInstance) {
  return async (id: string) => {
    const { data } = await http.get<OnboardingApplicationDetails>(`/lnpl/v1/onboarding-applications/${id}`);
    return data;
  };
}

export const getOnboardingApplication = getOnboardingApplicationCtor(http);
