import http from '@admin/http';
import { AxiosInstance } from 'axios';

interface Params {
  amountInCents: number;
  interestRate: number;
  period: number;
}

interface Response {
  interestInCents: number;
}
function calculateAccruedInterestCtor({ http }: { http: AxiosInstance }) {
  return async (params: Params) => {
    const { data } = await http.post<Response>('/utils/accrued_interest', params);
    return data;
  };
}

export const calculateAccruedInterestOverPeriod = calculateAccruedInterestCtor({ http });
