



























































































































































































































































































































import Vue from 'vue';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import WbDialog from '@/components/dialog/index.vue';
import WbSelect from '@/components/select/index.vue';
import {
  getOnboardingApplication,
  OnboardingApplicationDetails,
  KybStatus,
  KycStatus,
  ApplicationStatus,
  updateKybStatus,
  updateKycStatus,
  setDemoComplete,
  updateApplicationStatus,
} from './dependencies';

export default Vue.extend({

  components: {
    WbContentWrapper,
    WbDialog,
    WbSelect,
  },
  data() {
    return {
      state: '',
      formState: '',
      application: {
        projectProfile: {},
        projectOwnerProfile: {},
        projectPaymentDetails: {},
        projectDemo: {},
      } as OnboardingApplicationDetails,
      veriffResponseDialog: false,

      kybStatusOptions: [
        { value: 'approved', text: 'Approved' },
        { value: 'declined', text: 'Declined' },
        { value: 'resubmission_requested', text: 'Resubmission Requested' },
        { value: 'pending', text: 'Pending' },
      ] as { value: KybStatus; text: string }[],
      kybStatus: KybStatus.pending,
      updateKybStatusDialogOpen: false,

      kycStatusOptions: [
        { value: 'approved', text: 'Approved' },
        { value: 'declined', text: 'Declined' },
        { value: 'resubmission_requested', text: 'Resubmission Requested' },
        { value: 'pending', text: 'Pending' },
      ] as { value: KycStatus; text: string }[],
      kycStatus: KycStatus.pending,
      updateKycStatusDialogOpen: false,

      appStatusOptions: [
        { value: 'abandoned', text: 'Abandoned' },
        { value: 'approved', text: 'Approved' },
        { value: 'declined', text: 'Declined' },
        { value: 'resubmission_requested', text: 'Resubmission Requested' },
        { value: 'submitted', text: 'Submitted' },
      ] as { value: ApplicationStatus; text: string }[],
      appStatus: ApplicationStatus.submitted,
      updateAppStatusDialogOpen: false,
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        const data = await getOnboardingApplication(this.$route.params.id);
        this.application = data;
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    formatAddress() {
      const { projectProfile } = this.application;

      return `${projectProfile.addressComplement}
              ${projectProfile.addressStreet}
              ${projectProfile.addressCity},
              ${projectProfile.addressState},
              ${projectProfile.addressCountry}
              ${projectProfile.addressPostalCode}
            `;
    },

    formatPhoneNumber() {
      const { projectOwnerProfile } = this.application;

      return `+${projectOwnerProfile.phoneCountryCode}-${projectOwnerProfile.phoneNumber}`;
    },

    showUpdateKybStatusDialog() {
      this.kybStatus = this.application.projectProfile.kybStatus;
      this.updateKybStatusDialogOpen = true;
    },

    async updateKybStatus() {
      try {
        this.formState = 'loading';
        await updateKybStatus(this.$route.params.id, this.kybStatus);
        this.getData();
        this.formState = 'loaded';
        this.updateKybStatusDialogOpen = false;
      } catch (error) {
        this.formState = 'error';
      }
    },

    showUpdateKycStatusDialog() {
      this.kycStatus = this.application.projectOwnerProfile.kycStatus;
      this.updateKycStatusDialogOpen = true;
    },

    async updateKycStatus() {
      try {
        this.formState = 'loading';
        await updateKycStatus(this.$route.params.id, this.kycStatus);
        this.getData();
        this.formState = 'loaded';
        this.updateKycStatusDialogOpen = false;
      } catch (error) {
        this.formState = 'error';
      }
    },

    async completeDemo() {
      try {
        this.formState = 'loading';
        await setDemoComplete(this.$route.params.id);
        this.getData();
        this.formState = 'loaded';
      } catch (error) {
        this.formState = 'error';
      }
    },

    showUpdateAppStatusDialog() {
      this.appStatus = this.application.status;
      this.updateAppStatusDialogOpen = true;
    },

    async updateAppStatus() {
      try {
        this.formState = 'loading';
        await updateApplicationStatus(this.$route.params.id, this.appStatus);
        this.getData();
        this.formState = 'loaded';
        this.updateAppStatusDialogOpen = false;
      } catch (error) {
        this.formState = 'error';
      }
    },
  },
});
