





























































import Vue from 'vue';
import { debounce } from 'lodash';
import WbContentWrapper from '@/components/content_wrapper/index.vue';
import { getDelinquentLoanOrders, DelinquentLoanOrder } from './dependencies/get_delinquent_loan_orders';
import { getDelinquentReadableState } from '@/helpers/delinquency';

export default Vue.extend({
  data() {
    return {
      tab: null,
      state: 'loaded',
      states: [{
        text: 'Sent to Agency',
        value: 'sent',
      },
      {
        text: 'Ready to be Sent',
        value: 'ready',
      },
      {
        text: 'Archived',
        value: 'archived',
      },
      {
        text: 'Recalled',
        value: 'recalled',
      }],
      selectedDelinquentStates: [],
      loanOrderIdFiltered: '',
      delinquentLoanOrders: [] as DelinquentLoanOrder[],
      table: {
        itemsPerPage: 25,
        headers: [
          { text: 'State', align: 'start', value: 'state' },
          { text: 'Product Name', value: 'productName' },
          { text: 'Student Email', value: 'studentEmail' },
          { text: 'Agency', value: 'agency' },
          { text: 'Sent', value: 'sentToAgencyAt' },
          { text: 'Recalled', value: 'recalledAt' },
          { text: 'Blocked', value: 'blockedAt' },
          { text: '', align: 'start', value: 'loanOrderId' },
        ],
      },
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.state = 'loading';
        this.delinquentLoanOrders = await getDelinquentLoanOrders({
          loanOrderId: this.loanOrderIdFiltered,
          delinquencyStates: this.selectedDelinquentStates,
        });
        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    getReadableState(state: DelinquentLoanOrder) {
      return getDelinquentReadableState(state);
    },
  },

  watch: {
    loanOrderIdFiltered: debounce(async function getData(this: any) {
      await this.getData();
    }, 500),
  },

  components: {
    WbContentWrapper,
  },
});
