import {
  currency, currencyInCents, formatDate, percentage,
} from '@/filters';

export default {
  install(Vue) {
    Vue.filter('percentage', percentage);
    Vue.filter('currency', currency);
    Vue.filter('currencyInCents', currencyInCents);
    Vue.filter('formatDate', formatDate);
  },
};
