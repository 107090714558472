import http from '@admin/http';
import { AxiosInstance } from 'axios';

function downloadInvoiceCtor({ http }: { http: AxiosInstance }) {
  return async (invoiceId: string): Promise<void> => {
    const { data: pdf } = await http.get(`/lnpl/v1/subscriptions/download/invoice/${invoiceId}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };
}

export default downloadInvoiceCtor({ http });
