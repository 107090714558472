
































































import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

import auth from '@/lib/auth/auth.student';

import WbTextField from '@/components/text_field/index.vue';
import { countryPhoneFormat } from '@/components/country_dropdown/helpers';

import sendOtp from '../dependencies/send_otp';
import verifyOtp from './dependencies/verify_otp';
import getSession from './dependencies/get_session';

export default Vue.extend({

  name: 'StudentAuthLoginPhone',

  components: {
    WbTextField,
  },
  data() {
    return {
      state: '',
      error: '',
      projectId: '',
      form: {
        otp: '',
      },
      sessionId: '',
      phoneNumber: '',
      phoneCountryCode: 1,
      countryCode: 'US',
      resendPinBtnDisabled: false,
    };
  },

  validations: {
    form: {
      otp: { required },
    },
  },

  computed: {
    otpErrors(this: any) {
      const errors: string[] = [];
      if (!this.$v?.form?.otp?.$dirty) return errors;
      if (!this.$v?.form?.otp?.required) errors.push('OTP is required');

      return errors;
    },

    getFormattedPhone() {
      const phone = (this as any).phoneNumber;
      const formattedPhone = (this as any).programmaticMask(phone);
      return `+${(this as any).phoneCountryCode} ${formattedPhone}`;
    },
  },

  async mounted(this: any) {
    await this.getSession();
  },

  methods: {
    programmaticMask(phone: string) {
      const mask = countryPhoneFormat[(this as any).countryCode.toUpperCase()];

      if (!mask) {
        return phone;
      }

      let maskedValue = '';
      let valueIndex = 0;

      for (let i = 0; i < mask.length; i += 1) {
        if (mask[i] === '#') {
          if (valueIndex < phone.length) {
            maskedValue += phone[valueIndex];
            valueIndex += 1;
          } else {
            break;
          }
        } else {
          maskedValue += mask[i];
        }
      }

      return maskedValue;
    },

    async getSession(this: any) {
      const { sessionId, projectId } = this.$route.query;
      this.sessionId = sessionId as string;
      this.projectId = projectId as string;

      try {
        const { phoneNumber, phoneCountryCode, countryCode } = await getSession(this.sessionId);

        this.phoneNumber = phoneNumber.toString();
        this.phoneCountryCode = phoneCountryCode;
        this.countryCode = countryCode;
      } catch (error) {
        this.$router.replace({ path: '/student/login-phone', query: this.$route.query });
      }
    },

    async submit(this: any) {
      this.error = '';
      this.$v.form.$touch();

      try {
        const sixDigitRegex = /^\d{6}$/;

        if (!sixDigitRegex.test(this.form.otp)) {
          this.error = 'Verification code should be a 6 digit number.';
          return;
        }

        this.state = 'loading-verify-otp';

        const { token } = await verifyOtp(this.sessionId, this.form.otp, this.projectId);
        auth.signIn({ newToken: token, newRefreshToken: token });

        this.state = 'loaded';

        const path = this.$route.query.next || '/student/404';
        this.$router.replace({ path, query: this.$route.query });
      } catch (error) {
        this.state = 'error';
        this.error = (error as any)?.data?.message || "We've experienced an error";
      }
    },

    goToLoginPhone() {
      this.$router.replace({ path: '/student/login-phone', query: this.$route.query });
    },

    async resendPin(this: any) {
      try {
        this.state = 'loading-resend-code';
        const sessionId = await sendOtp(this.phoneNumber, this.phoneCountryCode, this.countryCode);

        this.$router.replace({ path: '/student/verify-otp', query: { ...this.$route.query, sessionId } });

        await this.getSession();

        this.state = 'loaded';

        this.resendPinBtnDisabled = true;

        // enable resend pin button after 5 secs
        setTimeout(() => {
          this.resendPinBtnDisabled = false;
        }, 5000);
      } catch (error) {
        this.state = 'error';
        this.error = (error as any)?.data?.message || "We've experienced an error";
      }
    },
  },
});
