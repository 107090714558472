

























import Vue from 'vue';

export default Vue.extend({
  props: {
    noBorder: {
      default: false,
      type: Boolean,
    },

    elevation: {
      default: '2',
      type: String,
    },

    width: {
      default: undefined,
      type: String,
    },

    fullWidth: {
      default: undefined,
      type: Boolean,
    },
  },

  computed: {
    outlined() {
      return !this.noBorder;
    },

    cardWidth() {
      return this.width || (this.fullWidth ? '100%' : undefined);
    },
  },
});
