import http from '@admin/http';
import { AxiosInstance } from 'axios';
import { SubscriptionInterval, SubscriptionStatus } from '../../../types';

export interface Subscription {
  createdAt: string;
  updatedAt: string;
  status: SubscriptionStatus;
  projectId: string;
  projectName: string;
  planId: string;
  planName: string;
  planPrice: number;
  planInterval: SubscriptionInterval;
  planOveragePercentage: number;
  planTransactionLimit: number;
}

function getOrdersCtor({ http }: { http: AxiosInstance }) {
  return async function getOrders(): Promise<Subscription[]> {
    const { data } = await http.get('/lnpl/v1/subscriptions');
    return data;
  };
}

export default getOrdersCtor({ http });
