import { RouteConfig } from 'vue-router';

import AuthRoutes from './auth/routes';

export const routes: Array<RouteConfig> = [
  {
    path: '/creator',
    component: () => import('./index.vue'),
    children: [
      ...AuthRoutes,
    ],
  },
];

export default routes;
