<template>
  <section class="payment-schedule">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="accordion-header">
          <label class="payments-text"><b>{{ installmentsList.length }} total payments</b></label>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol
            v-if="installmentsList.length <= 4"
            class="payment-list"
          >
            <payment-schedule-row
              v-for="(installment, ind) of installmentsList"
              :key="installment.paymentNumber"
              :payment-index="ind"
              :installment="installment"
              :installments-list-length="installmentsList.length"
            />
          </ol>
          <ol
            v-else
            class="payment-list"
          >
            <payment-schedule-row
              :payment-index="0"
              :installment="installmentsList[0]"
              :installments-list-length="installmentsList.length"
            />
            <payment-schedule-row
              :payment-index="1"
              :installment="installmentsList[1]"
              :installments-list-length="installmentsList.length"
            />
            <label class="more-payments-info">
              <span>+{{ installmentsList.length - 4 }} more payments</span>
            </label>
            <payment-schedule-row
              :payment-index="installmentsList.length - 2"
              :installment="installmentsList[installmentsList.length - 2]"
              :installments-list-length="installmentsList.length"
            />
            <payment-schedule-row
              :payment-index="installmentsList.length - 1"
              :installment="installmentsList[installmentsList.length - 1]"
              :installments-list-length="installmentsList.length"
            />
          </ol>
        </v-expansion-panel-content>
        <label class="due-today-text">
          <p class="due-today-text">{{ installmentsList[0].amountInCents | currencyInCents(2) }} is due today</p>
        </label>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import Vue from 'vue';
import PaymentScheduleRow from './components/payment_schedule_row/index.vue';

export default Vue.extend({
  name: 'PaymentSchedule',
  components: {
    PaymentScheduleRow,
  },
  props: {
    installmentsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      panel: 0,
    };
  },
});
</script>

<style lang="scss" scoped>
.payment-schedule {
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  .due-today-text {
    color: #9CA3AF;
    margin-bottom: 0;
    margin-top: -0.6rem;
  }
}

.accordion-header {
  padding: 0;

  .payments-text {
    margin-bottom: 0;
    cursor: pointer;
  }
}

.payment-list {
  padding: 0;

  .more-payments-info {
    margin-bottom: 1rem;
    color: #9CA3AF;
  }
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel--active .due-today-text {
  display: none;
}
</style>

<style lang="scss">
  .v-icon.v-icon {
    font-size: 1rem;
  }

  .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
