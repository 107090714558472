


































































































































import Vue from 'vue';
import { getCheckouts, Checkout } from '../dependencies/get_checkouts';
import { currencyInCents, formatDate, percentage } from '@/filters';

export default Vue.extend({
  data() {
    return {
      state: '',
      copying: false,
      previewedCheckout: null as Checkout | null,
      projectId: '',
      checkouts: [] as Checkout[],
      table: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Static Id', align: 'start', value: 'staticIdentifier', sortable: false,
          },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Amount', align: 'start', value: 'totalInCents' },
          { text: 'Currency', value: 'currencyCode' },
          { status: 'Status', value: 'status' },
          { text: 'Created', value: 'createdAt' },
          { text: 'Archived', value: 'archivedAt' },
        ],
      },
      productTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Id', align: 'start', value: 'id', sortable: false,
          },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Price', align: 'start', value: 'amountInCents' },
          { text: 'Currency', value: 'currencyCode' },
        ],
      },
      plansTable: {
        itemsPerPage: 25,
        headers: [
          {
            text: 'Id', align: 'start', value: 'id', sortable: false,
          },
          { text: 'Installments', align: 'start', value: 'installments' },
          { text: 'Frequency', align: 'start', value: 'frequency' },
        ],
      },
    };
  },
  computed: {
    downPaymentInCents() {
      if (!this.previewedCheckout || !this.previewedCheckout?.downPaymentValue
        || this.previewedCheckout?.downPaymentType !== 'cents'
      ) {
        return 0;
      }

      // @ts-ignore
      return this.previewedCheckout.downPaymentValue / this.previewedCheckout.totalInCents;
    },

    checkoutLink() {
      if (!this.previewedCheckout || !process.env.VUE_APP_BASE_URL) {
        return '';
      }

      const url = new URL(process.env.VUE_APP_BASE_URL);

      const pathSegments = [
        'student',
        'checkout',
        this.projectId,
      ];

      url.pathname = pathSegments.join('/');
      url.searchParams.set('productId', this.previewedCheckout.staticIdentifier);

      return url.toString();
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.state = 'loading';
        this.projectId = this.$route.params.id;
        const checkouts = await getCheckouts(this.projectId);

        this.checkouts = checkouts;

        this.state = 'loaded';
      } catch (error) {
        this.state = 'error';
      }
    },

    preview(staticIdentifier: string) {
      this.previewedCheckout = this.checkouts.find((c) => c.staticIdentifier === staticIdentifier) || null;
    },

    formatDownPaymentType(type: Checkout['downPaymentType']) {
      switch (type) {
      case 'cents':
        return 'Cents';
      case 'percentage':
        return 'Percentage';
      default:
        return 'None';
      }
    },

    copyCheckoutLink() {
      setTimeout(() => {
        this.copying = true;

        setTimeout(() => {
          this.copying = false;
        }, 2000);
      }, 200);

      navigator.clipboard.writeText(this.checkoutLink);
    },
  },
});
