export default function decorate(router) {
  const originalPush: any = router.prototype.push;
  router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
      if (router.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  const originalReplace: any = router.prototype.replace;
  router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => {
      if (router.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };
}
