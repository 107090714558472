var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h3',[_vm._v("Checkouts")]),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.checkouts,"items-per-page":_vm.table.itemsPerPage},scopedSlots:_vm._u([{key:"item.staticIdentifier",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.preview(item.staticIdentifier)}}},[_vm._v(" "+_vm._s(item.staticIdentifier)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.archivedAt ? 'Archived' : item.status)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.archivedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.archivedAt))+" ")]}},{key:"item.totalInCents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.totalInCents))+" ")]}}])}),(!_vm.previewedCheckout)?_c('div',[_c('p',[_vm._v("Select a checkout to preview")])]):_c('div',{staticClass:"preview"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("Checkout Preview")]),_c('a',{on:{"click":function($event){_vm.previewedCheckout = null}}},[_vm._v("Close")])]),_c('div',{staticClass:"url"},[_c('div',{staticClass:"scroll-contain"},[_c('a',{attrs:{"href":_vm.checkoutLink}},[_vm._v(_vm._s(_vm.checkoutLink))])]),_c('v-btn',{attrs:{"label":"","fab":"","x-small":"","depressed":""},on:{"click":_vm.copyCheckoutLink}},[(_vm.copying)?_c('v-icon',[_vm._v(" fas fa-check ")]):_c('v-icon',[_vm._v(" fas fa-copy ")])],1)],1),_c('div',{staticClass:"card"},[_c('h4',[_vm._v("Oveview")]),_c('div',{staticClass:"list"},[_c('p',[_vm._v("Checkout Amount: "),_c('strong',[_vm._v(_vm._s(_vm._f("currencyInCents")(_vm.previewedCheckout.totalInCents)))])]),_c('p',[_vm._v("Static Id: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.staticIdentifier))])]),_c('p',[_vm._v("Needs attention: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.needsAttention))])]),_c('p',[_vm._v("Terms url: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.termsUrl || 'Not Set'))])]),_c('p',[_vm._v("Redirect url: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.redirectUrl || 'Not Set'))])])])]),_c('div',{staticClass:"card"},[_c('h4',[_vm._v("Products")]),_c('v-data-table',{attrs:{"headers":_vm.productTable.headers,"items":_vm.previewedCheckout.products,"items-per-page":_vm.productTable.itemsPerPage},scopedSlots:_vm._u([{key:"item.amountInCents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyInCents")(item.amountInCents))+" ")]}}])})],1),_c('div',{staticClass:"card"},[_c('h4',[_vm._v("Down payment (aka First Payment)")]),(_vm.previewedCheckout.downPaymentType)?_c('div',{staticClass:"list"},[_c('p',[_vm._v("Type: "),_c('strong',[_vm._v(_vm._s(_vm.formatDownPaymentType(_vm.previewedCheckout.downPaymentType)))])]),_c('p',[_vm._v("Amount: "),_c('strong',[_vm._v(_vm._s(_vm._f("currencyInCents")(_vm.previewedCheckout.downPaymentValue)))])]),(_vm.previewedCheckout.downPaymentType === 'cents')?_c('p',[_vm._v(" Percentage: "),_c('strong',[_vm._v(_vm._s(_vm._f("percentage")(_vm.downPaymentInCents)))])]):_vm._e()]):_c('p',[_vm._v(" No down payment Set ")])]),_c('div',{staticClass:"card"},[_c('h4',[_vm._v("Payment Settings")]),_c('div',{staticClass:"list"},[_c('p',[_vm._v("LNPL Enabled: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.lnplEnabled))])]),_c('p',[_vm._v("PPM Enabled: "),_c('strong',[_vm._v(_vm._s(_vm.previewedCheckout.ppmEnabled))])])]),(_vm.previewedCheckout.paymentPlans)?_c('div',[_c('h5',[_vm._v("PPM payment plans")]),(_vm.previewedCheckout.paymentPlans)?_c('v-data-table',{attrs:{"headers":_vm.plansTable.headers,"items":_vm.previewedCheckout.paymentPlans,"items-per-page":_vm.plansTable.itemsPerPage}}):_vm._e()],1):_c('p',[_vm._v(" No plans (ppm not enabled) ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }