import { RouteConfig } from 'vue-router';

import Subscriptions from './views/subscriptions/index.vue';
import Subscription from './views/subscription/index.vue';
import SubscriptionInvoice from './views/subscription_invoice/index.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('./index.vue'),
    children: [
      {
        path: 'ppm/subscriptions',
        component: Subscriptions,
        meta: {
          title: 'Subscriptions',
        },
      },
      {
        path: 'ppm/subscriptions/invoice/:id',
        component: SubscriptionInvoice,
        meta: {
          title: 'Subscription Invoice',
        },
      },
      {
        path: 'ppm/subscriptions/:id',
        component: Subscription,
        meta: {
          title: 'Subscription',
        },
      },
    ],
  },
];

export default routes;
