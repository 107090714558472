import Vue from 'vue';
import moment from 'moment';

export const store = Vue.observable({
  dateRange: {
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'd').format('YYYY-MM-DD'),
    grouping: 'week',
  },

  history: {},
});

export function viewOutOfSync(view: string) {
  // @ts-ignore
  const history = store.history[view];
  if (!history) return true;

  const staleDateRange = (history.endDate !== store.dateRange.endDate)
    || (history.startDate !== store.dateRange.startDate)
    || (history.grouping) !== store.dateRange.grouping;

  return staleDateRange;
}

export const mutations = {
  // @ts-ignore
  setDateRange({ startDate, endDate }) {
    store.dateRange = {
      startDate,
      endDate,
      grouping: store.dateRange.grouping,
    };
  },

  setDateRangeGrouping(grouping: string) {
    store.dateRange = {
      startDate: store.dateRange.startDate,
      endDate: store.dateRange.endDate,
      grouping,
    };
  },

  // @ts-ignore
  updateHistory(dateRange, view) {
    // @ts-ignore
    store.history[view] = dateRange;
  },
};

export default {
  mutations,
  store,
  viewOutOfSync,
};
