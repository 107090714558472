import admin from './auth.admin';
import creator from './auth.creator';
import student from './auth.student';

export function decider(name) {
  if (name === 'admin') {
    return admin;
  } if (name === 'creator') {
    return creator;
  } if (name === 'student') {
    return student;
  }

  throw Error('Invalid auth');
}

export default decider;
