import http from '@admin/http';
import { AxiosInstance } from 'axios';

export interface DelinquentAccount {
  id: string;
  loanOrderId: string;
  agency: string;
  amountToBePaid: number;
  amountPaid: number;
  registeredAt: Date;
  recalledAt?: Date;
  archivedAt?: Date;
  blockedFromCollectionsAt?: Date;
  sentToAgencyAt?: Date;
}

export function getDelinquentAccountCtor(http: AxiosInstance) {
  return async (id: string): Promise<DelinquentAccount> => {
    const { data } = await http.get(`/lnpl/v1/delinquent-loan-orders/${id}`);
    return data;
  };
}

export const getDelinquentAccount = getDelinquentAccountCtor(http);
