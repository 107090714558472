import http from '@student/http';

function initCtor({ http }) {
  return async function init() {
    const { data } = await http.get('/update-payment');
    return data;
  };
}

export default initCtor({ http });
