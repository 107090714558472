



























// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      activeTab: '',
      tabs: [],
    };
  },
  provide() {
    return {
      activeTab: () => this.activeTab,
    };
  },

  mounted() {
    this.$slots.default.forEach((tab) => {
      const name = this.getName(tab);
      this.tabs.push(name);

      if (!this.activeTab) {
        this.activeTab = name;
      }
    });
  },
  methods: {
    getName(tab: any) {
      // capitalize the first letter of the tab name
      return tab.componentOptions.propsData.name;
    },
  },
});
