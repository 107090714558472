





















import Vue from 'vue';
import AuthLayout from '@/layouts/auth/index.vue';
import WbSplitCard from '@/layouts/auth/components/split_card/index.vue';

export default Vue.extend({
  name: 'ViewAuth',

  components: {
    AuthLayout,
    WbSplitCard,
  },
});
