











import Vue from 'vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      default: 'disclosure-box',
    },
  },
  methods: {
    onCheckboxChange(value: boolean) {
      this.$emit('change', value);
    },
  },
});
