import { AxiosInstance } from 'axios';
import http from '../../../../http';

function getPaymentPlanDataCtor(http: AxiosInstance) {
  return async function getPaymentPlanData(projectId: string, sessionId: string) {
    const { data } = await http.get(`/checkout/${projectId}/${sessionId}/payment-data`);
    return data;
  };
}

export default getPaymentPlanDataCtor(http);
