import { AxiosInstance } from 'axios';
import http from '../../../../http';
import { PaymentData } from '@/views/student/types';

export interface CompleteCheckoutProps {
  sessionId: string;
  projectId: string;
  requestBody: CheckoutPaymentRequestBodyVGS;
}

export interface CheckoutPaymentRequestBodyVGS {
  sessionId: string;
  paymentMethod: 'card' | 'bank';
  provider: 'vgs';
  paymentData: {
    card?: PaymentData;
    bank?: {
      accountNumber?: string;
      routingNumber?: string;
      plaidIntegration: boolean;
    };
  };
}

export interface CompletionDetails {
  price: number;
  studentFirstName: string;
  studentLastName: string;
  email: string;
  productName: string;
  redirectPage: string | null;
}

function completeCheckoutCtor(http: AxiosInstance) {
  return async function completeCheckout({
    sessionId,
    projectId,
    requestBody,
  }: CompleteCheckoutProps): Promise<CompletionDetails> {
    try {
      const { data } = await http.post(`/checkout/${projectId}/${sessionId}/complete-checkout`, requestBody);
      return data;
    } catch (err) {
      const error = err as any;
      if (error?.data.type) {
        throw error.data;
      }
      throw error;
    }
  };
}

export default completeCheckoutCtor(http);
